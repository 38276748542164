;
(function (undefined) {
    Shop.LoyaltyPoints.include({
        options: {
            pointsSelector: '#box_productfull .loyalty_points .points',
            inputSelector: '#box_productfull .basket input[name=quantity]'
        },

        initialize: function (options) {
            var $quantity;
            var $points;
            var startPoints;

            this.constructor.setOptions(options);
            $quantity = $(this.options.inputSelector);
            $points = $(this.options.pointsSelector);
            startPoints = parseFloat($points.text().replace(/,/g, '.').replace(/\s/g, ''));

            if (!!$quantity.length && !!$points.length) {
                $quantity.on('change', function () {
                    var value;
                    var points;

                    value = parseFloat($(this).val().replace(/,/g, '.'));
                    if (value > 0) {
                        points = Math.floor(value * startPoints);

                        if (points >= 1) {
                            $points.text(points);
                        }
                    } else {
                        $points.text(startPoints);
                    };
                });
            }
        }
    });
})();