import { IValidator, TErrorName, TValidationResult } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';

export class Validator {
    validate(value: unknown, validators: IValidator[]): TValidationResult {
        const errorsName: Array<TErrorName> = [];

        const isValid = validators.reduce((isValidSoFar, validator): boolean => {
            if (!validator.check(value)) {
                errorsName.push(validator.errorName);
            }

            return !isValidSoFar ? isValidSoFar : validator.check(value);
        }, true);

        return {
            isValid,
            errorsName
        };
    }
}
