;(function (undefined) {
	Shop.FileUploader = (function () {
		function FileUploader (settings) {
			this.xhr = new XMLHttpRequest();
			this.isSending = false;
			this.$progress = null;
			this.settings = _setSettings.call(this, settings);
	
			_addListeners(this.xhr, this.settings);
	
			if (!this.settings.$file) {
				console.error('this.settings.$file required. (<input type="file">).');
				return false;
			}
	
			if (!this.settings.$submit && this.settings.type === 'click') {
				console.error('this.settings.$submit is required. With type "click".');
				return false;
			}
	
			if (this.settings.$file.dataset && this.settings.$file.dataset.allowedExtensions) {
				this.settings.allowedExtensions = this.settings.$file.dataset.allowedExtensions.split('|');
			}
			
			if (this.settings.type === 'change') {
				this.settings.$file.addEventListener('change', this.send.bind(this));
			}
	
			if (this.settings.type === 'click') {
				this.settings.$submit.addEventListener('click', this.send.bind(this));
			}
		}
	
		FileUploader.prototype.validate = function (ev) {
			var self;
			var files;
			var valid;
			var errorMessages;
	
			self = this;
			files = this.settings.$file.files;
			valid = true;
			errorMessages = [];
	
			if (!files.length) {
				valid = false;
			}
	
			_forEachIn(files, function (file, key) {
				if (!_validExtension(self.settings.dissalowedExtensions, _getExt(file.name))) {
					valid = false;
					errorMessages.push(shoper.substitute(Shop.lang.fileUploader.wrong_extension, {
						filename: file.name
					}));
					
				}
	
				if (!_valisSize(self.settings.maxSize, file.size * 0.001)) {
					valid = false;
					errorMessages.push(shoper.substitute(Shop.lang.fileUploader.wrong_size, {
						filename: file.name,
						size: self.settings.maxSize
					}));
				}
			});
	
			return {
				valid: valid,
				errorMessages: errorMessages
			}
		};
	
		FileUploader.prototype.send = function () {
			var self;
			var files;
			var formData;
			var validate;
			
			validate = this.validate();
			if (!validate.valid) {
				return false;
			}
	
			if (this.isSending !== false) {
				return false;
			}
	
			this.isSending = true;
	
			this.xhr.open('post', this.settings.url, this.settings.async);
			files = this.settings.$file.files;
	
			self = this;
			formData = new FormData();
	
			_forEachIn(this.settings.headers, function (headerValue, headerKey) {
				self.xhr.setRequestHeader(headerKey, headerValue); 
			});
	
			_forEachIn(files, function (file, key) {
				formData.append(self.settings.$file.name, file, file.name);
			});
	
			this.xhr.send(formData);
	
			return this;
		};
	
		function _setSettings (userSettings) {
			var self;
			var settings;
			var $progressBar;
	
			self = this;
			settings = Object.assign({
				$file: null,
				$submit: null,
	
				type: 'click',
				maxSize: 26214400,
				allowedExtensions: ['png', 'jpg', 'txt', 'jpeg', 'webp', 'gif'],
				dissalowedExtensions: [
					'php',
					'inc',
					'exe',
					'js',
					'php4',
					'php5',
					'php6',
					'php7',
					'phtml',
					'cgi',
					'phps',
					'bat',
					'dev',
					'apk',
					'app',
					'jar',
					'class',
					'jnlp',
					'bin',
					'sh',
					'run',
					'vb',
					'vbs',
					'vbscript',
					'hta',
					'msi',
					'osx',
					'lnk',
					'nexe',
					'py',
					'rb',
					'hphp',
					'tpl',
					'htaccess',
					'htpasswd',
					'ini',
					'log',
					'svg'
				],
	
				url: null,
				async: true,
				headers: {},
	
				start: function (ev) {                
					self.$progress = document.createElement('div');
					self.$progress.classList.add('progress-bar');
					$progressBar = document.createElement('span');
					$progressBar.classList.add('progress-bar__bar');
					
					self.$progress.appendChild($progressBar);
					self.settings.$file.parentNode.appendChild(self.$progress);
				},
				progress: function (ev) {
					self.$progress.dataset.progress = shoper.formatBytes(ev.loaded);
					self.$progress.dataset.progressMax = shoper.formatBytes(ev.total);
					$progressBar.style.width = ((100 * ev.loaded) / ev.total) + '%';
				},
				success: null,
				complete: function () {
					setTimeout(function () {
						self.$progress.remove();
						self.isSending = false;
						self.isComplete = true;
					}, 300);
				},
				abort: null,
				error: null,
				timeout: null
			}, userSettings);
		
			Object.assign(settings.headers, {
				'X-Requested-With': 'XMLHttpRequest'
			}, userSettings.headers);
	
			return settings;
		}
	
		function _addListeners (xhr, settings) {
			xhr.upload.addEventListener('loadstart', settings.start);
			xhr.upload.addEventListener('progress', settings.progress);
			xhr.upload.addEventListener('load', settings.success);
			xhr.upload.addEventListener('loadend', settings.complete);
			xhr.upload.addEventListener('abort', settings.abort);
			xhr.upload.addEventListener('error', settings.error);
			xhr.upload.addEventListener('timeout', settings.timeout);
		}
	
		function _forEachIn (obj, callback) {
			var x;
			var counter;
			var hasOwn;
	
			counter = 0;
			hasOwn = Object.prototype.hasOwnProperty;
			for (x in obj) {
				if (hasOwn.call(obj, x)) {
					callback(obj[x], x, counter, obj);
					counter += 1;
				}
			}
		}
	
		function _getExt (filename) {
			var parts
			parts = filename.split('.');
			return parts[parts.length - 1].toLowerCase();
		}
	
		function _validExtension (allowedExtensions, ext) {
			if (!allowedExtensions || !allowedExtensions.length) {
				return true;
			}
	
			return allowedExtensions.indexOf(ext) < 0;
		}
	
		function _valisSize (maxSize, fileSize) {
			if (!maxSize) {
				return true;
			}
	
			return fileSize <= maxSize;
		}
	
		return FileUploader;
	})();
})();