import 'regenerator-runtime';
import 'promise-polyfill/src/polyfill';
import 'reflect-metadata';

import { $emit, $off, $on } from '@core/tools/event_bus';

import { CurrencyUtils } from '@dreamcommerce/utilities';
import { clone } from 'lodash';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import jQuery from 'jquery';

('use strict');

function gFormSubmit() {
    if (gFormSubmit.form) {
        gFormSubmit.form.submit();
    }
}

function recaptchaRender() {
    $('.g-recaptcha').each(function (index, el) {
        el.recaptcha = grecaptcha.render(el, {
            sitekey: $(this).data('sitekey'),
            size: $(this).data('size'),
            callback: gFormSubmit
        });
    });
}

window.gFormSubmit = gFormSubmit;
window.recaptchaRender = recaptchaRender;
window.customerPrivacy = customerPrivacy;

var Class = function (parent) {
    var _class = function () {
        this.initialize.apply(this, arguments);
    };

    _class.fn = _class.prototype;
    _class.fn.parent = _class;

    if (parent) {
        _class.fn.parent = parent.prototype;
    }

    // extends class methods
    _class.extend = function (obj) {
        var i;
        var x;

        for (i in obj) {
            if (obj.hasOwnProperty(i)) {
                if (typeof obj[i] === 'object') {
                    for (x in obj[i]) {
                        _class[i][x] = obj[i][x];
                    }
                } else {
                    _class[i] = obj[i];
                }
            }
        }
    };

    // include methods for class instances
    _class.include = function (obj) {
        var i, y, options;

        for (i in obj) {
            if (obj.hasOwnProperty(i)) {
                if (i == 'options' && _class.fn.options) {
                    options = obj[i];

                    for (y in options) {
                        if (options.hasOwnProperty(y)) {
                            _class.fn[i][y] = options[y];
                        }
                    }
                } else if (i == 'selectorFunctions' && _class.fn.selectorFunctions) {
                    options = obj[i];

                    for (y in options) {
                        if (options.hasOwnProperty(y)) {
                            _class.fn[i][y] = options[y];
                        }
                    }
                } else {
                    _class.fn[i] = obj[i];
                }
            }
        }
    };

    // set options to option obj
    _class.setOptions = function (obj) {
        var i;
        if (obj && obj !== undefined) {
            for (i in obj) {
                if (obj.hasOwnProperty(i)) {
                    if (typeof obj[i] === 'function') {
                        _class.fn.options[i] = obj[i](_class.fn.parent);
                    } else {
                        _class.fn.options[i] = obj[i];
                    }
                }
            }
        }
    };

    return _class;
};

(function () {
    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    if (inIframe()) {
        $('link[rel="stylesheet"]').each(function () {
            $('<link/>', {
                rel: 'stylesheet',
                href: $(this).attr('href') + '?date=' + Date.now(),
                type: 'text/css'
            }).appendTo($('head'));
        });
    }
}.call(window));

(function (window) {
    'use strict';

    if (!jQuery) {
        console.log('No jQuery detected');
        return;
    }

    let temporaryShopObject;
    if (window.Shop) {
        temporaryShopObject = clone(window.Shop);
    }

    window.Shop = new Class();

    if (temporaryShopObject) {
        for (const shopProp in temporaryShopObject) {
            window.Shop[shopProp] = temporaryShopObject[shopProp];
        }
    }

    Shop.AddToCart = new Class(Shop);
    Shop.AjaxBasket = new Class(Shop);
    Shop.AjaxLayer = new Class(Shop);
    Shop.BasketHandler = new Class(Shop);
    Shop.BasketDeliveryPayment = new Class(Shop);
    Shop.FilterPrice = new Class(Shop);
    Shop.Filter = new Class(Shop);
    Shop.ImageSlider = new Class(Shop);
    Shop.ImageZoom = new Class(Shop);
    Shop.LoyaltyPoints = new Class(Shop);
    Shop.PageSlider = new Class(Shop);
    Shop.ProductVoter = new Class(Shop);
    Shop.SkinPreviewBox = new Class(Shop);
    Shop.ShippingCosts = new Class(Shop);
    Shop.StockHandler = new Class(Shop);
    Shop.ProductAvailability = new Class(Shop);
    Shop.Tabs = new Class(Shop);
    Shop.RwdMenu = new Class(Shop);
    Shop.Modal = new Class(Shop);
    Shop.QuickView = new Class(Shop);
    Shop.xhrBox = new Class(Shop);
    Shop.Gallery = new Class(Shop);
    Shop.Mask = new Class(Shop);
    Shop.AddressContainer = new Class(Shop);
    Shop.Address = new Class(Shop);
    Shop.EnhancedEcommerce = new Class(Shop);
    Shop.LazyPicture = new Class(Shop);
    Shop.Recaptcha = new Class(Shop);
    Shop.Blankshield = new Class(Shop);
    Shop.BundleStockHandler = new Class(Shop);
    Shop.BundleStockHandlerContainer = new Class(Shop);
    Shop.PocztaPolska = new Class(Shop);
    Shop.GoogleMapsAPI = new Class(Shop);
    Shop.MapPoints = new Class(Shop);
    Shop.FuzzySearch = new Class(Shop);
    Shop.PocztaPolskaBasketHandler = new Class(Shop);

    Shop.include({
        version: '5.6.3',

        urls: {
            skin: '',
            base: ''
        },

        px1: 'libraries/images/1px.gif',

        selectorFunctions: {},
        perBrowserFix: {},
        classes: {},
        pubsub: $({}),

        runtime: {
            OptionsDefault: null,
            OptionsConfiguration: null,
            OptionCurrentStocok: null,
            OptionCurrentVirt: null,
            OptionImgWidth: null,
            OptionImgHeight: null
        },

        options: {
            debug: false,
            profile: false
        },

        status: {
            domready: false,
            load: false
        },

        rwd: {
            breakPoints: {
                max: 1200,
                mid: 767,
                small: 480
            },

            small: false,
            mid: false,
            large: false
        },

        debug: function () {
            if (this.options.debug == true) {
                console.debug.pass(arguments)();
            }
        },

        error: function () {
            if (console.warn) {
                console.warn.apply(console, arguments);
            } else if (console.error) {
                console.error.apply(console, arguments);
            } else {
                console.log.apply(console, arguments);
            }
        },

        profile: function () {
            if (this.options.debug == true && this.options.profile) {
                console.profile();
            }
        },

        profileEnd: function () {
            if (true == this.options.debug && true == this.options.profile) {
                console.profileEnd();
            }
        },

        time: function (el) {
            if (this.options.debug == true) {
                console.time(el);
            }
        },

        timeEnd: function (el) {
            if (this.options.debug == true) {
                console.timeEnd(el);
            }
        },

        // creating only those classes which are used
        subclass: function (_class) {
            if (Shop[_class] && Shop[_class].condition(this) && typeof Shop[_class] == 'function') {
                this.classes[_class] = Shop[_class];
                this[_class.toLowerCase()] = new this.classes[_class]();

                return true;
            }

            return false;
        },

        url: function (url, skin) {
            if (url.length > 0 && ('/' == url.substr(0, 1) || 'http://' == url.substr(0, 7) || 'https://' == url.substr(0, 8))) {
                return this.urls.base + url;
            }

            return (this.urls.base + '/' + url).replace(/\/\//g, '/');
        },

        alert: function (msg, title) {
            var modal = new Shop.Modal();
            modal.alert(msg, title);
        },

        versionParser: function (v) {
            if (typeof v !== 'string' || v.indexOf('.') < 1 || parseInt(v, 10) < 5) {
                this.error('Unable to parse version: "' + v + '". Comparison is unpredictable.');
            }

            var m = Math.pow(100, 3);
            var subv = v.split('.');
            var vint = 0;
            for (var x = 0; x < subv.length; ++x) {
                vint += m * parseInt(subv[x], 10);
                m /= 100;
            }

            return vint;
        },

        debounce: function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this;
                var args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        func.apply(context, args);
                    }
                };
                var callNow = immediate && !timeout;

                clearTimeout(timeout);
                timeout = setTimeout(later, wait);

                if (callNow) {
                    func.apply(context, args);
                }
            };
        },

        encodeQueryString: function (query) {
            var keyValue;
            var queryObject = {};

            query
                .replace('?', '')
                .split('&')
                .forEach(function (item) {
                    keyValue = item.split('=');
                    queryObject[keyValue[0]] = keyValue[1];
                });

            return queryObject;
        },

        simpleSanitizeHTML: function (string) {
            var map = {
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#x27;',
                '/': '&#x2F;',
                '(': '&#x28;',
                ')': '&#x29;',
                '|': '&#124;',
                '=': '&#61;',
                '^': '&#94;'
            };

            var reg = /[<>"'\/\\\)]/gi;
            return string.replace(reg, function (character) {
                return map[character];
            });
        },

        escapeJSONData: function (data, cb) {
            if (!data) {
                return;
            }

            var self = this;

            if (Array.isArray(data)) {
                data.forEach(function (value, index) {
                    if (Array.isArray(value) || typeof value === 'object') {
                        self.escapeJSONData(value, cb);
                    } else if (typeof value === 'string') {
                        _escapeJSONHelper(self, data, value, index, cb);
                    }
                });
            } else if (typeof data === 'object') {
                this.forEachIn(data, function (value, key) {
                    if (Array.isArray(value) || typeof value === 'object') {
                        self.escapeJSONData(value, cb);
                    } else if (typeof value === 'string') {
                        _escapeJSONHelper(self, data, value, key, cb);
                    }
                });
            }
        },

        escapeRegExp: function (value) {
            return value.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
        },

        substitute: function (str, sub) {
            return str.replace(/\{(.+?)\}/g, function ($0, $1) {
                return $1 in sub ? sub[$1] : $0;
            });
        },

        forEachIn: function (obj, cb) {
            var x;
            var counter;
            var hasOwn;

            counter = 0;
            hasOwn = Object.prototype.hasOwnProperty;
            for (x in obj) {
                if (hasOwn.call(obj, x)) {
                    cb(obj[x], x, counter, obj);
                    counter += 1;
                }
            }
        },

        asyncEach: function (obj, fn, cb, time) {
            var keys;
            var async;
            var length;

            keys = Object.keys(obj);
            async = function (x, objLength) {
                if (x < objLength) {
                    if (obj instanceof Array) {
                        fn.call(null, obj[x], x, obj);
                    } else if (obj instanceof Object) {
                        fn.call(null, obj[keys[x]], keys[x], x, obj);
                    }
                } else {
                    if (typeof cb === 'function') {
                        if (obj instanceof Array) {
                            cb.call(null, obj[x], x, obj);
                        } else if (obj instanceof Object) {
                            cb.call(null, obj[keys[x]], keys[x], x, obj);
                        }
                    }
                    return false;
                }

                x++;
                setTimeout(function () {
                    async(x, objLength);
                }, time || 0);
            };

            async(0, obj.length || keys.length);
        },

        isInViewport: function ($el, offset) {
            var defaultOffset = {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            };

            offset = offset || defaultOffset;

            if (typeof jQuery === 'function' && $el instanceof jQuery) {
                $el = $el[0];
            }

            var rect = $el.getBoundingClientRect();

            if (!$el) {
                return false;
            }

            return (
                rect.top + offset.top >= 0 &&
                rect.left + offset.left >= 0 &&
                rect.bottom - offset.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right - offset.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },

        findObjInArrayByParamValue: function (arr, param, value) {
            var searchObj;

            searchObj = false;
            arr.some(function (obj) {
                if (obj[param] === value) {
                    searchObj = obj;
                    return true;
                }
            });

            return searchObj;
        },

        base64: {
            alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
            lookup: null,
            ie: /MSIE /.test(navigator.userAgent),
            ieo: /MSIE [67]/.test(navigator.userAgent),
            encode: function (s) {
                var buffer = this.toUtf8(s),
                    position = -1,
                    len = buffer.length,
                    nan0,
                    nan1,
                    nan2,
                    enc = [, , ,];
                if (this.ie) {
                    var result = [];
                    while (++position < len) {
                        nan0 = buffer[position];
                        nan1 = buffer[++position];
                        enc[0] = nan0 >> 2;
                        enc[1] = ((nan0 & 3) << 4) | (nan1 >> 4);
                        if (isNaN(nan1)) enc[2] = enc[3] = 64;
                        else {
                            nan2 = buffer[++position];
                            enc[2] = ((nan1 & 15) << 2) | (nan2 >> 6);
                            enc[3] = isNaN(nan2) ? 64 : nan2 & 63;
                        }
                        result.push(
                            this.alphabet.charAt(enc[0]),
                            this.alphabet.charAt(enc[1]),
                            this.alphabet.charAt(enc[2]),
                            this.alphabet.charAt(enc[3])
                        );
                    }
                    return result.join('');
                } else {
                    var result = '';
                    while (++position < len) {
                        nan0 = buffer[position];
                        nan1 = buffer[++position];
                        enc[0] = nan0 >> 2;
                        enc[1] = ((nan0 & 3) << 4) | (nan1 >> 4);
                        if (isNaN(nan1)) enc[2] = enc[3] = 64;
                        else {
                            nan2 = buffer[++position];
                            enc[2] = ((nan1 & 15) << 2) | (nan2 >> 6);
                            enc[3] = isNaN(nan2) ? 64 : nan2 & 63;
                        }
                        result += this.alphabet[enc[0]] + this.alphabet[enc[1]] + this.alphabet[enc[2]] + this.alphabet[enc[3]];
                    }
                    return result;
                }
            },
            decode: function (s) {
                if (s.length % 4)
                    throw new Error("InvalidCharacterError: 'this.decode' failed: The string to be decoded is not correctly encoded.");
                var buffer = this.fromUtf8(s),
                    position = 0,
                    len = buffer.length;
                if (this.ieo) {
                    var result = [];
                    while (position < len) {
                        if (buffer[position] < 128) result.push(String.fromCharCode(buffer[position++]));
                        else if (buffer[position] > 191 && buffer[position] < 224)
                            result.push(String.fromCharCode(((buffer[position++] & 31) << 6) | (buffer[position++] & 63)));
                        else
                            result.push(
                                String.fromCharCode(
                                    ((buffer[position++] & 15) << 12) | ((buffer[position++] & 63) << 6) | (buffer[position++] & 63)
                                )
                            );
                    }
                    return result.join('');
                } else {
                    var result = '';
                    while (position < len) {
                        if (buffer[position] < 128) result += String.fromCharCode(buffer[position++]);
                        else if (buffer[position] > 191 && buffer[position] < 224)
                            result += String.fromCharCode(((buffer[position++] & 31) << 6) | (buffer[position++] & 63));
                        else
                            result += String.fromCharCode(
                                ((buffer[position++] & 15) << 12) | ((buffer[position++] & 63) << 6) | (buffer[position++] & 63)
                            );
                    }
                    return result;
                }
            },
            toUtf8: function (s) {
                var position = -1,
                    len = s.length,
                    chr,
                    buffer = [];
                if (/^[\x00-\x7f]*$/.test(s)) while (++position < len) buffer.push(s.charCodeAt(position));
                else
                    while (++position < len) {
                        chr = s.charCodeAt(position);
                        if (chr < 128) buffer.push(chr);
                        else if (chr < 2048) buffer.push((chr >> 6) | 192, (chr & 63) | 128);
                        else buffer.push((chr >> 12) | 224, ((chr >> 6) & 63) | 128, (chr & 63) | 128);
                    }
                return buffer;
            },
            fromUtf8: function (s) {
                var position = -1,
                    len,
                    buffer = [],
                    enc = [, , ,];
                if (!this.lookup) {
                    len = this.alphabet.length;
                    this.lookup = {};
                    while (++position < len) this.lookup[this.alphabet.charAt(position)] = position;
                    position = -1;
                }
                len = s.length;
                while (++position < len) {
                    enc[0] = this.lookup[s.charAt(position)];
                    enc[1] = this.lookup[s.charAt(++position)];
                    buffer.push((enc[0] << 2) | (enc[1] >> 4));
                    enc[2] = this.lookup[s.charAt(++position)];
                    if (enc[2] == 64) break;
                    buffer.push(((enc[1] & 15) << 4) | (enc[2] >> 2));
                    enc[3] = this.lookup[s.charAt(++position)];
                    if (enc[3] == 64) break;
                    buffer.push(((enc[2] & 3) << 6) | enc[3]);
                }
                return buffer;
            }
        },

        rwdCheck: function (el) {
            this.rwd.large = false;
            this.rwd.mid = false;
            this.rwd.small = false;
            this.rwd.xs = false;

            if (el.innerWidth() > this.rwd.breakPoints.max) {
                this.rwd.large = true;
            } else if (el.innerWidth() < this.rwd.breakPoints.max && el.innerWidth() > this.rwd.breakPoints.mid) {
                this.rwd.mid = true;
            } else {
                this.rwd.small = true;
                //add to check forwidth < 480 without breaking backward compatibility
                if (el.innerWidth() < this.rwd.breakPoints.small) {
                    this.rwd.xs = true;
                }
            }
        },

        getBasketProducts: function () {
            var t = this;
            var basProducts;

            if (Shop.basket.basketProducts && typeof Shop.basket.basketProducts === 'string') {
                basProducts = Shop.basket.basketProducts.split(',');
                basProducts = basProducts.slice(0, basProducts.length - 1);

                return basProducts.map(function (id) {
                    return t.base64.decode(id);
                });
            }

            return [];
        },

        getCategoryProducts: function () {
            var t = this;
            var catProducts;

            if (typeof Shop.basket.categoryProducts === 'string') {
                catProducts = Shop.basket.categoryProducts.split(',');
                catProducts = catProducts.slice(0, catProducts.length - 1);

                return catProducts.map(function (id) {
                    return t.base64.decode(id);
                });
            }

            return [];
        },

        getVisitorId: function () {
            if (Shop.basket.shopVisitorId) {
                return this.base64.decode(Shop.basket.shopVisitorId);
            }

            return null;
        },

        getOrderDone: function () {
            if (Shop.basket.orderDone) {
                return Shop.basket.orderDone;
            }

            return false;
        },

        isMobile: function () {
            var check = false;
            (function (a) {
                if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                        a
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                        a.substr(0, 4)
                    )
                )
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },

        noJS: function () {
            $('input[name=nojs]').val('0');
        },

        runSelectorFunctions: function () {
            var x,
                self,
                that = this;

            for (x in this.selectorFunctions) {
                self = eval('this.selectorFunctions.' + x);

                try {
                    if (typeof self.domready == 'function') {
                        if (this.status.domready == true) {
                            $(self.selector).each(function () {
                                self.domready($(this), that);
                            });
                        } else {
                            $(document).ready(function () {
                                try {
                                    $(self.selector).each(function () {
                                        self.domready($(this), that);
                                    });
                                } catch (ignore) {
                                    console.error(ignore);
                                }
                            });
                        }
                    }
                } catch (ignore) {
                    console.error(ignore);
                }

                try {
                    if (typeof self.load == 'function') {
                        if (this.status.load == true) {
                            $(self.selector).each(function () {
                                self.load($(this), that);
                            });
                        } else {
                            $(window).on('load', self, function (e) {
                                try {
                                    $(e.data.selector).each(function () {
                                        e.data.load($(this), that);
                                    });
                                } catch (ignore) {
                                    console.error(ignore);
                                }
                            });
                        }
                    }
                } catch (ignore) {
                    console.error(ignore);
                }
            }
        },

        addEvent: function () {
            if (arguments[2]) {
                this.pubsub.bindFirst.call(this.pubsub, arguments[0], arguments[1]);
            } else {
                this.pubsub.on.call(this.pubsub, arguments[0], arguments[1]);
            }
        },

        removeEvent: function () {
            this.pubsub.off.call(this.pubsub, arguments[0], arguments[1]);
        },

        fireEvent: function () {
            var args;

            args = [];
            this.forEachIn(arguments, function (val, key, counter) {
                if (counter) {
                    args.push(val);
                }
            });

            this.pubsub.trigger.call(this.pubsub, arguments[0], args);
        },

        toPrimaryValue: function (value, decSep, thousSep) {
            if (!decSep && Shop.values.decimalSep) {
                decSep = Shop.values.decimalSep || ',';
            }

            if (!thousSep && Shop.values.thousandSep) {
                thousSep = Shop.values.thousandSep || ' ';
            }

            return CurrencyUtils.toNumber(value, { decimalSeparator: decSep, literalSeparator: thousSep });
        },

        formatBytes: function (bytes) {
            var i;
            var k;
            var sizes;

            if (bytes == 0) {
                return '0 B';
            }

            k = 1024;
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },

        initialize: function () {
            var that = this;

            var pri = Shop.preinit;
            if (typeof pri === 'object') {
                $.each(pri, function (key, fn) {
                    if (typeof fn === 'function') {
                        fn.call(that);
                    }
                });
            }

            this.time('Shop.initialize');
            this.profile();

            this.status.domready = true;
            $(window).load(function () {
                that.status.load = true;
            });

            $(window).on(
                'resize',
                function () {
                    this.rwdCheck($(window));
                }.bind(this)
            );

            this.rwdCheck($(window));

            if (false == Shop.urlParser(this)) {
                this.error('Unable to parse urls');
                return;
            }

            this.subclass('Recaptcha');
            this.subclass('LazyPicture');
            this.subclass('Modal');
            this.subclass('AjaxBasket');
            this.subclass('AjaxLayer');
            this.subclass('EnhancedEcommerce');
            this.subclass('AddToCart');
            this.subclass('BasketHandler');
            this.subclass('BasketDeliveryPayment');
            this.subclass('FilterPrice');
            this.subclass('Filter');
            this.subclass('ImageSlider');
            this.subclass('ImageZoom');
            this.subclass('LoyaltyPoints');
            this.subclass('PageSlider');
            this.subclass('ProductVoter');
            this.subclass('SkinPreviewBox');
            this.subclass('ShippingCosts');
            this.subclass('StockHandler');
            this.subclass('ProductAvailability');
            this.subclass('Tabs');
            this.subclass('RwdMenu');
            this.subclass('QuickView');
            this.subclass('xhrBox');
            this.subclass('Gallery');
            this.subclass('AddressContainer');
            this.subclass('Blankshield');
            this.subclass('BundleStockHandlerContainer');
            this.subclass('PocztaPolska');
            this.subclass('GoogleMapsAPI');
            this.subclass('MapPoints');
            this.subclass('FuzzySearch');
            this.subclass('PocztaPolskaBasketHandler');

            this.runSelectorFunctions();
            this.noJS();

            var pi = Shop.postinit;
            if (typeof pi === 'object') {
                $.each(pi, function (key, fn) {
                    if (typeof fn === 'function') {
                        fn.call(that);
                    }
                });
            }

            this.profileEnd();
            this.timeEnd('Shop.initialize');

            $('[data-size="invisible"].g-recaptcha')
                .parents('form')
                .find('[type="submit"]')
                .on('click', function (ev) {
                    if (!this.hiddenVal) {
                        if ($(this).attr('value')) {
                            this.hiddenVal = true;

                            $('<input />', {
                                type: 'hidden',
                                value: $(this).val(),
                                name: $(this).attr('name')
                            }).appendTo($(this));
                        }
                    }
                });

            $('[data-size="invisible"].g-recaptcha')
                .parents('form')
                .on('submit', function (ev) {
                    ev.preventDefault();

                    gFormSubmit.form = this;
                    grecaptcha.execute($(this).find('.g-recaptcha').get(0).recaptcha);
                });

            if (navigator.onLine === false) {
                $('body').addClass('shop_offline').attr('data-message', Shop.lang.common.offline);

                $(document).ajaxSend(function (ev, xhr) {
                    xhr.abort();

                    xhr.done = function () {
                        return xhr;
                    };
                    xhr.fail = function () {
                        return xhr;
                    };
                    xhr.complete = function () {
                        return xhr;
                    };
                    xhr.error = function () {
                        return xhr;
                    };
                    xhr.success = function () {
                        return xhr;
                    };
                    xhr.always = function () {
                        return xhr;
                    };

                    that.alert(Shop.lang.common.offline_long, Shop.lang.common.offline);
                });

                $('form')
                    .off('submit')
                    .on('submit', function (ev) {
                        ev.preventDefault();
                        ev.stopPropagation();

                        that.alert(Shop.lang.common.offline_long, Shop.lang.common.offline);
                    });
            }

            if ('serviceWorker' in navigator && location.protocol === 'https:' && navigator.onLine === true) {
                if ($('html').data('pwa')) {
                    navigator.serviceWorker.register('/libraries/scripts/sw.js', {
                        scope: '/'
                    });
                } else {
                    navigator.serviceWorker.getRegistrations().then(function (registrations) {
                        var registration;

                        for (registration in registrations) {
                            registration.unregister();
                        }
                    });
                }
            }
        }
    });

    Shop.extend({
        exist: function (obj) {
            if (typeof obj === 'object') {
                if (obj != null && obj && obj.length != 0) {
                    return true;
                }

                return false;
            }

            if (obj != null && obj != undefined && obj) {
                return true;
            }

            return false;
        },

        scrollto: function (div, noAnimation) {
            var scrollHeight = $(div).offset().top;

            if (noAnimation) {
                $('html, body').scrollTop(scrollHeight);
            } else {
                $('html, body').animate(
                    {
                        scrollTop: scrollHeight
                    },
                    600
                );
            }

            return false;
        }
    });

    Shop.lang = {
        plugins: {}
    };

    Shop.values = Shop.values || {};
    Shop.useroptions = Shop.useroptions || {};
    Shop.preinit = Shop.preinit || {};
    Shop.postinit = Shop.postinit || {};
    Shop.basket = Shop.basket || {};
    Shop.RwdGallery = Shop.RwdGallery || {}; // backward compatibility
    Shop.useroptions.prevent = false;

    window.Shop = Shop;

    Shop.urlParser = function (that) {
        $('link[rel=home]').each(function () {
            if (typeof $(this).attr('href') == 'string') {
                that.urls.base = ($(this).attr('href') + '/').replace(/\/\//g, '/');
            }
        });

        $('link[rel=start]').each(function () {
            if (typeof $(this).attr('href') == 'string') {
                that.urls.base = ($(this).attr('href') + '/').replace(/\/\//g, '/');
            }
        });

        return !!that.urls.base.length;
    };

    Function.prototype.delay = function (ms) {
        var fn = this;
        return function () {
            setTimeout(fn, ms);
        };
    };

    Number.prototype.numberFormat = function (decimals, dec_point, thousands_sep) {
        decimals = Math.abs(decimals) + 1 ? decimals : 2;
        dec_point = dec_point || '.';
        thousands_sep = thousands_sep || ',';
        var matches = /(-)?(\d+)(\.\d+)?/.exec((isNaN(this) ? 0 : this) + '');
        var f_decimal = decimals ? Number(matches[3] || 0).toFixed(decimals) : '0';
        matches[2] = (parseInt(matches[2]) + parseInt(f_decimal.substr(0, 1))).toString();
        var remainder = matches[2].length > 3 ? matches[2].length % 3 : 0;

        return (
            (matches[1] ? matches[1] : '') +
            (remainder ? matches[2].substr(0, remainder) + thousands_sep : '') +
            matches[2].substr(remainder).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep) +
            (decimals ? dec_point + f_decimal.substr(2) : '')
        );
    };

    $.fn.bindFirst = function (name, fn) {
        this.on(name, fn);

        this.each(function () {
            var handlers;
            var handler;

            handlers = $._data(this, 'events')[name.split('.')[0]];
            handler = handlers.pop();

            handlers.splice(0, 0, handler);
        });
    };

    function _escapeJSONHelper(shopInstance, data, value, key, cb) {
        if (typeof cb === 'function') {
            cb.call(shopInstance, data, value, key);
        } else {
            data[key] = shopInstance.simpleSanitizeHTML(value);
        }
    }
})(window);

function googleMapsAPICallback() {
    Shop.googleMapsApi.apiReady();
}

function gm_authFailure() {
    if (Shop.pocztapolska) {
        Shop.pocztapolska.handleErrorMap();
    }
}

window._$ = jQuery;
window.googleMapsAPICallback = googleMapsAPICallback;
window.gm_authFailure = gm_authFailure;
window.$on = $on;
window.$off = $off;
window.$emit = $emit;
