// @ts-nocheck

import { AnalyticService } from '../../classes/service';
import { TServiceName } from '../types';
import eventsHandlersMapper from './microsoft_ads_events';
import { MicrosoftAdsSDK } from '@frontstoreRwd/modules/analytics/services/microsoft_ads/microsoft_ads_sdk';
import { TMicrosoftAdsEvent } from '@frontstoreRwd/modules/analytics/services/microsoft_ads/microsoft_ads_types';

export class MicrosoftAdsService extends AnalyticService<MicrosoftAdsService> {
    private _uetq: any;
    private _uetgId: string;

    protected _events: TMicrosoftAdsEvent = eventsHandlersMapper;

    constructor(name: TServiceName, uetg: string) {
        super(name);
        this._uetgId = uetg;
    }

    public async setup(): Promise<void> {
        await MicrosoftAdsSDK.create();

        window['uetq'] = window['uetq'] || [];

        const config = {
            ti: this._uetgId,
            q: window['uetq']
        };

        window['uetq'] = new UET(config);
        this._uetq = window['uetq'];
        this._uetq.push('pageLoad');

        this._isSetuped = true;
    }

    public send({ eventName, eventParameters }: { eventName: string; eventParameters: Record<string, any> }): void {
        this._uetq.push('event', eventName, eventParameters);
    }
}
