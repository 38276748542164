import { GOOGLE_ANALATYICS_V4_GTAG, GOOGLE_ANALATYICS_V4_GTM_CLIENT, GOOGLE_ANALATYICS_V4_GTM_PLATFORM } from '../services_names';
import { GTM_TRACKING_TYPE, NATIVE_TRACKING_TYPE } from './constants';
import { AnalyticManager } from '../../classes/manager';
import { GoogleAnalyticV4GTMService } from './gtm';
import { GoogleAnalyticV4GtagService } from './gtag';
import { IAnalyticService } from '../../classes/service/types';
import { TGoogleAnalyticsV4Options } from '../../types';
import Features from '@core/tools/features';
import { PlatformGoogleAnalyticV4GTMService } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/platform_gtm';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';

export class GoogleAnalyticsV4Initializer {
    private _analyticsManager: AnalyticManager;
    private _options: TGoogleAnalyticsV4Options[];

    private _serviceTypes = {
        [GTM_TRACKING_TYPE]: {
            create({ googleRemarketing }: TGoogleAnalyticsV4Options): IAnalyticService {
                return new GoogleAnalyticV4GTMService(GOOGLE_ANALATYICS_V4_GTM_CLIENT, {
                    dataLayerName: 'dataLayer',
                    googleRemarketing
                });
            }
        },

        [NATIVE_TRACKING_TYPE]: {
            create(options: TGoogleAnalyticsV4Options): IAnalyticService {
                return new GoogleAnalyticV4GtagService(options.serviceName || GOOGLE_ANALATYICS_V4_GTAG, {
                    trackingId: [options.trackingId],
                    layerName: options.layerName,
                    googleRemarketing: options.googleRemarketing
                });
            }
        }
    };

    constructor(analyticManager: AnalyticManager, options: TGoogleAnalyticsV4Options[]) {
        this._analyticsManager = analyticManager;
        this._options = options;

        for (const options of this._options) {
            this.registerService(options);
        }

        if (Features.isEnabled('partner_google_analytics_v4')) {
            customerPrivacy.onPlatformConsentGranted(async () => {
                const service = new PlatformGoogleAnalyticV4GTMService(GOOGLE_ANALATYICS_V4_GTM_PLATFORM, {
                    dataLayerName: 'shopLayer'
                });
                this._analyticsManager.register(service);
            });
        }
    }

    private registerService(option: TGoogleAnalyticsV4Options) {
        const isClientGoogleAnalyticsV4Active = option && option.isActive;

        if (Features.isEnabled('gtag_emit_event')) {
            //@ts-ignore
            window.isGA4Active = false;
        }

        if (isClientGoogleAnalyticsV4Active) {
            if (Features.isEnabled('gtag_emit_event')) {
                //@ts-ignore
                window.isGA4Active = true;
            }
            const service = this._serviceTypes[option.isGtm].create(option);
            this._analyticsManager.register(service);
        }
    }
}
