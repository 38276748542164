(function(undefined) {
    Shop.ImageZoom.include({
        options: {
            img: false,
            inner: false,
            container_class: 'imagezoom',
            transbox_class: 'imagezoom_transbox',
            shade_class: 'imagezoom_shade',
            parent_selector: '#box_productfull .product-main-box',
            blank_regexp: '__overlay.jpg$',
            img_container: 'mainimg',
            disable: false
        },

        img: null,
        div: null,
        divimg: null,
        src: null,

        mmfx: false,
        infx: false,
        inner: true,
        inside: false,
        startpos: null,

        locked: false,

        shade1: null,
        shade2: null,

        initialize: function(options) {
            if (!$('html').hasClass('no-touch')) {
                return;
            }

            this.constructor.setOptions(options);
            var shopClass = this.parent;
            var img = $(this.options.img)[0];

            if (new RegExp(this.options.blank_regexp).test($(img).attr('src'))) {
                return;
            }

            img._class = this;
            img._zoomimage = this;
            this.img = img;
            this.inner = !!this.options.inner;

            if (shopClass.rwd.small || shopClass.rwd.mid) {
                this.inner = true;
            }

            $(img)
                .off('mouseenter mouseleave mousemove')
                .on('mouseenter', [this], function(e) {
                    var self = e.data[0];

                    if (this._loaded == true && this._locked == false) {
                        this._class.inside = true;

                        if (this._class.infx == true) {
                            this._class.show_div(this._class).delay(100);
                        } else {
                            this._class.show_div();
                        }
                    } else {
                        $(self)
                            .trigger('mouseenter')
                            .delay(100);
                    }
                })
                .on('mouseleave', function(e) {
                    this._class.inside = false;
                })
                .on('mousemove', function(e) {
                    if (this._class.inside == true) {
                        this._class.startpos = e.page;
                    }
                })
                .on('load', function(e) {
                    this._loaded = true;
                });

            var w = $(img).width();
            var h = $(img).height();

            img._loaded = !!(w + h > 20);

            if (Shop.ImageZoom.donefirsttime == false) {
                img._locked = true;
                Shop.ImageZoom.donefirsttime = true;

                setTimeout(function() {
                    img._locked = false;
                }, 100);
            } else {
                img._locked = false;
            }

            this.getsrc();
        },

        destroy: function() {
            try {
                $(window).off('resize', this.resize_event);
                delete this.img._class;
                delete this.img._zoomimage;
                if (Shop.exist($(this.div))) $(this.div).remove();
                if (Shop.exist($(this.shade1))) $(this.shade1).remove();
                delete this;
            } catch (e) {}
        },

        getsrc: function() {
            if (
                $(this.img)
                    .attr('class')
                    .match(/gallery_[0-9]+/)
            ) {
                var id = $(this.img)
                    .attr('class')
                    .replace(/.*gallery_([0-9]+).*/, 'prodimg$1');
                if (id.match(/^prodimg[0-9]+$/) && $(id) && !$(this.img).hasClass('non-stock-img')) {
                    this.src = $('#' + id).attr('href');
                } else if ($(this.img).data('photo_orig')) {
                    this.src = $(this.img).data('photo_orig');
                }
            } else {
                if (
                    $(this.img)
                        .parent()
                        .prop('tagName') == 'A'
                ) {
                    this.src = $(this.img)
                        .parent()
                        .attr('href');
                } else {
                    this.src = $(this.img).data('photo_orig');
                }
            }

            if (Shop.exist(this.src)) {
                var img = $('<img />').attr('href', this.src);
            }
        },

        reset: function() {
            try {
                $(window).off('resize', this.resize_event);
                if (Shop.exist($(this.div))) $(this.div).remove();
                if (Shop.exist($(this.shade1))) $(this.shade1).remove();
                this.img._loaded = false;
                this.img._locked = false;
                this.inside = false;
                this.locked = false;
                this.div = false;
                this.shade1 = false;
                this.getsrc();
            } catch (e) {}
        },

        show_div: function() {
            if (this.inside == false || this.locked == true) return;
            if (!Shop.exist(this.div)) {
                var shopClass = this.parent;
                var parent = $(this.options.parent_selector) || $('body');
                var galleryOutside = $('.tab-content .gallery');
                parent.css('position', 'relative');

                this.div = $('<div />', {
                    class: this.options.container_class
                }).appendTo(parent);

                if (galleryOutside.length > 0 && this.inner) {
                    this.div.one('mousedown', function(e) {
                        e.preventDefault();
                        e.stopPropagation();

                        galleryOutside.find('> .f-grid-4:first-child > a').trigger('click');
                    });
                }

                var c = {};
                c.x = parent.find('.' + this.options.img_container).width();
                c.y = parent.find('.' + this.options.img_container).height();

                var p = parent.find('.' + this.options.img_container).position();

                this.resize_event = function(e) {
                    var p = parent.find('.' + this.options.img_container).position();

                    var c = {};
                    c.x = parent.find('.' + this.options.img_container).width();
                    c.y = parent.find('.' + this.options.img_container).height();

                    if (shopClass.rwd.small || shopClass.rwd.mid) {
                        this.inner = true;
                    }

                    if (this.inner == false) {
                        this.shade1.css({
                            left: p.left,
                            top: p.top
                        });

                        this.shade1._pos = $(this.img).offset();

                        var x = Shop.ImageZoom.sidebox_size($(this.img), this.div, this.divimg);
                        p = {
                            x: x.left,
                            y: x.top
                        };
                    }

                    $(this.div).css({
                        width: c.x,
                        height: c.y,
                        left: p.left,
                        top: p.top
                    });
                }.bind(this);

                $(window).resize(this.resize_event);

                if (this.inner == true) {
                    $(this.div).css({
                        left: p.left,
                        top: p.top,
                        width: c.x,
                        height: c.y
                    });

                    this.div._size = $(this.div).width();
                    this.div._pos = $(this.div).offset();
                } else {
                    var cs = Shop.ImageZoom.sidebox_size($(this.img), this.div, this.divimg);
                    delete cs.left;
                    cs.top = 0;
                    cs.right = 0;

                    $(this.div).css(cs);

                    this.shade1 = $('<div />', {
                        class: this.options.shade_class
                    }).appendTo(parent);

                    this.shade1.css({
                        left: p.left,
                        top: p.top,
                        width: c.x,
                        height: c.y
                    });

                    this.div._size = $(this.img).width();
                    this.div._pos = $(this.img).offset();
                    this.shade1._class = this;

                    this.shade1.hide();

                    this.shade2 = $('<div />');
                    this.shade2
                        .addClass(this.options.transbox_class)
                        .css({
                            width: 0,
                            height: 0
                        })
                        .appendTo(this.shade1);

                    if (galleryOutside.length > 0 && !this.innder) {
                        this.shade2.one('mousedown', function(e) {
                            e.preventDefault();
                            e.stopPropagation();

                            galleryOutside.find('> .f-grid-4:first-child > a').trigger('click');
                        });
                    }
                }

                this[this.inner ? 'div' : 'shade1']
                    .off('mouseleave mousemove click')
                    .on('click', [this], function(e) {
                        var self = e.data[0].div;

                        self._class.hide_div();
                        var a = $(self._class.img).parent();
                        self._class.locked = true;

                        if (a.prop('tagName') == 'DIV' && a.hasClass(e.data[0].options.img_container)) {
                            a = a.find('img');

                            if (a) {
                                if (a.hasClass('js__open-gallery')) {
                                    const id = a.attr('class').replace(/^.*gallery_([0-9]+)[^0-9]?.*$/, '$1');
                                    document.querySelector('img.gallery_' + id + '.js__open-gallery')?.click();
                                } else {
                                    $('#prodimg' + a.attr('class').replace(/^.*gallery_([0-9]+)[^0-9]?.*$/, '$1')).trigger('click');
                                }
                            }
                        } else {
                            if (a.hasClass('js__gallery-anchor-image')) {
                                document.querySelector('img.js__open-gallery')?.click();
                            }

                            a.trigger('click');
                        }

                        setTimeout(
                            function() {
                                self._class.locked = false;
                            },
                            1000,
                            self
                        );
                    })
                    .on('mouseleave', [this], function(e) {
                        var self = e.data[0];
                        self.hide_div();
                    })
                    .on('mousemove', [this], function(e) {
                        var self = e.data[0];

                        var size = {
                            x: self.div.width(),
                            y: self.div.height()
                        };

                        var xfactor = Shop.ImageZoom.transform((e.pageX - self.div._pos.left) / size.x, self.div._class.inner);
                        var yfactor = Shop.ImageZoom.transform((e.pageY - self.div._pos.top) / size.y, self.div._class.inner);
                        var s = self.div._class.divimg;

                        var x = -Math.round(xfactor * (s.width() - size.x));
                        var y = -Math.round(yfactor * (s.height() - size.y));

                        if (self.div._class.mmfx == false) {
                            self.div._class.divimg.css({
                                left: x,
                                top: y
                            });
                        }

                        if (Shop.exist($(self.div._class.shade2))) {
                            if (self.div._class.shade2.width() == 0) {
                                self.div._class.shade2.css({
                                    width: Math.round((self.div._class.div.width() / self.div._class.divimg.width()) * size.x),
                                    height: Math.round((self.div._class.div.height() / self.div._class.divimg.height()) * size.y * 1.15)
                                });
                            }

                            x = Math.round(xfactor * (size.x - self.div._class.shade2.width()));
                            y = Math.round(yfactor * (size.y - self.div._class.shade2.height()));

                            self.div._class.shade2.css({
                                left: x,
                                top: y * 1.8,
                                border: '1px solid'
                            });
                        }
                    });

                this.div._class = this;
                this.div.hide();

                this.divimg = $('<img />');
                this.divimg.attr('src', this.src).css({
                    position: 'relative',
                    left: 0,
                    top: 0
                });
                this.divimg.appendTo(this.div);

                if (c.x > this.divimg[0].width && c.y > this.divimg[0].height && this.divimg[0].width !== 0 && this.divimg[0].height !== 0) {
                    this.options.disable = true;
                    return false;
                } else {
                    this.options.disable = false;
                }

                if (this.startpos && this.startpos.x) {
                    this[this.inner ? 'div' : 'shade1'].trigger('mousemove', { page: this.startpos });
                }
            }

            if (this.infx == true) {
                $(this.div).fadeIn('fast');
                if (Shop.exist($(this.shade1))) $(this.shade1).fadeIn('fast');
            } else {
                $(this.div).fadeIn('show');
                if (Shop.exist($(this.shade1))) {
                    this.shade1.show();
                    this.shade1.css('opacity', 0);
                }
            }
        },

        hide_div: function() {
            this.inside = false;
            var fadetype = this.infx ? 'fadeOut' : 'hide';

            if (fadetype == 'fadeOut') $(this.div).fadeOut('fast');
            else $(this.div).hide();

            if (Shop.exist($(this.shade1)))
                if (fadetype == 'fadeOut') $(this.shade1).fadeOut('fast');
                else $(this.shade1).hide();

            this.div.remove();
            if (this.shade1) {
                this.shade1.remove();
            }
            this.div = null;
        }
    });

    Shop.ImageZoom.donefirsttime = false;

    Shop.ImageZoom.transform = function(x, inner) {
        if (false == inner) return x;
        if (x < 0.5) {
            x = 3 * x * x - 1 / 4; // [ (2x)^2 / (4/3) ] - (1/3)
        } else {
            x = Math.sqrt(x - 0.5) * 1.5 + 0.5;
        }

        if (x >= 1) return 1;
        if (x <= 0) return 0;
        return x;
    };

    Shop.ImageZoom.sidebox_size = function(oimg, sidediv, divimg) {
        var $grid = $('div.productimg').next();

        return {
            left: $grid.position().left,
            top: oimg.position().top,
            width: $grid.width(),
            height: oimg.height()
        };
    };
})();
