//@ts-nocheck
//@ts-ignore
type TMapKey = '<' | '>' | '"' | "'" | '/' | '(' | ')' | '|' | '=' | '^';

export function simpleSanitizeHTML(html) {
    const map = {
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
        '(': '&#x28;',
        ')': '&#x29;',
        '|': '&#124;',
        '=': '&#61;',
        '^': '&#94;'
    };

    const reg = /[<>"'\/\\\)]/gi;
    return html.replace(reg, function(character: TMapKey) {
        return map[character];
    });
}
