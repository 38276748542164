import { BASKET_SELECTORS, COUNTRY_ID } from '@frontstoreRwd/modules/basket_manage/basket_constants';
import { ENABLED_PHONE_VALIDATION_FLAG } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';
import { IPhoneCountrySelect } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import { store } from '@frontstoreRwd/store';
import { CSS_NONE_CLASS } from '@core/constants/css_classes';
import { getBasketInformation } from '@frontstoreApi/basket';

export const doesBasketExist = (): boolean => Boolean(store.basketInfo);

export const areProductsInBasket = (): boolean | null => store.basketInfo && store.basketInfo.products.length > 0;

export const updateBasket = async (): Promise<void> => {
    store.basketInfo = await getBasketInformation({
        // @ts-ignore
        lang: window.Shop.lang.name,
        // @ts-ignore
        currency: window.Shop.values.currency
    });
    localStorage.setItem('basketUpdateTime', String(Date.now()));
};

export const isDifferentAddressChecked = (): boolean => {
    const $differentAddressCheckbox = document.getElementById(BASKET_SELECTORS.addressDifferentId) as HTMLInputElement | null;

    return $differentAddressCheckbox ? $differentAddressCheckbox.checked : false;
};

export const isPolandSelected = (countrySelectSelector: string): boolean => {
    const $countrySelect = document.querySelector<HTMLSelectElement>(countrySelectSelector);

    return $countrySelect ? $countrySelect.value == COUNTRY_ID.Poland : false;
};

export const togglePrimaryPhoneCountrySelectActivity = (phoneCountryInstance: IPhoneCountrySelect): void => {
    const isPrimaryPhoneCountrySelect = phoneCountryInstance.$phoneInput.getAttribute('id') === BASKET_SELECTORS.primaryInputPhoneID;

    if (!isPrimaryPhoneCountrySelect) {
        return;
    }

    if (isDifferentAddressChecked()) {
        phoneCountryInstance.enablePhoneCountrySelect();
        phoneCountryInstance.removeHiddenPhoneCountryInput();
    } else {
        phoneCountryInstance.disablePhoneCountrySelect();
        phoneCountryInstance.addHiddenPhoneCountryInput();
    }

    phoneCountryInstance.setPhoneCountryHintVisibility();
};

export const shouldPhoneCountrySelectBeVisible = (phoneInstance: IPhoneCountrySelect): boolean => {
    const phoneName = phoneInstance.$phoneInput.getAttribute('name');

    if (!phoneName) {
        return false;
    }

    const isPhoneInputAvailable = phoneInstance.$phoneInput.closest(`.${CSS_NONE_CLASS}`);
    const countryForPhoneSelector = `${BASKET_SELECTORS.countryForPhone}${phoneName}`;

    return isPhoneValidationEnabled(phoneInstance) && isPolandSelected(countryForPhoneSelector) && isPhoneInputAvailable == null;
};

export const isPhoneValidationEnabled = (phoneInstance: IPhoneCountrySelect | undefined): boolean => {
    if (!phoneInstance) return false;

    const phoneCountrySelectContainer = document.querySelector(phoneInstance.selectors.phoneCountrySelectContainer) as HTMLDivElement;

    return phoneCountrySelectContainer.dataset?.phoneValidation === ENABLED_PHONE_VALIDATION_FLAG;
};
