export const CONTENT_TYPES: Record<string, string> = {
    shop_index: 'Main page',
    shop_product: 'Products | Product',
    shop_product_list: 'Products | Category',
    shop_categories_list: 'Products | Categories list',
    shop_product_producer: 'Products | Vendor',
    shop_product_promo: 'Products | Promotions',
    shop_product_new: 'Products | New',
    shop_product_day: 'Products | Products of the day',
    shop_product_search: 'Products | Search results',
    shop_product_loyaltylist: 'Products | Loyalty list',
    shop_product_mailfriend: 'Products | Mail to friend',
    shop_infopage: 'Info page',
    shop_news: 'Blog | News',
    shop_news_list: 'Blog | News list',
    shop_news_category_list: 'Blog | Category',
    shop_news_tag_list: 'Blog | Tag list',
    shop_basket: 'Cart | Cart',
    shop_basket_address: 'Cart | Address',
    shop_basket_step2: 'Cart | Step 2',
    shop_basket_step3: 'Cart | Step 3',
    shop_basket_done: 'Cart | Done',
    shop_basket_finished: 'Cart | Finished',
    shop_login: 'User | Login',
    shop_login_register: 'User | Register',
    shop_login_passremind: 'User | Password reminder',
    shop_panel: 'Panel | Main',
    shop_panel_orders: 'Panel | Order list',
    shop_panel_order: 'Panel | Order',
    shop_panel_payment: 'Panel | Payment',
    shop_panel_comments: 'Panel | Comments',
    shop_panel_favourites: 'Panel | Favourites',
    shop_panel_addresses: 'Panel | Addresses',
    shop_panel_address: 'Panel | Address',
    shop_panel_password: 'Panel | Password change',
    shop_panel_edit: 'Panel | Edit profile',
    shop_contact: 'Contact form',
    shop_facebook_splash: 'Facebook splash',
    shop_links_list: 'Links list',
    shop_newsletter_unsign: 'Newsletter | Unsign'
};

export const FBE_COOKIE_NAME = 'fbe_conversion_allow';
export const FBE_COOKIE_EXPIRATION_TIME_IN_DAYS = 365;
