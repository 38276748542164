export default function recentBoxRenderStrategy({ moduleElement: $el, shoperModule }: any) {
    const $boxRecent = $el.find('#box_recent');
    const boxSlider = shoperModule.parent.selectorFunctions.boxslider;

    if (!shoperModule.parent.lazypicture) {
        shoperModule.parent.lazypicture = new shoperModule.parent.classes.LazyPicture();
    }

    //@ts-ignore
    if ($boxRecent.hasClass('slider') && shoperModule.parent.status.load && !$boxRecent.selectorFnTriggered) {
        boxSlider.load($boxRecent, shoperModule.parent);
    }

    shoperModule.parent.lazypicture.updateImagesList();
}
