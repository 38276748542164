import { FBE_COOKIE_EXPIRATION_TIME_IN_DAYS, FBE_COOKIE_NAME } from './facebook_business_extension_constants';

import { AnalyticManager } from '../../classes/manager';
import Cookies from 'js-cookie';
import { FACEBOOK_BUSINESS_EXTENSION } from '../services_names';
import { FacebookBusinessExtensionService } from './facebook_business_extension';
import { TFacebookBusinessExtensionInitializerOptions } from '../../types';

export class FacebookBusinessExtensionInitializer {
    private _analyticsManager: AnalyticManager;
    private _options: TFacebookBusinessExtensionInitializerOptions;

    constructor(analyticManager: AnalyticManager, options: TFacebookBusinessExtensionInitializerOptions) {
        this._analyticsManager = analyticManager;
        this._options = options;
    }

    public async init(): Promise<void> {
        const FacebookBusinessExtensionActive = this._options && this._options.isActive && this._options.pixelId;

        if (FacebookBusinessExtensionActive) {
            const service = new FacebookBusinessExtensionService(FACEBOOK_BUSINESS_EXTENSION, {
                pixelId: this._options.pixelId
            });
            this._analyticsManager.register(service);

            Cookies.set(FBE_COOKIE_NAME, '1', { expires: FBE_COOKIE_EXPIRATION_TIME_IN_DAYS });
        }
    }
}
