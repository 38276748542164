import { AnalyticService } from '@frontstoreRwd/modules/analytics/classes/service';

import { GoogleAnalyticV4GTMService } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/gtm';
import eventsHandlersMapper from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/gtm/events';
import {
    TGoogleAnalyticsV4GTMOptions,
    TGoogleAnalyticsV4GTMEvent
} from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/gtm/types';
import { PlatformGoogleAnalyticsV4Map } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/platform_gtm/mapper';
import { TServiceName } from '@frontstoreRwd/modules/analytics/services/types';
import { TGoogleAnalyticsV4GtagOptions } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/gtag/types';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy';

export class PlatformGoogleAnalyticV4GTMService extends AnalyticService<GoogleAnalyticV4GTMService> {
    private _options: TGoogleAnalyticsV4GTMOptions;
    private _layer: unknown[] = [];
    private _mapper = PlatformGoogleAnalyticsV4Map;

    protected _events: TGoogleAnalyticsV4GTMEvent = eventsHandlersMapper;

    constructor(name: TServiceName, options: TGoogleAnalyticsV4GTMOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._layer = window[this._options.dataLayerName] || [];

        if (customerPrivacy.isEnabled()) {
            this._setDefaultConsents();
            this._updateConsents();
        }

        this._isSetuped = true;
    }

    private _setDefaultConsents(): void {
        this._layer.push('consent', 'default', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'granted'
        });
    }

    private _updateConsents(): void {
        customerPrivacy.onMarketingConsentGranted(() => {
            this._layer.push('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted'
            });
        });
    }

    public getLayer(): unknown[] {
        return this._layer;
    }

    public getMapper(): typeof PlatformGoogleAnalyticsV4Map {
        return this._mapper;
    }

    public getRemarketingOptions(): TGoogleAnalyticsV4GtagOptions['googleRemarketing'] {
        return undefined;
    }
}
