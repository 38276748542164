import { ANALYTICS_CONFIG_ELEMENT_ID } from './constants';
import { AnalyticManager } from './classes/manager';
import { FacebookBusinessExtensionInitializer } from './services/facebook_business_extension/facebook_business_extension_initializer';
import Features from '@core/tools/features';
import { GoogleAnalyticsV4Initializer } from './services/google_analytics_v4/initializer';
import { TAnalyticsOptions, TGoogleAnalyticsV4Options } from './types';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import { getJSONFromScriptTag } from '@core/dom_utilities/get';
import { CAMPAIGN_GOOGLE_ANALYTICS_V4 } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/constants';
import { MicrosoftAdsInitializer } from '@frontstoreRwd/modules/analytics/services/microsoft_ads/microsoft_ads_initializer';

export class AnalyticsInitializer {
    private _analyticsOptions: TAnalyticsOptions | undefined;
    private _analyticsManager: AnalyticManager;

    constructor() {
        this._analyticsOptions = getJSONFromScriptTag(ANALYTICS_CONFIG_ELEMENT_ID) as TAnalyticsOptions;
        this._analyticsManager = new AnalyticManager();
    }

    public async init(): Promise<void> {
        if (!this._analyticsOptions) {
            return;
        }

        if (
            Features.isEnabled('google_analytics_v4') ||
            Features.isEnabled('partner_google_analytics_v4') ||
            Features.isEnabled('google_analytics_v4_campaign')
        ) {
            let googleCampaignOptions: TGoogleAnalyticsV4Options;

            if (Features.isEnabled('ga_campaign_move_from_plugins')) {
                const googleCampaignsTrackingId = this._getTrackingIdFromMetaTag('shoper_campaigns_analytics_measurment_id');
                googleCampaignOptions = {
                    isActive: googleCampaignsTrackingId ? '1' : '0',
                    isGtm: '0',
                    trackingId: googleCampaignsTrackingId || ''
                };
            } else {
                googleCampaignOptions = this._analyticsOptions.campaignGoogleAnalyticsV4;
            }

            new GoogleAnalyticsV4Initializer(this._analyticsManager, [
                this._analyticsOptions.clientGoogleAnalyticsV4,
                {
                    ...googleCampaignOptions,
                    ...CAMPAIGN_GOOGLE_ANALYTICS_V4
                }
            ]);
        }

        customerPrivacy.onAnalyticsConsentGranted(() => {
            if (!this._analyticsOptions) {
                return;
            }

            if (Features.isEnabled('facebook_business_extension')) {
                new FacebookBusinessExtensionInitializer(this._analyticsManager, this._analyticsOptions.facebookBusinessExtension).init();
            }

            if (Features.isEnabled('microsoft_ads')) {
                const uetg = this._getTrackingIdFromMetaTag('uet-tag-for-microsoft');

                if (uetg) {
                    new MicrosoftAdsInitializer(this._analyticsManager, uetg);
                }
            }
        });
    }

    private _getTrackingIdFromMetaTag(metaTagName: string): string | null {
        const meta = document.querySelector(`meta[name="${metaTagName}"]`);
        if (meta) {
            return meta.getAttribute('content');
        }

        return null;
    }
}
