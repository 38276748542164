import { TErrorName } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';
import { IFormErrorRenderer } from '@frontstoreRwd/modules/form_error_renderer/form_error_renderer_types';
import { FORM_ERRORS_CSS_CLASSES, FORM_ERRORS_JS_CLASSES } from '@frontstoreRwd/modules/form_error_renderer/form_error_renderer_constants';

export class FormErrorRenderer implements IFormErrorRenderer {
    public render($element: HTMLElement, errorsNameList: Array<TErrorName>): void {
        const elementName = $element.getAttribute('name') as string;

        if (this._isErrorContainerCreated(elementName)) {
            this.removeErrors($element);
        }

        const $errorMarkup = this._createErrorsMarkup(elementName, errorsNameList);

        $element.closest(`.${elementName}`)?.after($errorMarkup);

        this._setErrorClasses($element);
    }

    private _isErrorContainerCreated = (elementName = ''): boolean => {
        const isErrorContainerCreated = document.querySelector(`.${FORM_ERRORS_JS_CLASSES.errorContainer}${elementName}`);

        return isErrorContainerCreated ? true : false;
    };

    public removeErrors($element: HTMLElement): void {
        const elementName = $element.getAttribute('name') as string;

        document.querySelector(`.${FORM_ERRORS_JS_CLASSES.errorContainer}${elementName}`)?.remove();

        this._clearErrorClasses($element);
    }

    private _clearErrorClasses($element: HTMLElement): void {
        const elementName = $element.getAttribute('name') as string;

        if (document.querySelector(`.${FORM_ERRORS_CSS_CLASSES.errorContainer}${elementName}`) != null) {
            return;
        }

        $element.closest(`.${FORM_ERRORS_JS_CLASSES.inputContainer}`)?.classList.remove(FORM_ERRORS_CSS_CLASSES.error);
    }

    private _createErrorsMarkup(elementName: string, errorsNameList: Array<TErrorName>): HTMLTableRowElement {
        const $errorMarkup = document.createElement('tr');

        $errorMarkup.classList.add(
            `${FORM_ERRORS_CSS_CLASSES.errorContainer}${elementName}`,
            `${FORM_ERRORS_JS_CLASSES.errorContainer}${elementName}`
        );

        const $emptyTableCell = document.createElement('td');
        const $errorContent = document.createElement('td');

        $errorContent.classList.add(`${FORM_ERRORS_CSS_CLASSES.error}`);

        const $errorsList = document.createElement('ul');

        $errorsList.classList.add(`${FORM_ERRORS_CSS_CLASSES.inputError}`);

        errorsNameList.forEach((errorName: TErrorName) => {
            $errorsList.append(this._createErrorListItem(errorName));
        });

        $errorContent.append($errorsList);
        $errorMarkup.append($emptyTableCell, $errorContent);

        return $errorMarkup;
    }

    private _createErrorListItem = (errorName: TErrorName): HTMLLIElement => {
        const $error = document.createElement('li');

        // @ts-ignore
        $error.textContent = window.Shop?.lang.validation[errorName];

        return $error;
    };

    private _setErrorClasses($element: HTMLElement): void {
        $element.closest(`.${FORM_ERRORS_JS_CLASSES.inputContainer}`)?.classList.add(FORM_ERRORS_CSS_CLASSES.error);
    }
}
