;(function (undefined) {
    Shop.BundleStockHandlerContainer.include({
        instances: [],

        elements: {
            $container: null,
            $children: null
        },

        productsInBundle: null,

        composing: {
            enabled: false,
            limit: null
        },

        bundle: {
            selectedProducts: []
        },

        events: {
            preventBuy: null
        },

        initialize: function (options) {
            var self;
            var uploaded;

            self = this;
            this.elements.$container = $('#box_bundle');
            this.productsInBundle = this.elements.$container.data('bundleItems');
            this.composing.enabled = this.elements.$container.data('composing') == '1';
            this.composing.limit = parseFloat(this.elements.$container.data('composingLimit'));

            if (!$('.stock-control').length) {
                $('.bundle-with-variants').remove();
            }

            this.elements.$container.find('.product').each(function (index) {
                var $el;
                var $stockBundle;
                var hasVariants;
                var stockId;
                var bundleId;

                $el = $(this);
                hasVariants = $el.data('hasVariants') == '1';
                stockId = parseInt($el.data('stockId'), 10);
                bundleId = parseInt($el.data('bundleId'), 10);
                $stockBundle = $el.find('.stocks-bundle');

                if ($el.find('.stock-control').length <= 2) {
                    $stockBundle.addClass('stocks-bundle-full');
                }

                if (hasVariants) {
                    if (!$stockBundle[0].BundleStockHandler) {
                        $stockBundle[0].BundleStockHandler = new Shop.BundleStockHandler({
                            BundleStockContainer: self,
                            id: bundleId,
                            $el: $stockBundle,
                            $view: $stockBundle.parents('.product'),
                            stockId: stockId,
                            stock_id: stockId
                        });

                        self.instances.push($stockBundle[0].BundleStockHandler);
                    }
                } else {
                    self.bundle.selectedProducts.push({
                        id: bundleId,
                        stockId: stockId,
                        stock_id: stockId,
                        quantity: parseFloat($el.find('[data-quantity]').text())
                    });
                }

                $el.find('input[type="text"], input[type="checkbox"]:checked, input[type="radio"]:selected, select, input[type="file"]').trigger('change');
            });

            this.events.preventBuy = function (ev) {
                ev.preventDefault();
                Shop.scrollto($('#box_bundle'));
            };

            this.events.buy = function (ev) {
                $('.form-basket .addtobasket').trigger('click');
            };

            window.addEventListener('scroll', this.toggleFixedCart.bind(this));

            this.toggleFixedCart();
            this.changeBuyAction();

            uploaded = 0;
            if (Shop.useroptions.ajaxbasket.mode !== 2 && !!$('.stocks-bundle .option-file').length) {
                this.parent.addEvent('cart:add', function (ev, evForm, options, $form) {
                    var validate;
                    var filesToUpload = $('input[type="file"].product-option_color_selected').length;

                    options.preventSubmit = true;
                    if (filesToUpload === 0) {
                        $form[0].submit();
                        return true;
                    }
                    $('.stocks-bundle .option-file').each(function () {
                        var fileUploader;
                        var $el = $(this);
                        var $file = $(this).find('input[type="file"]');
                        var fileId = parseInt($file.attr('name').split('_').pop(), 10);
                        var variantId = parseInt($file.data('variantId'), 10);

                        if (!this.FileUploader) {
                            fileUploader = this.FileUploader = new Shop.FileUploader({
                                $file: $file[0],
                                type: 'none',
                                url: '/basket/ajaxupload'
                            });
                        } else {
                            fileUploader = this.FileUploader;
                        }

                        validate = fileUploader.validate();

                        fileUploader.xhr.onreadystatechange = function (ev) {
                            var response;
                            var data;

                            if (fileUploader.xhr.readyState === 4) {
                                response = JSON.parse(fileUploader.xhr.response);

                                if (response && response.success === true) {
                                    uploaded += 1;
                                    self.bundle.selectedProducts.some(function (obj) {
                                        if (obj.id === fileId) {
                                            data = obj;
                                            return true;
                                        }
                                    });

                                    data.options[variantId] = {
                                        'fileHash': response.fileHash
                                    };
                                    self.elements.$children.val(self.parent.base64.encode(JSON.stringify(self.bundle.selectedProducts)));

                                    if (uploaded === filesToUpload) {
                                        $form[0].submit();
                                    }
                                }
                            }
                        };

                        if (validate.valid) {
                            this.FileUploader.send();
                        } else {
                            $el.find('.error-file-upload').remove();
                            validate.errorMessages.forEach(function (error) {
                                $('<span />', {
                                    'class': 'error error-file-upload',
                                    'text': error
                                }).appendTo($el);
                            });
                            validate.errorMessages.length = 0;
                        }
                    });
                });
            } else if (!!$('.stocks-bundle .option-file').length) {
                this.parent.addEvent('cart:preadd:ajax', function (ev, form) {
                    var filesToUpload;
                    form.prevent = true;

                    filesToUpload = $('input[type="file"].product-option_color_selected').length;
                    if (filesToUpload === 0) {
                        // eslint-disable-next-line no-undef
                        shoper.ajaxbasket.sendAjax(form);
                        return true;
                    }
                    $('.stocks-bundle .option-file').each(function () {
                        var fileUploader;
                        var $file = $(this).find('input[type="file"]');
                        var fileId = parseInt($file.attr('name').split('_').pop(), 10);
                        var variantId = parseInt($file.data('variantId'), 10);
                        var settings = {
                            $file: $file[0],
                            type: 'none',
                            url: '/basket/ajaxupload'
                        };

                        if (!this.FileUploader) {
                            this.FileUploader = fileUploader = new Shop.FileUploader(settings);
                        } else {
                            fileUploader = this.FileUploader;
                        }

                        if (fileUploader.xhr) {
                            fileUploader.xhr.onreadystatechange = function (ev) {
                                var data;
                                var response;

                                if (fileUploader.xhr.readyState === 4) {
                                    response = JSON.parse(fileUploader.xhr.response);

                                    if (response && response.success === true) {
                                        uploaded += 1;
                                        self.bundle.selectedProducts.some(function (obj) {
                                            if (obj.id === fileId) {
                                                data = obj;
                                                return true;
                                            }
                                        });

                                        data.options[variantId] = {
                                            'fileHash': response.fileHash
                                        };
                                        self.elements.$children.val(self.parent.base64.encode(JSON.stringify(self.bundle.selectedProducts)));

                                        if (uploaded === filesToUpload) {
                                            uploaded = 0;
                                            // eslint-disable-next-line no-undef
                                            shoper.ajaxbasket.sendAjax(form);
                                        }
                                    }
                                }
                            };
                        }

                        this.FileUploader.send();
                    });
                });
            }

            if (!$('[data-has-variants="1"]').length || !this.elements.$container.find('.option-stock').length) {
                $('.bundle-fixed-cart').find('.bundle-selected').remove();
            }
        },

        isBuyable: function () {
            return (
                (this.composing.enabled === true && this.composing.limit === this.bundle.selectedProducts.length) ||
                (this.productsInBundle === this.bundle.selectedProducts.length)
            );
        },

        changeBuyAction: function () {
            var $form;
            var $buyButton;
            var $buyFixedButton;
            var $fixedCart;
            var btnLabel;

            $form = $('.form-basket');
            $fixedCart = $('.bundle-fixed-cart');
            $buyButton = $('.form-basket .addtobasket');
            $buyFixedButton = $('#fixed-cart-add');

            if (!this.isBuyable()) {
                $buyButton.addClass('btn-inactive').text(Shop.lang.bundle.choose);
                $buyFixedButton.addClass('btn-inactive').text(Shop.lang.bundle.choose);
                $buyButton.off('click', this.events.preventBuy).on('click', this.events.preventBuy);
                $buyFixedButton.off('click', this.events.preventBuy).off('click', this.events.buy).on('click', this.events.buy);
                $fixedCart.removeClass('bundle-fixed-cart-buyable');

                if (this.elements.$children) {
                    this.elements.$children.remove();
                    this.elements.$children = null;
                }
            } else {
                if ($form.hasClass('loyaltyexchange')) {
                    btnLabel = Shop.lang.basket.exchange;
                } else {
                    btnLabel = Shop.lang.basket.add_to_cart;
                }

                $buyButton.removeClass('btn-inactive').text(btnLabel);
                $buyFixedButton.removeClass('btn-inactive').text(btnLabel);
                $buyButton.off('click', this.events.preventBuy);
                $buyFixedButton.off('click', this.events.preventBuy).off('click', this.events.buy).on('click', this.events.buy);
                $fixedCart.addClass('bundle-fixed-cart-buyable');

                this.elements.$children = $('<input />', {
                    'type': 'hidden',
                    'value': this.parent.base64.encode(JSON.stringify(this.bundle.selectedProducts)),
                    'name': 'children'
                }).appendTo($('.form-basket .quantity_wrap'));
            }

            $('.bundle-selected-item').text(this.bundle.selectedProducts.length);
        },

        toggleFixedCart: function () {
            var $fixedCart;
            var $addToBasket;

            $addToBasket = $('.button_wrap .addtobasket');
            $fixedCart = $('.bundle-fixed-cart');
            if (!this.parent.isInViewport($addToBasket) && window.scrollY > $addToBasket.offset().top) {
                $fixedCart.removeClass('none');
            } else {
                $fixedCart.addClass('none');
            }
        }
    });
})();