;(function (undefined) {
	/**
     * @example
     * <ul data-fuzzy-search>
     *      <li data-fuzzy-search-value="list-1">
     *          <h5>list-item-1</h5><div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem animi eligendi voluptate minima.</div>
     *      </li>
     *      <li data-fuzzy-search-value="list-2">
     *          <h5>list-item-1</h5><div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem animi eligendi voluptate minima.</div>
     *      </li>
     *      <li data-fuzzy-search-value="list-3">
     *          <h5>list-item-1</h5><div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem animi eligendi voluptate minima.</div>
     *      </li>
     *      <li data-fuzzy-search-value="list-4">
     *          <h5>list-item-1</h5><div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem animi eligendi voluptate minima.</div>
     *      </li>
     *      <li data-fuzzy-search-value="list-5">
     *          <h5>list-item-1</h5><div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem animi eligendi voluptate minima.</div>
     *      </li>
     *      <li data-fuzzy-search-value="list-6">
     *          <h5>list-item-1</h5><div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem animi eligendi voluptate minima.</div>
     *      </li>
     * </ul>
     */
    Shop.FuzzySearch.include({
        options: {
            $list: null,
            alwaysVisible: false,
            placeholder: null
        },

        callbacks: {
            select: null
        },

        classes: {
            hideListContainer: 'fuzzy-search__list-container__hide',
            listItemSelected: 'fuzzy-search__list-item__selected'
        },

        elements: {
            $dropdown: null,
            $search: null,
            $list: null,
            $listContainer: null,
            $searchIcon: null
        },

        listOptions: [],
        optionsValueDOM: {},

        $selectedItem: null,
        isOpen: false,

        $detachedList: null,

        initialize: function (options) {
            var $detachedList;
            this.constructor.setOptions(options);

            if (!!this.options.$list.children('[data-fuzzy-search-value]').length) {
                this.createDOM().insertAfter(this.options.$list);
                $detachedList = this.options.$list.detach();

                this.bindListValueAndDOM($detachedList);
                this.bindEvents();

                if (this.options.alwaysVisible) {
                    this.filter(true);
                }
            }
        },

        bindEvents: function () {
            var self = this;

            this.elements.$search.on('input', this.filter.bind(this));

            $('body').on('click', function () {
                if (self.isOpen) {
                    if (!self.options.alwaysVisible) {
                        self.elements.$search.val('');
                        self.showSearchIcon();
                        self.close();
                    }
                }
            });

            this.elements.$dropdown.on('click', function (evt) {
                evt.stopPropagation();

                this.$selectedItem = null;
                self.elements.$search.val($(this).attr(''));
                self.filter(true);
                self.hideSearchIcon();
            });

            this.elements.$dropdown.on('keydown', function (evt) {
                switch(evt.key) {
                    case 'ArrowUp':
                        self.moveListItem('up');
                        break;
                    case 'ArrowDown':
                        self.moveListItem('down');
                        break;
                    case 'Enter':
                        if (self.$selectedItem) {
                            self.selectListItem(self.$selectedItem);
                        }
                        evt.preventDefault();
                        break;
                    case 'Escape':
                        if (!self.options.alwaysVisible) {
                            self.close();
                        }
                        break;
                }

            });

            this.elements.$list.on('click', 'li', function (evt) {
                var $currentTarget = $(evt.currentTarget);
                evt.stopPropagation();
                evt.preventDefault();

                self.selectListItem($currentTarget);

                if (typeof self.callbacks.select === "function") {
                    self.callbacks.select(self, evt);
                }
            });
        },

        moveListItem: function (direction) {
            var $currItem;
            var $nextItem;
            var $prevItem;

            if (!this.$selectedItem) {
                $currItem = this.elements.$list.find('li').first();

                $currItem.addClass(this.classes.listItemSelected);
                this.$selectedItem = $currItem;

                return;
            }

            if (direction === 'down') {
                $nextItem = this.$selectedItem.next('li');

                if (!!$nextItem.length) {
                    this.$selectedItem.removeClass(this.classes.listItemSelected);
                    $nextItem.addClass(this.classes.listItemSelected);

                    this.$selectedItem = $nextItem;
                }
                    } else {
                $prevItem = this.$selectedItem.prev('li');

                if (!!$prevItem.length) {
                    this.$selectedItem.removeClass(this.classes.listItemSelected);
                    $prevItem.addClass(this.classes.listItemSelected);

                    this.$selectedItem = $prevItem;
                    }
            }
        },

        selectListItem: function ($currentTarget) {
            $currentTarget.addClass(this.classes.listItemSelected);
            this.$selectedItem = $currentTarget;
            this.elements.$search.val($currentTarget.attr('data-fuzzy-search-value'));

            if (!this.options.alwaysVisible) {
                this.close();
                }
        },

        open: function () {
            this.isOpen = true;
            this.elements.$listContainer.removeClass(this.classes.hideListContainer);
        },

        close: function () {
            this.$selectedItem = null;
            this.isOpen = false;
            this.elements.$listContainer.addClass(this.classes.hideListContainer);
        },

        hideSearchIcon: function () {
            this.elements.$searchIcon.addClass('none');
        },

        showSearchIcon: function () {
            this.elements.$searchIcon.removeClass('none');
        },

        bindListValueAndDOM: function ($detachedList) {
            var self = this;
            var $listItem;
            var value;

            $detachedList.children().each(function (index, listItem) {
                $listItem = $(listItem);
                value = $listItem.attr('data-fuzzy-search-value');

                Object.defineProperty(self.optionsValueDOM, value, {
                    value: $listItem.html(),
                    writable: true
            });

                self.listOptions.push(value);
            });
        },

        createDOM: function () {
            var self = this;
            var $searchWrapper;

            this.elements.$dropdown = $('<div />', {
                'class': 'fuzzy-search '
            });

            $searchWrapper = $('<div />', {
                'class': 'fuzzy-search__search-wrapper'
            }).appendTo(this.elements.$dropdown);

            this.elements.$searchIcon = $('<a />', {
                'class': 'fa fa-search fuzzy-search__search-icon',
            }).append($('<img />', {
                'src':  self.parent.url('libraries/images/1px.gif'),
                'class': '1px'
            }));

            this.elements.$searchIcon.appendTo($searchWrapper);

            this.elements.$search = $('<input />', {
                'class': 'fuzzy-search__search',
                'type': 'search',
                'placeholder': self.options.placeholder || Shop.lang.common.search
            }).appendTo($searchWrapper);

            this.elements.$listContainer = $('<div />', {
                'class': 'fuzzy-search__list-container fuzzy-search__list-container__hide'
            }).appendTo(this.elements.$dropdown);

            this.elements.$list = $('<ul />', {
                'class': 'fuzzy-search__list'
            }).appendTo(this.elements.$listContainer);

            if (this.options.alwaysVisible) {
                this.elements.$listContainer.removeClass('fuzzy-search__list-container__hide');
                this.elements.$dropdown.addClass('fuzzy-search_always-visible');
                }

            return this.elements.$dropdown;
        },

        filter: function (resetFilter) {
            var options = this.listOptions.slice(0);
            var searchValue = this.elements.$search.val();
            var exactSearch;
            var firstSearch;
            var fuzzySearch;
            var filteredList;
            var toSplice;

            if (searchValue !== '' && resetFilter !== true) {
                exactSearch = new RegExp('^' + searchValue + '$', 'i');
                firstSearch = new RegExp('^' + searchValue, 'i');
                fuzzySearch = new RegExp('(^' + searchValue + ')|(' + searchValue + ')|(' + searchValue.replace(/\s+/g, '').split('').join('.*') + ')', 'i');

                filteredList = [];
                toSplice = [];

                options.forEach(function (option, index) {
                    if (option.match(exactSearch)) {
                        filteredList.push(option);
                        toSplice.push(index);
                    }
                });

                while (toSplice.length) {
                    options.splice(toSplice.pop(), 1);
                }

                toSplice = [];
                options.forEach(function (option, index) {
                    if (option.match(firstSearch)) {
                        filteredList.push(option);
                        toSplice.push(index);
                    }
                });

                while (toSplice.length) {
                    options.splice(toSplice.pop(), 1);
                }

                options.forEach(function (option, index) {
                    if (option.match(fuzzySearch)) {
                        filteredList.push(option);
                    }
                });

                this.appendOptions(filteredList, {
                    filtering: true
                });
            } else {
                this.appendOptions(options, {
                    filtering: true
                });
            }
        },

        appendOptions: function (options) {
            var self = this;
            var $listFragment = $(document.createDocumentFragment());

            if (!this.options.alwaysVisible) {
                this.open();
            }

            this.elements.$list.empty();
            this.$selectedItem = null;

            options.forEach(function (listItem) {
                $listFragment.append($('<li />', {
                    'class': 'fuzzy-search__list-item',
                    'data-fuzzy-search-value': listItem
                }).append(self.optionsValueDOM[listItem]));
            });

            this.elements.$list.append($listFragment);
        }
    });
})();