;(function (undefined) {
    Shop.SearchHistory = function (options) {
        this.options = options || {
            onHistoryItemClick: null,
            onHistoryDestory: null
        };

        this.settings = {
            maxItems: 18
        };

        this.objects = {
            basicTemplate: new Shop.SearchTemplate({
                type: Shop.Search.SEARCH_GLOBAL_SETTINGS.history
            })
        };

        this.elements = {
            $history: null
        };

        this.HISTORY = [];

        this.initialize();
    };

    Shop.SearchHistory.prototype = {
        constructor: Shop.SearchHistory,

        parent: Shop.prototype,

        initialize: function () {
            var storageHistory;

            if (localStorage.getItem('search-front-history')) {
                storageHistory = JSON.parse(this.parent.base64.decode(localStorage.getItem('search-front-history')));
                this.HISTORY = storageHistory;
            }

            return this;
        },

        getItemsCount: function () {
            return this.HISTORY.length;
        },

        save: function (value) {
            var index;

            if (!value.length) {
                return;
            }

            value = Shop.fn.simpleSanitizeHTML(value);
            index = this.HISTORY.indexOf(value);

            if (index !== -1) {
                this.HISTORY.splice(index, 1);
            }

            this.HISTORY.unshift(value);

            if (this.HISTORY.length > this.settings.maxItems) {
                this.HISTORY.pop();
            }

            _saveHistoryToStorage(this);

            return this;
        },

        remove: function (value) {
            var index = this.HISTORY.indexOf(value);

            if (index !== -1) {
                this.HISTORY.splice(index, 1);
            }

            _saveHistoryToStorage(this);

            if (!this.HISTORY.length) {
                this.destroy();
            }

            return this;
        },

        clean: function () {
            this.objects.basicTemplate.clean();

            return this;
        },

        destroy: function () {
            if (typeof this.options.onHistoryDestory === 'function') {
                this.options.onHistoryDestory();
            }

            return this;
        },

        template: function () {
            var self = this;
            var $markup;

            var historyItems = this.HISTORY.map(function (value) {
                var markup = '' +
                '<div class="search__history-item">' +
                    '<span class="search__icon_main-color search__list-icon icon icon-reload"></span>' +
                    '<span class="search__history-item-value">' + value + '</span>' +
                '</div>';

                return {
                    markup: markup,
                    attributes: {
                        'data-history-item-value': value
                    },
                };
            });

            $markup = this.objects.basicTemplate.template({
                title: Shop.lang.search.history,
                showLimit: 3,
                loadMoreCount: 3,
                elementsCount: this.HISTORY.length,
                listItems: historyItems,
                listItemRemoveable: true,
                cssSectionClass: 'search__history',
                onListItemRemove: function (evt, $el) {
                    self.remove($el.attr('data-history-item-value'));
                },
                onListItemClick: function (evt, $el) {
                    if (typeof self.options.onHistoryItemClick === 'function') {
                        self.options.onHistoryItemClick(evt, $el);
                    }
                }
            });

            return $markup;
        }
    };

    function _saveHistoryToStorage (historyInstance) {
        try {
            localStorage.setItem('search-front-history', (historyInstance.parent.base64.encode(JSON.stringify(historyInstance.HISTORY))));
        } catch (err) {
            console.error(err);
        }
    }
})();