export class MicrosoftAdsSDK {
    public static async create(): Promise<void> {
        const id = 'microsoft-jssdk';
        if (document.getElementById(id)) {
            return;
        }

        await MicrosoftAdsSDK._injectScript(id);
    }

    private static _injectScript(id: string): Promise<void> {
        return new Promise((resolve) => {
            const firstScriptOnPage = document.getElementsByTagName('script')[0];
            const sdkScript = document.createElement('script');

            sdkScript.id = id;
            sdkScript.src = `https://bat.bing.com/bat.js`;
            sdkScript.async = true;
            sdkScript.onload = () => {
                resolve();
            };

            firstScriptOnPage.parentNode?.insertBefore(sdkScript, firstScriptOnPage);
        });
    }
}
