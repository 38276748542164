export function getTemplate(templateId: string): HTMLElement[] {
    const template: HTMLTemplateElement = <HTMLTemplateElement>document.getElementById(templateId);
    const templateContent: DocumentFragment = template.content;

    // next line is Vue template v-pre fix
    const templateChildren = template.childNodes;
    const templateNodes: HTMLCollection = document.importNode(templateContent, true).children;
    const surveryTemplateHTMLElements: HTMLElement[] = <HTMLElement[]>[...templateNodes, ...templateChildren];

    return surveryTemplateHTMLElements;
}

export function getTemplateFromScriptTag(scriptID: string): string | null | undefined {
    return document.getElementById(scriptID)?.textContent;
}

export function getJSONFromScriptTag(scriptID: string): unknown | undefined {
    try {
        const $configAnalyticsScript = document.getElementById(scriptID);

        if ($configAnalyticsScript) {
            const jsonString = $configAnalyticsScript.textContent || '';

            if (jsonString) {
                return JSON.parse(jsonString.trim());
            }
        }
    } catch (err) {
        console.error(err);
    }
}
