export const PRODUCT_CLICKED = 'productClicked';
export const PRODUCT_ADDED_TO_CART = 'productAddedToCart';
export const PRODUCT_REMOVED_FROM_CART = 'productRemovedFromCart';
export const PRODUCT_DISPLAYED = 'productDisplayed';
export const PRODUCTS_DISPLAYED = 'productsDisplayed';

export const BEGAN_CHECKOUT = 'beganCheckout';
export const CART_DISPLAYED = 'cartDisplayed';
export const CHECKOUT_OPTIONS_SUBMITED = 'checkoutOptionsSubmited';
export const PURCHASED = 'purchased';
