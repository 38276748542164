;(function (undefined) {
	Shop.ProductAvailability.include({
        options : {
            selectors : { },
            requestUrl: 'product/getproduct/id/'
        },
        
        initialize: function(options) {
            this.constructor.setOptions(options);
            var shopClass = this.parent;
            var btns = $(this.options.selectors.availabilitynotifier_btn);
            var unsubBtns = $(this.options.selectors.availabilitynotifier_btn_unsub);
            var shopClass = this.parent;
            var self = this;
            var subscribeStock = function(element, stock_id, email, is_logged, modal) {
                var btnsParent = $(element).parents('form'),
                    requestData = { 'stock_id' : stock_id };
            
                if(btnsParent.length === 0) {
                    btnsParent = $(element).parents('.actions');
                }

                var btnsCon = $(btnsParent[0]).find(self.options.selectors.availabilitynotifier),
                    unsubBtnsCon = $(btnsParent[0]).find(self.options.selectors.availabilitynotifier_unsub);

                if(!is_logged) {
                    requestData.email = email;
                }
                
                $.ajax({
                    url: shopClass.url('product/availabilitysub'),
                    async: false,
                    cache: false,
                    method: 'post',
                    data: requestData
                }).error(function(xhr){
                    shopClass.alert(Shop.lang.common.request.send_error);
                }).success(function(json) {
                    var x;
                    var stocks;
                    var stock;

                    if(json == '1') {
                        btnsCon.addClass('none');
                        unsubBtnsCon.removeClass('none');
                        if(modal) {
                            modal.destroyModal();
                        }
                        shopClass.alert(Shop.lang.product.availability_success);

                        if (shoper && shoper.stockhandler) {
                            stocks = shoper.stockhandler.downloadedStock;
                            for (x in stocks) {
                                if (stocks.hasOwnProperty(x)) {
                                    if (stocks[x].sid === parseInt(stock_id, 10)) {
                                        stock = stocks[x];
                                        break;
                                    }
                                }
                            }
                        }

                        if (stock) {
                            stock.is_subscribed = true;
                        }
                    } else if(json == '2') {
                        if(modal) {
                            modal.destroyModal();
                        }
                        shopClass.alert(Shop.lang.product.availability_email_found);
                    } else {
                        var messages = self._parseMessages(json);                            
                        if(modal) {
                            modal.options.content.find('input').addClass('error');
                            modal.options.content.prepend(
                                $('<div class="alert-error alert">\n\
                                        <div class="row"><p>' + messages + '</p></div>\n\
                                    </div>')
                            );
                        } else {
                            shopClass.alert(messages);
                        }
                    }
                });
            };
            
            var unsubscribeStock = function(element, stock_id) {
                var btnsParent = $(element).parents('form');
                var requestData = { 'stock_id' : stock_id };
            
                if(btnsParent.length === 0) {
                    btnsParent = $(element).parents('.actions');
                }

                var btnsCon = $(btnsParent[0]).find(self.options.selectors.availabilitynotifier);
                var unsubBtnsCon = $(btnsParent[0]).find(self.options.selectors.availabilitynotifier_unsub);

                $.ajax({
                    url: shopClass.url('product/availabilityunsub'),
                    async: false,
                    cache: false,
                    method: 'post',
                    data: requestData
                }).error(function(xhr){
                    shopClass.alert(Shop.lang.common.request.send_error);
                }).success(function(json) {
                    var x;
                    var stocks;
                    var stock;

                    switch(json) {
                        case 1:
                            btnsCon.removeClass('none');
                            unsubBtnsCon.addClass('none');
                            shopClass.alert(Shop.lang.product.availability_unsub_success);

                            if (shoper && shoper.stockhandler) {
                                stocks = shoper.stockhandler.downloadedStock;
                                for (x in stocks) {
                                    if (stocks.hasOwnProperty(x)) {
                                        if (stocks[x].sid === parseInt(stock_id, 10)) {
                                            stock = stocks[x];
                                            break;
                                        }
                                    }
                                }
                            }

                            if (stock) {
                                stock.is_subscribed = false;
                            }
                            break;
                        case 0:
                            shopClass.alert(Shop.lang.product.availability_unsub_not_found);
                            break;
                        case -1:
                            shopClass.alert(Shop.lang.product.availability_unsub_not_logged);
                            break;
                    }
                });
            };

            btns.each(function() {
                var product_name = $(this).attr('data-product-name');  
                    
                $(this).on('click', function(ev) {
                    ev.preventDefault();
                    
                    var is_logged = $(this).attr('data-is-logged') === 'true',
                        stock_id = $(this).attr('data-stock-id'),
                        req,
                        productId,
                        me = this;
                    
                    if(is_logged) {
                        subscribeStock(this, stock_id, false, true, false);
                    } else {                   
                        var modal = new Shop.Modal();

                        productId = $(this).data('productId')

                        req = $.ajax({
                            url: self.parent.url(self.options.requestUrl + productId),
                            type: "get"
                        });

                        req.done(function (data) {
                            var modal = new Shop.Modal();
                            var productCard;
                            var subAdd;
                            var productImg;
                            var stocks;

                            if ($(me).parents('#box_productfull').length <= 0) {
                                subAdd = true;
                                productCard = $(me).parents('.product');
                            } else {
                                subAdd = false;
                                productCard = $('#box_productfull');
                            }

                            if (!subAdd) {
                                if (productCard.find('.mainimg img').length > 0) {
                                    productImg = productCard.find('.mainimg img').attr('src');
                                }
                            } else {
                                productImg = $(productCard.find('img')[0]).attr('src');
                            }

                            stocks = '';
                            if ($('.stocks').length > 0) {
                                $('.stocks > .f-row').each(function (index) {
                                    var label,
                                        input,
                                        div,
                                        value;

                                    label =  $(this).find('div.label label').text().replace('* ', '');
                                    input = $(this).find('div.stock-options input, div.stock-options select');
                                    div = $(this).find('.stock-options > div');
                                    
                                    if (div.hasClass('option_truestock')) {
                                        switch (input.attr('type')) {
                                            case 'text': {
                                                value = input.val();
                                                break;  
                                            }
                                            case 'radio': {
                                                value = input.filter(':checked').parent().next().text();
                                                break;  
                                            }
                                            case 'checkbox': {
                                                if (input.is(':checked')) {
                                                    value = input.next().attr('data-yes');
                                                }
                                                break;  
                                            }
                                            case undefined: {
                                                value = input.find(':selected').text();
                                                break;  
                                            }
                                        };

                                        if (value) {
                                            stocks += (label + '<strong>' + value + '</strong>');

                                            if ((index + 1) !== $('.stocks > .f-row').length) {
                                                stocks += ', ';
                                            }
                                        }
                                    }
                                });
                            }

                            modal.options.footer = '';
                            modal.options.header = $('<h3 />').text(Shop.lang.product.availability_notify_title);
                            modal.options.content = $('<div class="clearfix"><div class="availability_notify_info">\n\
                            <img src="' + productImg + '" />\n\
                            </div>\n\
                            <form class="availability_notify_form">\n\
                                <h3>' + product_name +  '</h3>\n\
                                <p class="availability-stocks">' + stocks + '</p>\n\
                                <div class="notify-small-font">' + data.short_description + '</div>\n\
                                <p>' + Shop.lang.common.email + ':</p>\n\
                                <p><input type="text" value="">\n\
                                <button class="btn btn-red" type="submit">\n\
                                    <span>' + Shop.lang.product.availability_save + '</span>\n\
                                </button>\n\
                                <p>' + Shop.lang.product.availability_email_description + '</p>\n\
                            </form></div>');

                            modal.createModal();
                            modal.options.content.find('button').on('click', function(ev) {
                                ev.preventDefault();
                                modal.options.content.find('div.alert').remove();
                                subscribeStock(this, stock_id, modal.options.content.find('input').val(), false, modal);
                            });
                        }.bind(this));
                    }
                });
            });

            unsubBtns.each(function() { 
                $(this).on('click', function(ev) {
                    ev.preventDefault();
                    unsubscribeStock(this, $(this).attr('data-stock-id'));
                });
            });
        },
        
        _parseMessages: function(json) {
            var messages = '';
            if(typeof json === 'string') {
                messages = json;
            } else if(json instanceof Array) { 
                messages =  json.join(', ');
            } else {
                for (var m in json) {
                    var r = this._parseMessages(json[m]);
                    if(r.length !== 0 && messages.length !== 0) {
                        messages += ', ';
                    }
                    messages += r;
                }
            }
            
            return messages;
        }
    });
})();