import { $once } from '@core/tools/event_bus';
import { CONSENTS_SAVED } from '../../consents_api/event_names';
import { ConsentsGroup } from '../../consents_group';
import { ConsentsGroupMap } from '../../consents_group/mapper';
import { IHandle } from '@frontstoreRwd/core/events/i_handle';
import { xhr } from '@core/tools/xhr';

export class AfterConsentsSaved implements IHandle {
    constructor() {
        this.setupEvents();
    }

    setupEvents(): void {
        $once(CONSENTS_SAVED, (consentsGroup: unknown) => {
            if (consentsGroup instanceof ConsentsGroup) {
                const userConsents = ConsentsGroupMap.grantedToString(consentsGroup);
                this._sendUserConsentsData(userConsents);
            }
        });
    }

    private _sendUserConsentsData(grantedConsents: string) {
        try {
            xhr({
                url: `/__s/consents_granted/${grantedConsents}`
            });
        } catch (err) {
            console.log(err);
        }
    }
}
