/* eslint-disable prefer-spread */
import { EVENTS_VERSION } from '../../constants';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';

export class FacebookEvents {
    static create(): void {
        customerPrivacy.onAnalyticsConsentGranted(() => {
            FacebookEvents._injectScript();
        });
    }

    private static _injectScript() {
        const firstScriptOnPage = document.getElementsByTagName('script')[0];
        const facebookEventsScript = document.createElement('script');

        let fbq: Record<string, any> = {};

        //@ts-ignore
        if (window.fbq) return;
        //@ts-ignore
        fbq = window.fbq = function () {
            // eslint-disable-next-line prefer-rest-params
            fbq.callMethod ? fbq.callMethod.apply(fbq, arguments) : fbq.queue.push(arguments);
        };
        //@ts-ignore
        if (!window._fbq) window._fbq = fbq;
        fbq.push = fbq;
        fbq.loaded = !0;
        fbq.version = EVENTS_VERSION;
        fbq.agent = 'plshoper';
        fbq.queue = [];

        facebookEventsScript.async = !0;
        facebookEventsScript.src = `https://connect.facebook.net/${Shop.lang.name}/fbevents.js`;

        firstScriptOnPage.parentNode?.insertBefore(facebookEventsScript, firstScriptOnPage);
    }
}
