import { ICustomerPrivacyApi } from '../types';

export class CustomerPrivacyApiDisabled implements ICustomerPrivacyApi {
    public isEnabled(): boolean {
        return false;
    }

    onAnalyticsConsentGranted(callback: () => void): void {
        callback();
    }

    onMarketingConsentGranted(callback: () => void): void {
        callback();
    }

    onFunctionalConsentGranted(callback: () => void): void {
        callback();
    }

    onPlatformConsentGranted(callback: () => void): void {
        callback();
    }
}
