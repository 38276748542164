import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import { CurrencyHelper } from '@frontstoreRwd/core/classes/CurrencyHelper';
import {
    TGoogleAnalyticsV4Order,
    TGoogleAnalyticsV4Product,
    TPurchaseParams
} from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/types';

export class PlatformGoogleAnalyticsV4Map {
    public static toV4Product(product: AnalyticProduct): TGoogleAnalyticsV4Product {
        let licenseId = '';

        //@ts-ignore
        if (Shop.values.partnerData) {
            //@ts-ignore
            let partnerData = Shop.values.partnerData + '';
            partnerData = partnerData.substring(10);
            licenseId = partnerData.substring(1);
        }

        const priceFloat = CurrencyHelper.convertStrToNum(product.price);
        const positionFloat = CurrencyHelper.convertStrToNum(product.position);
        const quantityInt = product.quantity ? CurrencyHelper.convertStrToNum(product.quantity) : 1;

        return {
            item_name: product.name, // Name or ID is required.
            item_id: `${licenseId}_${product.id}`,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.variant,
            item_list_name: product.listName || '',
            index: positionFloat,
            price: priceFloat,
            currency: product.currency || '',
            quantity: quantityInt
        };
    }

    public static toV4Order(purchaseParams: TPurchaseParams): TGoogleAnalyticsV4Order {
        let licenseId = '';

        //@ts-ignore
        if (Shop.values.partnerData) {
            //@ts-ignore
            let partnerData = Shop.values.partnerData + '';
            partnerData = partnerData.substring(10);
            licenseId = partnerData.substring(1);
        }

        const revenueFloat = CurrencyHelper.convertStrToNum(purchaseParams.orderSummary.revenue);
        const shippingFloat = CurrencyHelper.convertStrToNum(purchaseParams.orderSummary.shipping);
        const taxFloat = CurrencyHelper.convertStrToNum(purchaseParams.orderSummary.tax);
        const analyticsV4Products = purchaseParams.products.map((product: AnalyticProduct) => this.toV4Product(product));

        return {
            transaction_id: `${licenseId}_${purchaseParams.orderSummary.id}`,
            value: revenueFloat,
            shipping: shippingFloat,
            currency: purchaseParams.currency || '',
            tax: taxFloat,
            items: analyticsV4Products,
            coupon: purchaseParams.coupon
        };
    }
}
