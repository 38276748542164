;(function (undefined) {
	Shop.BasketDeliveryPayment.include({
        options : {
            selectors : { }
        },
        
        initialize: function(options) {
            this.constructor.setOptions(options);
        }
    });
})();