;(function (undefined) {
    var SEARCH_MOBILE_BTN_CLASS = 'search__mobile-btn';
    var SEARCH_MOBILE_BTN_OPEN_CLASS = SEARCH_MOBILE_BTN_CLASS + '_open';
    var SEARCH_MOBILE_BTN_CLOSE_CLASS = SEARCH_MOBILE_BTN_CLASS + '_close';
    var SEARCH_MOBILE_BTN_CLOSING_CLASS = SEARCH_MOBILE_BTN_CLASS + '_closing';

    Shop.SearchMobileBtn = function (options) {
        this.options = options || {
            onClick: null
        };

        this.elements = {
            $btn: null
        };

        this.settings = {
            visibilityOffset: 100
        };

        this.callbacks = {
            onClosingEnd: _onClosingEnd.bind(this)
        };

        this.create();
    };

    Shop.SearchMobileBtn.prototype = {
        constructor: Shop.SearchMobileBtn,

        parent: Shop.prototype,

        create: function () {
            if (this.created) {
                return this;
            }

            this.render();
            _bindEvents(this);
            this.created = true;

            return this;
        },

        show: function () {
            /**
			 * IOS return always 0 for documentElement.scrollTop
			 */
            if (document.body.scrollTop || document.documentElement.scrollTop > this.settings.visibilityOffset && this.elements.$btn) {
                this.elements.$btn.addClass(SEARCH_MOBILE_BTN_OPEN_CLASS);
                this.elements.$btn.removeClass(SEARCH_MOBILE_BTN_CLOSE_CLASS);
            }

            return this;
        },

        hide: function () {
            if (this.elements.$btn && this.elements.$btn.hasClass(SEARCH_MOBILE_BTN_OPEN_CLASS)) {
                this.elements.$btn.addClass(SEARCH_MOBILE_BTN_CLOSING_CLASS);
                this.elements.$btn.on('animationend', this.callbacks.onClosingEnd);
                this.elements.$btn.removeClass(SEARCH_MOBILE_BTN_OPEN_CLASS);
            }

            return this;
        },

        destroy: function () {
            if (!this.created) {
                return this;
            }

            this.elements.$btn.remove();
            this.created = false;
            this.elements.$btn = null;

            return this;
        },

        render: function () {
            this.elements.$btn = $('<button/>', {
                'class': 'btn js__search-mobile-btn ' + SEARCH_MOBILE_BTN_CLASS + ' ' + SEARCH_MOBILE_BTN_CLOSE_CLASS
            }).append($('<span/>', {
                'class': 'fa fa-search fa-2x'
            }));

            $(document.body).find('.wrap').eq(0).append(this.elements.$btn);

            return this;
        }
    };

    function _onClosingEnd () {
        this.elements.$btn.removeClass(SEARCH_MOBILE_BTN_CLOSING_CLASS);
        this.elements.$btn.addClass(SEARCH_MOBILE_BTN_CLOSE_CLASS);

        this.elements.$btn.off('animationend', this.callbacks.onClosingEnd);
    }

    function _bindEvents (btnInstance) {
        btnInstance.elements.$btn.on('click', function () {
            if (typeof btnInstance.options.onClick === 'function') {
                btnInstance.options.onClick();
            }
        });
    }
})();