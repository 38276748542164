import { ConsentsGroup } from '../consents_group';
import { ConsentsGroupMap } from '../consents_group/mapper';
import { IConsentsStore } from './types';
import { TConsentName } from '../../types';
import { USER_CONSENTS_KEY } from '../../constants';

export class ConsentsStore implements IConsentsStore {
    async save(consentsList: ConsentsGroup): Promise<void> {
        try {
            window.localStorage.setItem(USER_CONSENTS_KEY, ConsentsGroupMap.grantedToJSON(consentsList));
        } catch (err) {
            this._logError(err);
        }
    }

    async load(): Promise<TConsentName[] | null> {
        try {
            const storeConsents = window.localStorage.getItem(USER_CONSENTS_KEY);
            if (storeConsents) {
                return JSON.parse(storeConsents);
            }

            return null;
        } catch (err) {
            this._logError(err);

            return null;
        }
    }

    private _logError(err: Error) {
        console.error(err);
    }
}
