import { $emit } from '@core/tools/event_bus';
import { AnalyticProduct } from '../analytics/classes/product';
import { ADDED_TO_WISHLIST } from './add_to_wishlist_events';

export class AddToWishlist {
    constructor() {
        this._bindEvents();
    }

    private _bindEvents() {
        window.addEventListener('click', (ev: Event) => {
            const $el: HTMLElement = <HTMLElement>ev.target;

            const $addToWishListEl = $el.closest('.addtofav');

            if (!$addToWishListEl) return;

            //@ts-ignore
            const isUserLoggedIn = Shop.basket.shopVisitorId;

            if (isUserLoggedIn) {
                $emit(ADDED_TO_WISHLIST, this._getProductInfo());
            }
        });
    }

    private _getProductInfo() {
        const $mainProductContainer = document.getElementById('box_productfull');

        if (!$mainProductContainer) return;

        const $inputWithId = $mainProductContainer.querySelector('input[name="product_id"]') as HTMLInputElement;
        const $nameEl = $mainProductContainer.querySelector('h1.name') as HTMLElement;
        const $priceEl = $mainProductContainer.querySelector('.price em.main-price') as HTMLElement;
        const $manufacturerEl = $mainProductContainer.querySelector('.manufacturer a') as HTMLElement;
        const $quantityEl = $mainProductContainer.querySelector('input[name="quantity"]') as HTMLInputElement;

        return new AnalyticProduct({
            id: $inputWithId?.value,
            name: $nameEl?.innerText.trim(),
            price: $priceEl?.innerText.replace(/([^\d,.])+/g, ''),
            brand: $manufacturerEl?.getAttribute('title') || '',
            category: $mainProductContainer.dataset.category || '',
            quantity: $quantityEl?.value || '1',
            position: '0',
            variant: '',
            listName: '',
            //@ts-ignore
            currency: Shop.values.currency
        });
    }
}
