import { Consent } from '../consent';
import { TConsentName } from '../../types';

export class ConsentsGroup {
    _consents: Consent[];

    constructor(listOfConsentNames: TConsentName[]) {
        this._consents = listOfConsentNames.map((name) => new Consent(name, false));
    }

    get(): Consent[] {
        return this._consents;
    }

    getGranted(): Consent[] {
        return this._consents.filter((consent) => consent.isGranted());
    }

    find(name: TConsentName): Consent | undefined {
        return this._consents.find((consent) => consent.getName() === name);
    }
}
