import { IShippingTimeData, ITooltipApi } from './tooltip_types';

import { TOOLTIP_URLS } from './tooltip_constants';
import { xhr } from '@core/tools/xhr';

export class TooltipApi implements ITooltipApi {
    public async getWarehousesAdditionalShippingTimesForStock(stockId: string): Promise<IShippingTimeData | void> {
        const response = await xhr<IShippingTimeData>({
            method: 'get',
            url: `${TOOLTIP_URLS.shippingTime}/${stockId}`
        });

        const data = await response;

        if (data) return data.data;
        return;
    }
}
