;(function (undefined) {
	Shop.Recaptcha.include({
        options: {
            active: false
        },

        initialize: function (options) {
            this.constructor.setOptions(options);
            var self = this;
            var d = document;
            var s = 'script';
            var id = 'g-recaptcha';
            if (this.options.active === false) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "//www.google.com/recaptcha/api.js?onload=recaptchaRender&render=explicit";
                js.async = true;
                js.defer = true;
                fjs.parentNode.insertBefore(js, fjs);
                this.options.active = true;
            }
        }
    });
})();