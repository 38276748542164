;(function (undefined) {
	Shop.FilterPrice.include({
        options : {
            selectors : {
                inputprice1 : '#filterprice1',
                inputprice2 : '#filterprice2',
                buttonprice : '#filterprice'
            }
        },

        initialize: function(options) {
            this.constructor.setOptions(options);
            var shopClass = this.parent;

            var if1 = $(this.options.selectors.inputprice1);
            var if2 = $(this.options.selectors.inputprice2);
            var ifb = $(this.options.selectors.buttonprice);

            var priceFromTo = Shop.values.PriceFilterFromTo;
            var priceFromTo64 = shopClass.base64.decode(priceFromTo.substr(1));
            var priceFrom = Shop.values.PriceFilterFrom;
            var priceFrom64 = shopClass.base64.decode(priceFrom.substr(1));
            var priceTo = Shop.values.PriceFilterTo;
            var priceTo64 = shopClass.base64.decode(priceTo.substr(1));

            if(if1.length > 0 && if2.length > 0 && ifb.length > 0) {
                ifb.off('click').on('click', function(e) {
                    e.stopPropagation();
                    e.preventDefault();

                    var f1 = if1.val().replace(/\s+/g, '').replace(',', '.');
                    var f2 = if2.val().replace(/\s+/g, '').replace(',', '.');
                    var url;

                    if (f1.length > 0) {
                        if (f2.length > 0) {
                            if (Shop.values.PriceFilterFromTo.charAt(0) === "#") {
                                url = priceFromTo64;
                            } else {
                                url = priceFromTo;
                            }
                        } else {
                            if (Shop.values.PriceFilterFrom.charAt(0) === "#") {
                                url = priceFrom64;
                            } else {
                                url = priceFrom;
                            }
                        }
                    } else {
                        if (f2.length > 0) {
                            if(Shop.values.PriceFilterFrom.charAt(0) === "#") {
                                url = priceTo64;
                            } else {
                                url = priceTo;
                            }
                        } else {
                            return false;
                        }
                    }

                    if (typeof url !== 'string' || 0 == url.length) {
                        return false;
                    }
                    
                    window.location.href = shopClass.substitute(url, {
                        pricefrom: f1,
                        priceto: f2
                    });
                });

                if1.on('keypress', function(e) {
                    if (13 == e.keyCode) {
                        ifb.trigger('click');
                    }
                });

                if2.on('keypress', function(e) {
                    if (13 == e.keyCode) {
                        ifb.trigger('click');
                    }
                });
            }
        }
    });
})();