import {
    formatToPhoneNumberWithoutMask,
    formatToPhoneNumberWithoutPrefix,
    replacePlusWithDoubleZerosInPrefix
} from '@frontstoreRwd/modules/phone_country_select/phone_country_select_utils';
import { phonePatternStrategies, phoneCountryStrategies } from '@frontstoreRwd/modules/phone_country_select/phone_strategies';
import {
    IPhoneCountrySelect,
    TPhoneNumberPatternNames,
    TPhoneCountries
} from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import { PHONE_COUNTRY_SELECT_CREATED_EVENT } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_event_names';
import {
    PHONE_COUNTRY_SELECT_JS_CLASSES,
    PHONE_COUNTRY_SELECT_CSS_CLASSES,
    PHONE_COUNTRY_SELECT_SELECTORS,
    PHONE_NUMBER_PATTERN_STRATEGIES,
    DEFAULT_PHONE_NUMBER_COUNTRY,
    PHONE_NUMBER_COUNTRIES,
    PHONE_NUMBER_DETAILS,
    PHONE_NUMBER_PREFIX
} from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';
import { VALIDATOR_CREATED_EVENT } from '@frontstoreRwd/modules/validation/form_validator/form_validator_event_names';
import { INPUT_MASK_INPUT_EVENT, INPUT_MASK_CHANGE_EVENT } from '@frontstoreRwd/modules/input_mask';
import { IFormValidator } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';
import { FORM_ERRORS_JS_CLASSES } from '@frontstoreRwd/modules/form_error_renderer/form_error_renderer_constants';
import { CSS_HINT_CLASS, CSS_NONE_CLASS } from '@core/constants/css_classes';
import { $emit, $on } from '@core/tools/event_bus';

export class PhoneCountrySelect implements IPhoneCountrySelect {
    $phoneCountrySelect: HTMLSelectElement;
    $phoneInput: HTMLInputElement;
    $hiddenPhoneCountryInput: HTMLInputElement | null = null;

    selectedCountry: TPhoneCountries = DEFAULT_PHONE_NUMBER_COUNTRY;
    activePatternStrategy: TPhoneNumberPatternNames = PHONE_NUMBER_PATTERN_STRATEGIES.nineDigits;

    validator: IFormValidator | null = null;

    selectors = {
        phoneCountrySelectContainer: `.${PHONE_COUNTRY_SELECT_JS_CLASSES.phoneCountrySelectContainer}`,
        inputPhone: PHONE_COUNTRY_SELECT_SELECTORS.inputPhone,
        addressDifferentId: PHONE_COUNTRY_SELECT_SELECTORS.addressDifferentId,
        phoneTableRow: `.${PHONE_COUNTRY_SELECT_JS_CLASSES.phoneTableRow}`
    };

    constructor($phoneCountrySelect: HTMLSelectElement) {
        this.$phoneCountrySelect = $phoneCountrySelect;

        const $parentTableRow = this.$phoneCountrySelect.closest(this.selectors.phoneTableRow) as HTMLTableRowElement;

        this.$phoneInput = $parentTableRow.querySelector(this.selectors.inputPhone) as HTMLInputElement;

        this._updateSelectedPhoneCountry();
        this._setupEventHandlers();

        if (this._isPhoneNumberAlreadyPrefixed()) {
            this._adjustPhoneCountryAndNumberToPrefix();
        }

        $emit(PHONE_COUNTRY_SELECT_CREATED_EVENT, this);
    }

    private _updateSelectedPhoneCountry = (): void => {
        const $selectedOption: HTMLOptionElement = this.$phoneCountrySelect.selectedOptions[0];

        this.selectedCountry = $selectedOption.value as TPhoneCountries;

        this._setPatternStrategy($selectedOption);
        this._setSelectedPhoneFlag($selectedOption);
    };

    private _setPatternStrategy($selectedOption: HTMLOptionElement): void {
        if ($selectedOption.dataset?.patternStrategy) {
            this.activePatternStrategy = $selectedOption.dataset.patternStrategy as TPhoneNumberPatternNames;
        }
    }

    private _setSelectedPhoneFlag($selectedOption: HTMLOptionElement): void {
        const selectedImageSource: string | undefined = $selectedOption.dataset?.backgroundSource;

        this.$phoneCountrySelect.style.background = `18% 50% no-repeat url(${selectedImageSource}), 85% 50% no-repeat url(/skins/default/rwd_shoper/images/ico_arrow_down_small.png)`;
    }

    private _setupEventHandlers(): void {
        this.$phoneCountrySelect.addEventListener('change', this.activePhoneCountryFunctionality);

        $on(VALIDATOR_CREATED_EVENT, this._setFormValidator);
        $on(INPUT_MASK_INPUT_EVENT, this._updateMask);
        $on(INPUT_MASK_CHANGE_EVENT, this._updateMaskAndCursorPosition);
    }

    public activePhoneCountryFunctionality = (): void => {
        this.showPhoneCountrySelect();
        this._updateSelectedPhoneCountry();
        this._updatePhoneInputData();
    };

    public showPhoneCountrySelect = (): void => {
        const phoneCountrySelectContainer: HTMLElement | null = this.$phoneCountrySelect.closest(this.selectors.phoneCountrySelectContainer);

        phoneCountrySelectContainer?.classList.remove(CSS_NONE_CLASS);
    };

    private _updatePhoneInputData = (): void => {
        this._updateMaskData();
        this._updateInputsPrefix();
        this._updateValidatorActivity();
    };

    private _updateMaskData(): void {
        phonePatternStrategies[this.activePatternStrategy].setMaskData(this.$phoneInput);
        phonePatternStrategies[this.activePatternStrategy].updateMask(this.$phoneInput, this.selectedCountry);
    }

    private _updateInputsPrefix(): void {
        const $inputContainer = this.$phoneInput.closest(`.${FORM_ERRORS_JS_CLASSES.inputContainer}`) as HTMLDivElement;

        phoneCountryStrategies[this.selectedCountry].setPrefixData($inputContainer);
    }

    private _updateValidatorActivity = (): void => {
        if (!this.validator) {
            return;
        }

        const phoneName = this.$phoneInput.getAttribute('name') as string;

        phonePatternStrategies[this.activePatternStrategy].setValidatorActivity(this.validator, phoneName);
    };

    private _setFormValidator = (formValidator: unknown): void => {
        const validator = formValidator as IFormValidator;
        const validateForm = validator.$form;
        const $parentForm = this.$phoneCountrySelect.closest('form');
        const phoneName = this.$phoneInput.getAttribute('name') as string;

        if (validateForm === $parentForm) {
            this.validator = validator;

            if (this._shouldPhoneCountrySelectBeVisible()) {
                phonePatternStrategies[this.activePatternStrategy].setValidatorActivity(this.validator, phoneName);
            }
        }
    };

    private _shouldPhoneCountrySelectBeVisible(): boolean {
        const phoneName = this.$phoneInput.getAttribute('name');

        if (!phoneName) {
            return false;
        }

        const isPhoneInputAvailable = this.$phoneInput.closest(`.${CSS_NONE_CLASS}`);

        return isPhoneInputAvailable === null;
    }

    private _updateMask = ({ inputMaskInstance, isValidPattern }: any): void => {
        this._updateMaskAndCursorPosition(inputMaskInstance, isValidPattern);
    };

    private _updateMaskAndCursorPosition = (inputMaskInstance: any, isValidPattern = true): void => {
        if (!this._isPhoneInputEdited(inputMaskInstance)) return;

        const phoneInputValue = this.$phoneInput.value;
        const activeValidPattern = PHONE_NUMBER_DETAILS[this.activePatternStrategy].validPattern;
        const isFullPhoneEntered = phoneInputValue.length === formatToPhoneNumberWithoutMask(activeValidPattern).length;
        const { selectionStart, selectionEnd } = inputMaskInstance.options.$el[0];
        const isCursorInsideValidPattern = selectionEnd <= activeValidPattern.length;

        if (isFullPhoneEntered && isCursorInsideValidPattern) {
            phonePatternStrategies[this.activePatternStrategy].updateMask(this.$phoneInput, this.selectedCountry);

            if (!isValidPattern) return;

            inputMaskInstance.setCursorPosition(selectionStart, selectionEnd);
        }
    };

    private _isPhoneInputEdited = (inputMaskInstance: any): boolean => {
        const $inputWithMask = inputMaskInstance.options.$el[0];

        return $inputWithMask === this.$phoneInput;
    };

    public enablePhoneCountrySelect(): void {
        this.$phoneCountrySelect.removeAttribute('disabled');
        this.$phoneCountrySelect.classList.remove(PHONE_COUNTRY_SELECT_CSS_CLASSES.selectPhoneInactive);
    }

    public disablePhoneCountrySelect(): void {
        this.$phoneCountrySelect.setAttribute('disabled', 'disabled');
        this.$phoneCountrySelect.classList.add(PHONE_COUNTRY_SELECT_CSS_CLASSES.selectPhoneInactive);
    }

    public toggleDefaultPhoneNumberData = (shouldPhoneCountryFunctionalityBeEnable: boolean, isPhoneCountryChangeAllowed = true): void => {
        if (shouldPhoneCountryFunctionalityBeEnable) {
            this.showPhoneCountrySelect();

            if (this._isPhoneNumberAlreadyPrefixed() && isPhoneCountryChangeAllowed) {
                this._adjustPhoneCountryAndNumberToPrefix();
                this._updateSelectedPhoneCountry();
                this._updatePhoneInputData();
            } else {
                this.setDefaultPhoneNumberData();
            }
        } else {
            this.resetPhoneCountryFunctionality();
        }

        this._updateValidatorActivity();
    };

    private _isPhoneNumberAlreadyPrefixed = (): boolean => {
        return this.$phoneInput.value.startsWith('00');
    };

    private _adjustPhoneCountryAndNumberToPrefix = (): void => {
        const [[prefixedCountry, prefix]] = Object.entries(PHONE_NUMBER_PREFIX).filter(([, prefix]): boolean => {
            return this.$phoneInput.value.startsWith(replacePlusWithDoubleZerosInPrefix(prefix));
        });

        this.$phoneCountrySelect.value = prefixedCountry;

        if (!prefix) return;

        this.$phoneInput.value = formatToPhoneNumberWithoutPrefix(this.$phoneInput.value, replacePlusWithDoubleZerosInPrefix(prefix));
    };

    public setDefaultPhoneNumberData(): void {
        this.selectedCountry = DEFAULT_PHONE_NUMBER_COUNTRY;
        this.activePatternStrategy = PHONE_NUMBER_PATTERN_STRATEGIES.nineDigits;

        let $selectedOption = document.createElement('option');

        Array.from(this.$phoneCountrySelect.options).forEach(($option: HTMLOptionElement) => {
            if ($option.value === this.selectedCountry) {
                $selectedOption = $option;
            }
        });

        this._setSelectedPhoneFlag($selectedOption);

        this.$phoneCountrySelect.value = this.selectedCountry;

        this._updateMaskData();
        this._updateInputsPrefix();

        this._updateValidatorActivity();
    }

    public resetPhoneCountryFunctionality = (): void => {
        this.activePatternStrategy = PHONE_NUMBER_PATTERN_STRATEGIES.none;
        this.selectedCountry = PHONE_NUMBER_COUNTRIES.Other;

        const phoneCountrySelectContainer = this.$phoneCountrySelect.closest(this.selectors.phoneCountrySelectContainer);

        phoneCountrySelectContainer?.classList.add(CSS_NONE_CLASS);

        this._updateMaskData();
        this._updateInputsPrefix();
    };

    public addHiddenPhoneCountryInput(): void {
        let $hiddenInput = document.querySelector(`input[name=${this.$phoneCountrySelect.name}]`);

        if ($hiddenInput) {
            return;
        }

        $hiddenInput = this._createHiddenPhoneCountryInput();

        const $parentForm = this.$phoneCountrySelect.closest('form');

        $parentForm?.append($hiddenInput);
    }

    private _createHiddenPhoneCountryInput(): HTMLInputElement {
        const $hiddenInput = document.createElement('input');

        $hiddenInput.type = 'hidden';
        $hiddenInput.value = DEFAULT_PHONE_NUMBER_COUNTRY;
        $hiddenInput.name = this.$phoneCountrySelect.name;

        this.$hiddenPhoneCountryInput = $hiddenInput;

        return $hiddenInput;
    }

    public removeHiddenPhoneCountryInput(): void {
        this.$hiddenPhoneCountryInput?.remove();
    }

    public setPhoneCountryHintVisibility(): void {
        const $phoneCountrySelectContainer: HTMLTableDataCellElement | null = this.$phoneCountrySelect.closest(
            this.selectors.phoneCountrySelectContainer
        );

        if (this.$phoneCountrySelect.classList.contains(PHONE_COUNTRY_SELECT_CSS_CLASSES.selectPhoneInactive)) {
            $phoneCountrySelectContainer?.classList.add(CSS_HINT_CLASS);
        } else {
            $phoneCountrySelectContainer?.classList.remove(CSS_HINT_CLASS);
        }
    }
}
