import { TErrorName } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';

export const VALIDATED_PHONE_NUMBER_LENGTH = 9;

export const ERROR_NAMES_LIST: Record<TErrorName, TErrorName> = {
    tooShortNumberError: 'tooShortNumberError',
    numberStartsWithZeroError: 'numberStartsWithZeroError'
};

export const FORM_JS_SELECTORS: Record<string, string> = {
    noValidate: '.js__no-validate'
};

export const ACTIONS_TYPES = {
    global: 'global',
    dom: 'dom'
} as const;

export const EMPTY_INPUT_VALUE = '';
