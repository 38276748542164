const VERSION = 'v9.0';

export class FacebookSDK {
    static create(): void {
        const id = 'facebook-jssdk';
        if (document.getElementById(id)) {
            return;
        }

        FacebookSDK._createRootDiv();
        FacebookSDK._injectScript(id);
    }

    private static _createRootDiv() {
        const $fbRoot = document.createElement('div');
        $fbRoot.id = 'fb-root';
        document.body.append($fbRoot);
    }

    private static _injectScript(id: string) {
        const firstScriptOnPage = document.getElementsByTagName('script')[0];
        const facebookSDKScript = document.createElement('script');

        facebookSDKScript.id = id;
        // @ts-ignore
        facebookSDKScript.src = `https://connect.facebook.net/${Shop.lang.name || 'en_US'}/sdk.js#xfbml=1&version=${VERSION}`;
        facebookSDKScript.async = true;
        facebookSDKScript.defer = true;
        facebookSDKScript.crossOrigin = 'anonymous';
        facebookSDKScript.nonce = 'YJCrO05r';

        if (firstScriptOnPage.parentNode) {
            firstScriptOnPage.parentNode.insertBefore(facebookSDKScript, firstScriptOnPage);
        }
    }
}
