import { IValidator, TErrorName } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';
import { VALIDATED_PHONE_NUMBER_LENGTH, ERROR_NAMES_LIST } from '@frontstoreRwd/modules/validation/form_validator/form_validator_constants';
import { formatToPhoneNumberWithoutMask } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_utils';
import { startsWithZero, hasStringLengthEqual } from '@frontstoreRwd/modules/validation/form_validator/validators/validators_utils';

export class isProperPhoneNumberLength implements IValidator {
    errorName: TErrorName = ERROR_NAMES_LIST.tooShortNumberError;

    public check(value: string): boolean {
        const phoneNumber = formatToPhoneNumberWithoutMask(value);

        return hasStringLengthEqual(phoneNumber, VALIDATED_PHONE_NUMBER_LENGTH);
    }
}

export class isPhoneNumberFirstDigitValid implements IValidator {
    errorName: TErrorName = ERROR_NAMES_LIST.numberStartsWithZeroError;

    public check(value: string): boolean {
        const phoneNumber = formatToPhoneNumberWithoutMask(value);

        return !startsWithZero(phoneNumber);
    }
}
