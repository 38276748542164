import { AfterPhoneCountrySelectCreated } from './events/after_phone_country_select_created';
import { INPOST_PHONE_NUMBER_CSS_CLASSES, INPOST_ENGINE } from './inpost_phone_number_constants';
import { isPolishNumberSelected } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_utils';
import { BASKET_SELECTORS, BASKET_JS_CLASSES } from '@frontstoreRwd/modules/basket_manage/basket_constants';
import { CSS_HINT_CLASS } from '@core/constants/css_classes';

export class InpostPhoneNumberHandler {
    #selectors = {
        delivery: BASKET_SELECTORS.delivery,
        basketExtraStepShippings: `.${BASKET_JS_CLASSES.basketExtraStepShippings}`
    };

    constructor() {
        new AfterPhoneCountrySelectCreated();

        this._disableInpostShippingsOnBasketExtraStep();
    }

    private _disableInpostShippingsOnBasketExtraStep(): void {
        const $basketExtraStepShippings = document.querySelector(this.#selectors.basketExtraStepShippings);

        if ($basketExtraStepShippings && !isPolishNumberSelected()) {
            const $deliveries = document.querySelectorAll<HTMLElement>(this.#selectors.delivery);

            Array.from($deliveries)
                .filter(this.isInpostShipping)
                .forEach(($delivery: HTMLElement): void => {
                    $delivery.classList.add(CSS_HINT_CLASS);
                    $delivery.classList.add(INPOST_PHONE_NUMBER_CSS_CLASSES.deliveryInactive);
                    $delivery.querySelector('input')?.setAttribute('disabled', '');
                });
        }
    }

    private isInpostShipping($delivery: HTMLElement): boolean {
        return $delivery.dataset.engine === INPOST_ENGINE;
    }
}
