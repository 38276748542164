import fastdom from '../../libraries/fastdom.min';

(function (undefined) {
    Shop.LazyPicture.include({
        options: {
            offset: 500,
            imgs: [],
            imgCount: 0
        },

        events: {
            lazyLoading: null
        },

        isScrollBounded: false,

        initialize: function (options) {
            var self;

            self = this;
            this.constructor.setOptions(options);
            this.options.imgs = [].slice.call(document.querySelectorAll('img[data-src]'));
            this.options.imgCount = this.options.imgs.length;

            if (!this.parent.isMobile()) {
                setTimeout(function () {
                    self.lazyLoading();
                }, 100);

                this.events.lazyLoading = (function () {
                    self.lazyLoading();
                });

                $(window).on('scroll', this.events.lazyLoading);

                this.isScrollBounded = true;
            } else {
                self.lazyLoadingMobile();
            }
        },

        lazyLoadingMobile: function () {
            this.options.imgs.forEach(function (img) {
                if (img && $(img).parent().hasClass('lazy-load')) {
                    if (img.src !== img.getAttribute('data-src')) {
                        img.src = img.getAttribute('data-src');
                    }

                    $(img).parent().removeClass('lazy-load');
                }
            });
        },

        lazyLoading: function () {
            var self;

            self = this;
            self.parent.asyncEach(self.options.imgs, function (img) {
                if (img && $(img).parent().hasClass('lazy-load') && self.isReadyForLoad($(img).offset().top, $(window).scrollTop(), window.innerHeight)) {
                    fastdom.mutate(function () {
                        var $slideBox;

                        if (img.src !== img.getAttribute('data-src')) {
                            img.src = img.getAttribute('data-src');
                        }
                        $(img).parent().removeClass('lazy-load');

                        img.onload = (function () {
                            $(img).trigger('change');
                            $slideBox = $(img).parents('.product');
                            if ($slideBox.length > 0 && $slideBox.is(':last-child')) {
                                self.parent.fireEvent('img:change:slider:' + $slideBox.parents('.slider').attr('id'));
                            }
                        });
                    });
                }
            }, function () {
                if ($('.lazy-load').length <= 0) {
                    $(window).off('scroll', self.events.lazyLoading);
                    self.isScrollBounded = false;
                }
                self.parent.fireEvent('img:change');
            });
        },

        /**
         * For handling markup with images loaded via xhr
         */
        updateImagesList: function () {
            this.options.imgs = [].slice.call(document.querySelectorAll('img[data-src]'));
            this.options.imgCount = this.options.imgs.length;

            if (!this.parent.isMobile()) {
                if (!this.isScrollBounded) {
                    $(window).on('scroll', this.events.lazyLoading);
                }
            } else {
                this.lazyLoadingMobile();
            }
        },

        isReadyForLoad: function (imgOffsetTop, scrollTop, windowHeight) {
            var boundaryTop;
            var boundaryBottom;

            boundaryTop = scrollTop - this.options.offset;
            boundaryBottom = scrollTop + windowHeight + this.options.offset;

            if (imgOffsetTop > boundaryTop && imgOffsetTop < boundaryBottom) {
                return true;
            }

            return false;
        }
    });
})();