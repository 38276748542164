;(function (undefined) {
    Shop.CodeVerification = function ($root, options) {
        this.$root = $root;
        this.options = $.extend(true, {}, {
            verificationAction: function () {
                location.href = location.origin;
            },
            accessCode: {
                action: _getAccessCode,
                beforeSend: function () {},
                onSuccess: function () {},
                onError: function () {}
            }
        }, options);

        if ($root) {
            this.initialize();
        }
    };

    Shop.CodeVerification.prototype = {
        constructor: Shop.CodeVerification,
        parent: Shop.prototype,

        initialize: function () {
            _bindEvents(this);
        }
    };

    function _bindEvents (verificationInstance) {
        var $getNewCode = verificationInstance.$root.find('[verification-get-new-code]');
        var $submitAction = verificationInstance.$root.find('[verification-submit-action]');

        if ($getNewCode.length) {
            $getNewCode.on('click', function (ev) {
                verificationInstance.options.accessCode.action(verificationInstance, ev, $getNewCode);
            });
        }

        if ($submitAction.length) {
            $submitAction.on('click', function () {
                verificationInstance.options.verificationAction(verificationInstance);
            });
        }
    }

    function _getAccessCode (verificationInstance, ev, $target) {
        var elText = $target.text();

        ev.preventDefault();

        if (!$target.request) {
            $.ajax({
                url: $target.attr('data-action'),
                method: 'post',
                beforeSend: function () {
                    verificationInstance.options.accessCode.beforeSend();

                    $target.request = true;
                    $target.text(Shop.lang.verificationService.request_sent);
                },
                success: function (ev) {
                    verificationInstance.options.accessCode.onSuccess(ev, $target.attr('data-customer-email'));

                    $target.request = false;
                    $target.text(elText);
                },
                error: function (err) {
                    verificationInstance.options.accessCode.onError(err);

                    $target.request = false;
                    $target.text(elText);
                }
            });
        }
    }
})();