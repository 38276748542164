import { PURCHASE } from './microsoft_ads_event_names';
import { PURCHASED } from '@frontstoreRwd/events/events_names';
import { AnalyticProduct } from '../../classes/product';
import { TMicrosoftAdsEvent } from '@frontstoreRwd/modules/analytics/services/microsoft_ads/microsoft_ads_types';
import { MicrosoftAdsService } from '@frontstoreRwd/modules/analytics/services/microsoft_ads/microsoft_ads';

const eventsHandlersMapper: TMicrosoftAdsEvent = {
    [PURCHASED]: (service: MicrosoftAdsService, params: unknown): void => {
        if (typeof params === 'object') {
            const purchaseParams = params as {
                orderSummary: {
                    id: string;
                    revenue: string;
                    shipping: string;
                    tax: string;
                };
                products: AnalyticProduct[];
                currency: string;
            };

            service.send({
                eventName: PURCHASE,
                eventParameters: {
                    revenue_value: purchaseParams.orderSummary.revenue,
                    currency: purchaseParams.currency
                }
            });
        }
    }
};

export default eventsHandlersMapper;
