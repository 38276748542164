;(function (undefined) {
	Shop.RwdMenu.include({
        options: {
            buttonSelector: '.small-menu .fa.fa-align-justify',
            iconDown: 'fa afa-chevron-down',
            iconUp: 'fa afa-chevron-up'
        },

        objects: {
            categories: null,
            menu: null
        },

        events: {
            create: null,
            toggle: null
        },

        initialize: function(options) {
            this.constructor.setOptions(options);
            var shopClass = this.parent;

            this.events.create = (function (ev) {
                ev.preventDefault();
                ev.stopPropagation();
                if((shopClass.rwd.small || shopClass.rwd.mid) && !this.objects.categories) {
                    this.createMenu(shopClass);
                    $(this.options.buttonSelector).off('click', this.events.create);
                }
            }.bind(this));

            $(this.options.buttonSelector).on('click', this.events.create);
        },

        createMenu: function (shopClass) {
            var that = this;

            if (shopClass.rwd.small || shopClass.rwd.mid) {
                $.ajax({
                    url: shopClass.url('console/tree/gettree/client/1'),
                    method: 'get'
                }).error(function () {

                }).success(function (categories, state, xhrObject) {
                    if (state === 'success') {
                        that.objects.categories = categories;
                        that.buildMainCategory();
                    }
                });
            }
        },

        buildNode: function (tree, submenu) {
            var that;
            var category;
            var index;
            var li;
            var a;

            that = this;
            for (category in tree) {
                if (Object.prototype.hasOwnProperty.call(tree, category)) {
                    li = $('<li />', {
                        'class': tree[category].children.length > 0 ? 'has-children ' + this.options.iconDown : '',
                        'data-category-id': tree[category].id,
                        click: function(e) {
                            if($(this).hasClass('has-children')) {
                                e.stopPropagation();

                                if ($(this).hasClass('active')) {
                                    $(this).removeClass('active').removeClass(that.options.iconUp).addClass(that.options.iconDown).children('ul').hide();
                                } else {
                                    index = $(this).index();
                                    that.buildSubCategory($(this), tree[index].children);
                                    $(this).removeClass(that.options.iconDown).addClass(that.options.iconUp + ' active');
                                    $(this).children('ul').show();
                                }
                            }
                        }
                    }).appendTo(submenu);

                    a = $('<a />', {
                        'text': tree[category].name,
                        'href': tree[category].url,
                        click: function(e) {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            $(this).next().hide();
                            window.location.href = $(this).attr('href');
                        }
                    }).appendTo(li);
                }
            }
        },

        buildMainCategory: function() {
            if(!this.objects.menu) {
                var that = this,
                    additionalLinks = $('.menu-list a'),
                    tree = this.objects.categories.tree,
                    menu = this.objects.menu = $('<ul />', {
                        'id': 'rwd-menu',
                        'class': 'small active'
                    }).appendTo($('.menu.row nav'));

                this.buildNode(tree, menu);

                if (additionalLinks.length > 0) {
                    this.buildAdditionalLinks(menu, additionalLinks);
                }

                this.events.toggle = (function (ev) {
                    ev.preventDefault();
                    ev.stopPropagation();

                    this.objects.menu.toggleClass('active none small');
                }.bind(this));

                $(this.options.buttonSelector).on('click', this.events.toggle);
            }
        },

        buildSubCategory: function(li, tree) {
            var submenuId = parseInt(li.attr('data-category-id'), 10);

            if(tree.length > 0 && !this.objects.menu[submenuId]) {
                var that = this;
                var submenu = this.objects.menu[submenuId] = $('<ul />').appendTo(li);
        
                this.buildNode(tree, submenu);
            }       
        },

        buildAdditionalLinks: function (menu, additionalLinks) {
            additionalLinks.each(function (index) {
                var li;
                var $el;

                $el = $(this);
                
                if ($el.attr('href') !== '/' && $el.parents('li').attr('id') === undefined && index) {
                    li = $('<li />').appendTo(menu)
                    $('<a />', {
                        'text': $el.text(),
                        'href': $el.attr('href')
                    }).appendTo(li);
                }
            });
        }
    });
})();