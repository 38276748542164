;(function (undefined) {
	Shop.Blankshield.include({
        options: {},

        initialize: function (options) {
            var self;

            this.constructor.setOptions(options);
            
            self = this;
            $('[target="_blank"]:not([rel="noopener"]):not([rel="noreferrer"])').on('click', function (ev) {
                var $self;
                var href;
                var targetName;
                var usedModifier;

                $self = $(this);
                href = $self.attr('href');
                if (!href) {
                    return;
                }

                usedModifier = (ev.ctrlKey || ev.shiftKey || ev.metaKey);
                targetName = $self.attr('target');

                if (!usedModifier && (!targetName || self.safeTarget(targetName))) {
                    return;
                }

                self.open(href);
                ev.preventDefault()
            });
        },

        open: function (strUrl, strWindowName, strWindowFeatures) {
            if (this.safeTarget(strWindowName)) {
                return open.apply(window, arguments);
            } else {
                return this.iframeOpen(strUrl, strWindowName, strWindowFeatures)
            }
        },
        
        iframeOpen: function (url, strWindowName, strWindowFeatures) {
            var iframe;
            var iframeDoc; 
            var script; 
            var openArgs; 
            var newWin;

            iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

            openArgs = '"' + url + '"';
            if (strWindowName) {
              openArgs += ', "' + strWindowName + '"';
            }
            if (strWindowFeatures) {
              openArgs += ', "' + strWindowFeatures + '"';
            }

            script = iframeDoc.createElement('script');
            script.type = 'text/javascript';
            script.text = 'window.parent = null; window.top = null;' +
              'window.frameElement = null; var child = window.open(' + openArgs + ');' +
              'child.opener = null';
            iframeDoc.body.appendChild(script);
            newWin = iframe.contentWindow.child;

            document.body.removeChild(iframe);
            return newWin;
        },

        safeTarget: function (target) {
            return target === '_top' || target === '_self' || target === '_parent';
        }
    });
})();