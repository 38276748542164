import { Base64Utils, JsonUtils } from '@dreamcommerce/utilities';
import { DEFAULT_SHOP_VALUES, QUANTITY_WRAP_CLASS, STOCK_ID_INPUT, UNIT_CLASS } from './tooltip_constants';
import {
    IProductStocksCache,
    IShippingTimeData,
    IShippingTimeTooltipProps,
    ITooltip,
    ITooltipDataCache,
    ITooltipShopPhrases,
    TShippingTimeDataItem
} from './tooltip_types';

import { TShop } from '@frontstoreRwd/global';
import { TooltipApi } from './tooltip_api';
import { simpleSanitizeHTML } from '@core/dom_utilities/sanitize';

export class ShippingTimeTooltip {
    $tooltipInput: HTMLInputElement;
    tooltip: ITooltip;
    tooltipApi: TooltipApi;
    tooltipDataCache: ITooltipDataCache = {};

    constructor({ $tooltipInputWrapper, tooltipApi, tooltip }: IShippingTimeTooltipProps) {
        this.tooltip = tooltip;
        this.tooltipApi = tooltipApi;

        this.tooltip.renderTooltip();

        const cachedCurrentStock = this.getTooltipShippingTimesCache();
        if (cachedCurrentStock) this.tooltipDataCache = cachedCurrentStock;

        this.$tooltipInput = $tooltipInputWrapper.querySelector('input') as HTMLInputElement;
        this.$tooltipInput.addEventListener('change', this.handleTooltipInputChange);
    }

    private getShopValues(): { ProductStocksCache: string } {
        const shop = (window as TShop).Shop;
        const shopValues = shop?.values || DEFAULT_SHOP_VALUES;
        return shopValues;
    }

    private getTooltipShippingTimesCache(): void | ITooltipDataCache {
        const { ProductStocksCache } = this.getShopValues();

        const productStocksCache: IProductStocksCache | void = JsonUtils.fromJson(Base64Utils.decode(ProductStocksCache));
        const cachedCurrentStock: ITooltipDataCache | void =
            productStocksCache &&
            Object.values(productStocksCache).reduce((acc, curr) => {
                const { sid, shippingTimes } = curr;
                return {
                    ...acc,
                    [sid]: { data: shippingTimes }
                };
            }, {} as ITooltipDataCache);

        return cachedCurrentStock;
    }

    private setTooltipDataCache(tooltipData: IShippingTimeData, stockId: string) {
        this.tooltipDataCache = {
            ...this.tooltipDataCache,
            [stockId]: tooltipData
        };
    }

    private async getTooltipData(): Promise<IShippingTimeData | void> {
        const { value: currentStockId } = document.querySelector(`.${QUANTITY_WRAP_CLASS}`)?.querySelector(STOCK_ID_INPUT) as HTMLInputElement;
        const tooltipData = this.tooltipDataCache[currentStockId];

        if (tooltipData) return tooltipData;

        const tooltipDataXhr = await this.tooltipApi.getWarehousesAdditionalShippingTimesForStock(currentStockId);

        if (!tooltipDataXhr) return;

        this.setTooltipDataCache(tooltipDataXhr, currentStockId);

        return tooltipDataXhr;
    }

    public async updateTooltip(value: string): Promise<void> {
        this.tooltip.hideTooltip();

        const tooltipData = await this.getTooltipData();

        if (!tooltipData) return;

        tooltipData.data.forEach((element: TShippingTimeDataItem, index) => {
            const { max_quantity: lowerQuantity, shipping_time: lowerShippingTime } = element;
            const nextElement = tooltipData.data[index + 1];
            const currentShippingTime = nextElement?.shipping_time;
            const higherQuantity = nextElement?.max_quantity;

            if (!lowerShippingTime || !lowerQuantity) {
                this.tooltip.hideTooltip();
                return;
            }

            if (Number(value) > lowerQuantity) {
                const $quantityWrap = document.querySelector(`.${QUANTITY_WRAP_CLASS}`) as HTMLDivElement;
                const { innerHTML: unit } = $quantityWrap.querySelector(`.${UNIT_CLASS}`) as HTMLSpanElement;

                const shop = (window as TShop).Shop ?? DEFAULT_SHOP_VALUES;
                const { current_shipping_time, up_to, we_can_send_within }: ITooltipShopPhrases = shop.lang.tooltip || DEFAULT_SHOP_VALUES;

                const currentShippingText = simpleSanitizeHTML(currentShippingTime ? `${current_shipping_time} ${currentShippingTime}.` : '');
                const lowerShippingText = simpleSanitizeHTML(`${up_to} ${lowerQuantity} ${unit} ${we_can_send_within} ${lowerShippingTime}.`);

                this.tooltip.updateTooltip(lowerShippingText + '<br />' + currentShippingText);
                this.tooltip.showTooltip();
            }

            if (Number(value) > lowerQuantity && !higherQuantity) {
                this.tooltip.hideTooltip();
            }
        });
    }

    public handleTooltipInputChange = (): void => {
        const { value } = this.$tooltipInput;
        this.updateTooltip(value);
    };
}
