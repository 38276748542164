import { isDifferentAddressChecked, isPolandSelected } from '@frontstoreRwd/modules/basket_manage/basket_utils';
import { BASKET_SELECTORS } from '@frontstoreRwd/modules/basket_manage/basket_constants';
import { IPhoneCountrySelect } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import { PHONE_COUNTRY_SELECT_JS_CLASSES } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';

export const setDefaultInpostPhoneNumber = (phoneCountryInstance: IPhoneCountrySelect): void => {
    phoneCountryInstance.setDefaultPhoneNumberData();
    phoneCountryInstance.disablePhoneCountrySelect();
    phoneCountryInstance.addHiddenPhoneCountryInput();
    phoneCountryInstance.setPhoneCountryHintVisibility();
};

export const isInpostShippingSelected = (phoneCountryInstance: IPhoneCountrySelect): boolean => {
    const $phoneCountrySelectContainer = phoneCountryInstance.$phoneCountrySelect.closest(
        `.${PHONE_COUNTRY_SELECT_JS_CLASSES.phoneCountrySelectContainer}`
    ) as HTMLTableDataCellElement;
    const isInpostShippingSelected = $phoneCountrySelectContainer?.dataset?.inpostShipping === '1';

    return isInpostShippingSelected;
};

export const isPhoneCountrySelectNotNeeded = (phoneName: string): boolean => {
    return isDifferentAddressChecked() && !isPolandSelected(`${BASKET_SELECTORS.countryForPhone}${phoneName}`);
};
