import { Base64Utils } from '@dreamcommerce/utilities';

type TFeaturesList = Array<string>;
/**
 * Feature module allows to detect enabled features
 * @module application/modules/admin/source/source_mitra/js/Features
 */
const Features = {
    /**
     * @private
     */
    _list: <TFeaturesList | null>null,

    /**
     * list of all features
     * @readonly
     * @type {(FeaturesListInterface|null)}
     */
    get list(): TFeaturesList | null {
        if (this._list !== null) {
            return this._list;
        }

        let featuresString: string | undefined;

        if (document) {
            if (document.body) {
                featuresString = document.body.dataset.features;
            }

            if (document.head && !featuresString) {
                featuresString = document.head.dataset.features;
            }
        }

        if (featuresString) {
            try {
                return JSON.parse(Base64Utils.decode(featuresString));
            } catch (err) {
                return null;
            }
        }

        return null;
    },

    /**
     * checks if features exists and is enanbled
     * @param {string} featureName - name of the feature
     * @return {boolean}
     */
    isEnabled(featureName: string): boolean {
        if (this.list !== null && this.list.indexOf(featureName) >= 0) {
            return true;
        }

        return false;
    }
};

Object.freeze(Features);
export default Features;
