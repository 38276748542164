;(function (undefined) {
    /**
     * Create google maps and rednder provided points (optional: with list of points)
     * @param {jQuery DOM object} $map
     * @param {jQuery DOM object} $search
     * @param {jQuery DOM object} $searchContainer
     * @param {jQuery DOM object} $mapPointsList
     * @param {object} initPosition - init position for map center, used if geolocation isn't avaiable
     *      @property {number} lat
     *      @property {number} lng
     * @param {number} initZoomLevel
     * @param {number} zoomLevelOnFocus
     * @param {number} searchZoomLevel
     * @param {boolean} markerClusterer - use markerClusterer
     * @param {boolean} infoWindow - use infoWindow present
     * @param {object} mapSettings
     * @param {object} autocomplete - autocomplete controls settings
     *      @property {object} settings
     *      @property {string} position
     * @param {object} callbacks - callbacks functions trigered after specific event ocurred
     *      @property {function} updateView
     *      @property {function} mapReady
     *      @property {function} mapZoomChange
     *      @property {function} mapDragEnd
     *      @property {function} autocomplete
     *      @property {function} search
     *      @property {function} mapError
     *      @property {function} mapResize
     */
    Shop.MapPoints.include({
        options: {
            $map: null,
            $search: null,
            $searchContainer: null,
            $mapPointsList: null,

            initPosition: {
                lat: 52.15696,
                lng: 21.07715
            },

            initZoomLevel: 14,
            zoomLevelOnFocus: 17,
            searchZoomLevel: 15,

            markerClusterer: true,
            infoWindow: true,
            mapSettings: null,

            autocomplete: {
                settings: null,
                position: 'TOP_CENTER'
            },

            callbacks: {
                updateView: null,
                mapReady: null,
                mapZoomChange: null,
                mapDragEnd: null,
                autocomplete: null,
                search: null,
                mapError: null,
                mapResize: null
            }
        },

        /**
         * google map object
         */
        gmap: null,

        /**
         * google geocoder
         */
        geocoder: null,

        /**
         * google autocomplete
         */
        autocomplete: null,

        /**
         * google marker cluser
         */
        markerClusterer: null,

        /**
         * associative array, where key is marker id and value is marker object
         */
        markersObject: {},

        /**
         * instance of infoWindow, if user want to show it after click on marker
         */
        infoWindow: null,

        initialize: function (options) {
            this.constructor.setOptions(options);
            this.initObjects();
        },

        /**
         * init google maps objects like geocoder, autocomplete, if google map was created properly. Binds events
         */
        initObjects: function () {
            var self = this;

            new Promise(function (resolve, reject) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (pos) {
                        resolve({
                            lat: pos.coords.latitude,
                            lng: pos.coords.longitude
                        });
                    }, function () {
                        resolve(self.options.initPosition);
                    }, {
                        timeout: 5000
                    });
                } else {
                    resolve(self.options.initPosition);
                }

            }).then(function (position) {
                try {
                    if (self.renderMap(position)) {
                        self.geocoder = new google.maps.Geocoder();

                        if (self.options.$searchContainer.length > 0) {
                            self.autocomplete = new google.maps.places.Autocomplete(self.options.$search.get(0));
                            self.autocomplete.setComponentRestrictions(self.options.autocomplete.settings);
                            self.gmap.controls[google.maps.ControlPosition[self.options.autocomplete.position]].push(self.options.$searchContainer.get(0));
                        }

                        self.bindEvents();
                        $(window).trigger('resize');
                    }

                } catch (err) {
                    console.error(err);

                    if (typeof self.options.callbacks.mapError === 'function') {
                        self.options.callbacks.mapError(self, err);
                    }
                }
            }).catch(function (err) {
                console.error(err);
            });
        },

        /**
         *  render google map instance
         *  @return {object} google map
         */
        renderMap: function (position) {
            var mapSettings;

            mapSettings = {
                center:  position,
                zoom: this.options.initZoomLevel,
                gestureHandling: 'greedy',
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                styles: [{
                    featureType: 'poi',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }]
            };

            mapSettings = this.options.mapSettings || mapSettings;

            return this.gmap = new google.maps.Map(this.options.$map.get(0), mapSettings);
        },

        /**
         *  note: user need to provide unique id for each point
         *  renders marker on map
         *  @param {array} pickUpPoints - list of point objects, point object is used as a parameter for click callback. Additional data may be stored.
         *      [
         *          {
         *              location: {
         *                  latitude: {number},
         *                  longitude: {number}
         *              },
         *              infoWindowContent: {string},
         *              data: {data},
         *              id
         *              ...
         *          },
         *          ...
         *      ]
         * @param {function} pointClickCallback - callback on marker click
         * @return {undefined}
         */
        renderPoints: function (pickUpPoints, pointClickCallback) {
            var self = this;
            var newMarkers = [];
            var marker;

            pickUpPoints.forEach(function (point) {
                if (!point.id) {
                    throw new Error('Point object must contain unique id.');
                }

                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(point.location.latitude, point.location.longitude),
                    map: self.gmap,
                    icon: point.icon || null,
                    data: point.data || null,
                    infoWindowContent: point.infoWindowContent || '',
                    id: point.id
                });

                if (self.options.infoWindow) {
                    if (!self.infoWindow) {
                        self.infoWindow = new google.maps.InfoWindow({
                            content: ''
                        });

                        if (typeof self.options.callbacks.infoWindowClose === 'function') {
                            self.infoWindow.addListener('closeclick', function () {
                                self.options.callbacks.infoWindowClose(self);
                            });
                        }
                    }
                }

                marker.addListener('click', function () {
                    var mapWidth;
                    var infoWindowListener;

                    if (typeof pointClickCallback === 'function') {
                        pointClickCallback(self, point);
                    }

                    if (self.options.infoWindow) {
                        mapWidth = self.options.$map.outerWidth();

                        if (typeof self.options.callbacks.infoWindowContentLoaded === 'function') {
                            infoWindowListener = self.infoWindow.addListener('domready', function () {
                                self.options.callbacks.infoWindowContentLoaded(self, point);
                                infoWindowListener.remove();
                            });
                        }

                        self.infoWindow.setContent(this.infoWindowContent);
                        self.infoWindow.setPosition(this.position);
                        /**
                         * if map width is greater than 500px max infoWindow width is 50% of map width, else is equal 70% of map width
                         */
                        self.infoWindow.setOptions({ maxWidth: mapWidth > 500 ? mapWidth * 0.5 : mapWidth * 0.7 });
                        self.infoWindow.open(self.gmap);

                        /**
                         * if browser width is less or equal 800 move marker down when focus
                         */
                        if ($(document.body).outerWidth() <= 800) {
                            self.gmap.setCenter(new google.maps.LatLng(this.position.lat() + 0.0015,this.position.lng()));
                        } else {
                            self.gmap.setCenter(this.position);
                        }

                        if (self.gmap.getZoom() < self.options.zoomLevelOnFocus) {
                            self.gmap.setZoom(self.options.zoomLevelOnFocus);
                        }
                    }
                });

                newMarkers.push(marker);
                self.markersObject[point.id] = marker;
            });

            if (typeof MarkerClusterer == 'function') {
                if (this.options.markerClusterer === true) {
                    if (!this.markerClusterer) {
                        this.markerClusterer = new MarkerClusterer(this.gmap, newMarkers, {
                            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' // TODO
                        });
                    } else {
                        this.markerClusterer.addMarkers(newMarkers);
                    }
                }
            }
        },

        /**
         *  note: point need to have unique id
         *  renders list with point informations
         *  @param {array} pickUpPoints - list of point markups to put on the list, point object is used as a parameter for click callback. Additional data may be stored.
         *      [
         *          {
         *              $el: {jQuery DOM object},
         *              data: {data}
         *              ...
         *          },
         *          ...
         *      ]
         * @param {function} pointListClickCallback - callback on item list click
         * @return {undefined}
         */
        renderPointsList: function (pickUpPoints, pointListClickCallback, showOnClick) {
            var self = this;
            var $li;
            var $pointList = $(document.createDocumentFragment());
            var $mapPointsList = this.options.$mapPointsList.empty();

            pickUpPoints.forEach(function (point) {
                if (!point.id) {
                    throw new Error('Point object must contain unique id.');
                }

                point.data.id = point.id;
                $li = $('<li />');
                $li.get(0).data = point.data || {};
                point.$pointDOM.appendTo($li.appendTo($pointList));
            });

            $mapPointsList.append($pointList);
            $mapPointsList.find('> li').on('click', function (evt) {
                if (showOnClick) {
                    google.maps.event.trigger(self.markersObject[this.data.id], 'click');
                } else {
                    if (typeof self.options.callbacks.updateView === 'function') {

                        self.options.callbacks.updateView(self, {
                            lat: this.data.latitude,
                            lng: this.data.longitude
                        });
                    }
                }

                pointListClickCallback(self, evt);
            });
        },

        /**
         * bind events
         */
        bindEvents: function () {
            var self = this;
            var cbMapReady = this.options.callbacks.mapReady;
            var cbMapZoomChange = this.options.callbacks.mapZoomChange;
            var cbMapDragEnd = this.options.callbacks.mapDragEnd;
            var cbAutocomplete = this.options.callbacks.autocomplete;
            var cbSearch = this.options.callbacks.search;
            var cbMapResize = this.options.callbacks.mapResize;

            var readyEvent = this.gmap.addListener('tilesloaded', function () {
                if (typeof cbMapReady === 'function') {
                    cbMapReady(self);
                }

                if (Shop.googleMapsApi.options.$loaderContainer && Shop.googleMapsApi.isLoaderOpen) {
                    Shop.googleMapsApi.removeLoader();
                }

                google.maps.event.removeListener(readyEvent);
                self.parent.fireEvent('MapPoints.map:ready');
            });

            this.gmap.addListener('zoom_changed', function () {
                if (typeof cbMapZoomChange === 'function') {
                    cbMapZoomChange(self);
                }
                self.parent.fireEvent('MapPoints.map:zoomChange');
            });

            this.gmap.addListener('dragend', function () {
                if (typeof cbMapDragEnd === 'function') {
                    cbMapDragEnd(self);
                }
                self.parent.fireEvent('MapPoints.map:dragEnd');
            });

            this.autocomplete.addListener('place_changed', function () {
                if (typeof cbAutocomplete === 'function') {
                    cbAutocomplete(self, this);
                }
                self.parent.fireEvent('MapPoints.autocomplete:placeChanged');
            });

            this.options.$search.on('keypress', function (evt) {
                var value;
                if (evt.key === 'Enter') {

                    evt.preventDefault();
                    evt.stopPropagation();

                    value = $(this).val();

                    if (!!value.length) {
                        self.geocoder.geocode({
                            'address': value,
                            'region': 'pl'
                        }, function (results, status) {
                            if (status == 'OK') {
                                if (typeof cbSearch === 'function') {
                                    cbSearch(self, results);
                                }

                                self.parent.fireEvent('MapPoints.search');
                            } else {
                                self.parent.fireEvent('message.notification');
                            }
                        });
                    }
                }
            });

            this.options.$search.on('click', function () {
                $(this).val('');
            });

            $(window).on('resize', this.parent.debounce(function (evt) {
                if (typeof cbMapResize === 'function') {
                    cbMapResize(self, evt);
                }

                if (self.infoWindow) {
                    self.infoWindow.close();
                }
            }.bind(this), 200));
        },

        closeInfoWindow: function () {
            if (this.infoWindow) {
                this.infoWindow.close();
            }
        }

    });
})();