type TDeliveryTime = {
    [key: number]: Record<string, string>;
};

/**
 * updates products delivery times in basket/index.tpl
 * @param {object} deliveryTimes delivery times of products for given shippingId
 * @param {number} shippingId
 *
 */
export const updateDeliveryTimes = (deliveryTimes: TDeliveryTime, shippingId: number): void => {
    try {
        const deliveryTimesForShipping = deliveryTimes[shippingId];

        if (deliveryTimesForShipping) {
            for (const [key, deliveryTime] of Object.entries(deliveryTimesForShipping)) {
                const $elToUpdate = document.querySelector(`tr[data-id="${key}"] .time`) as HTMLTableCellElement;
                if ($elToUpdate) {
                    $elToUpdate.textContent = deliveryTime as string;
                }
            }
        }
    } catch (err) {
        console.error(err);
    }
};
