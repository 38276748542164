;(function (undefined) {
    var SEARCH_LIST_CLASS = 'search__list';
    var SEARCH_LIST_ITEM_CLASS = SEARCH_LIST_CLASS + '-item';

    Shop.SearchTemplate = function (options) {
        this.options = options || {};

        this.elements = {
            $markup: null
        };

        this.settings = {};

        this.elements = {
            $markup: null
        };

        this.callbacks = {};

        this.state = null;
    };

    Shop.SearchTemplate.prototype = {
        constructor: Shop.SearchTemplate,

        parent: Shop.prototype,

        template: function (data) {
            var $phrasesList;
            var $btnLoadMore;
            var btnLoadMore;
            var $sectionFooter;
            var $listItem;
            var loadMoreText;
            var self = this;

            if (this.elements.$markup) {
                _bindEvents(this);

                return this.elements.$markup;
            }

            this.callbacks.onListItemClick = data.onListItemClick;
            this.callbacks.onListItemRemove = data.onListItemRemove;

            this.settings.listItemRemoveable = !!data.listItemRemoveable;
            this.settings.loadMoreSettings = data.loadMoreSettings;

            this.elements.$markup = $('<div/>', {
                'class': 'search__section' + (data.cssSectionClass ? ' ' + data.cssSectionClass : '')
            }).append($('<h3/>', {
                'class': 'search__section-head search__section-head_sticky search__section-head_line',
                'html': this.parent.simpleSanitizeHTML(data.title)
            }));

            if (this.options.type) {
                this.elements.$markup.attr('data-search-section-type', this.options.type.id);
                this.elements.$markup.attr('data-search-section-name', this.options.type.name);
            }

            $phrasesList = $('<ul/>', {
                'class': SEARCH_LIST_CLASS
            }).appendTo(this.elements.$markup);

            data.listItems = data.listItems || [];
            data.listItems.forEach(function (item, index) {
                $listItem = self.getListItemTemplate(item, (index > data.showLimit - 1), data.listItemRemoveable);

                $phrasesList.append($listItem);
            });

            if (data.listItems.length && (data.listItems.length > data.showLimit || data.loadMoreSettings)) {
                loadMoreText = (data.loadMoreSettings && data.loadMoreSettings.text) ? data.loadMoreSettings.text : Shop.lang.search.load_more;

                $btnLoadMore = $('<button/>', {
                    'class': 'js__search__btn-load-more search__section-btn-load-more',
                    'type': 'button',
                    'html': self.parent.simpleSanitizeHTML(loadMoreText)
                });

                if (data.loadMoreSettings) {
                    btnLoadMore = $btnLoadMore.get(0);
                    btnLoadMore.page = data.page;
                    btnLoadMore.pages = data.pages;
                    btnLoadMore.xhrPhrase = decodeURIComponent(data.xhrPhrase);
                }

                this.settings.showLimit = data.showLimit;
                this.settings.loadMoreCount = data.loadMoreCount;
                this.settings.elementsCount = data.elementsCount ? data.elementsCount : data.listItems.length;

                this.state = {
                    visibleElementsOffset: data.visibleElementsOffset ? data.visibleElementsOffset : data.showLimit,
                };

                $sectionFooter = $('<div/>', {
                    'class': 'search__section-footer'
                }).append($btnLoadMore);

                this.elements.$markup.append($sectionFooter);
            }

            _bindEvents(this);
            return this.elements.$markup;
        },

        getListItemTemplate: function (item, isVisible, isRemoveable) {
            var $listItem = $('<li/>', {
                'class': SEARCH_LIST_ITEM_CLASS + (isVisible ? ' none': ''),
                'html': (item.markup ? item.markup : item)
            });

            if (item.attributes) {
                this.parent.forEachIn(item.attributes, function (value, key) {
                    $listItem.attr(key, value);
                });
            }

            if (item.cssClasses) {
                $listItem.addClass(item.cssClasses);
            }

            if (isRemoveable) {
                $listItem.append($('<span>', {
                    'class': 'js__search-template-item-remove-btn search__list-item-action-btn icon icon-close'
                }));
            }

            return $listItem;
        },

        appendListItems: function (items) {
            var $documentFragment = $(document.createDocumentFragment());
            var $listItem;
            var self = this;

            this.settings.visibleElementsOffset += items.length;
            items.forEach(function (item) {
                $listItem = self.getListItemTemplate(item);

                $documentFragment.append($listItem);
            });

            this.elements.$markup.find('.' + SEARCH_LIST_CLASS).append($documentFragment);

            return this;
        },

        reRender: function () {
            this.settings.elementsCount = this.elements.$markup.find('.' + SEARCH_LIST_ITEM_CLASS).length;
            _syncStateWithView(this);

            return this;
        },

        clean: function () {
            this.elements.$markup = null;

            return this;
        },

        loadMore: function () {
            var $loadMoreBtn = this.elements.$markup.find('.js__search__btn-load-more');
            var visibleElementsOffset = this.state.visibleElementsOffset;
            var toShow;

            if (this.settings.loadMoreCount !== Infinity) {
                toShow = visibleElementsOffset + this.settings.loadMoreCount;
            }

            this.elements.$markup.find('.search__list-item').slice(visibleElementsOffset, toShow).removeClass('none');

            if (visibleElementsOffset >= this.settings.elementsCount - 1) {
                $loadMoreBtn.addClass('none');
            }

            if (this.settings.loadMoreCount !== Infinity) {
                this.state.visibleElementsOffset = toShow;
            }

            return this;
        }
    };

    function _bindEvents (templateInstance) {
        if (typeof templateInstance.callbacks.onListItemClick === 'function' || templateInstance.settings.listItemRemoveable) {
            templateInstance.elements.$markup.find('.' + SEARCH_LIST_CLASS).on('click', ('.' + SEARCH_LIST_ITEM_CLASS), function (evt) {
                var $this = $(this);

                if ($(evt.target).hasClass('js__search-template-item-remove-btn')) {
                    _removeListItem(templateInstance, evt, $this);
                } else if (typeof templateInstance.callbacks.onListItemClick === 'function') {
                    templateInstance.callbacks.onListItemClick(evt, $this);
                }
            });
        }

        templateInstance.elements.$markup.find('.js__search__btn-load-more').on('click', function () {
            if (templateInstance.settings.loadMoreSettings && typeof templateInstance.settings.loadMoreSettings.onLoadMoreClick === 'function') {
                templateInstance.settings.loadMoreSettings.onLoadMoreClick(this);
            } else {
                templateInstance.loadMore();
            }
        });
    }

    function _removeListItem (templateInstance, evt, $el) {
        $el.remove();
        templateInstance.reRender();

        if (typeof templateInstance.callbacks.onListItemRemove === 'function') {
            templateInstance.callbacks.onListItemRemove(evt, $el);
        }
    }

    function _syncStateWithView (templateInstance) {
        if (templateInstance.state) {
            templateInstance.elements.$markup.find('.search__list-item').slice(0, templateInstance.state.visibleElementsOffset).removeClass('none');
        }
    }
})();