import { store } from '@frontstoreRwd/store';
import { doesBasketExist } from './basket_utils';

const selectors = {
    boxSum: '#box_basket .sum em',
    boxCount: '#box_basket .products em'
};

export default {
    $boxSum: null as HTMLElement | null,
    $boxCount: null as HTMLElement | null,

    basketBoxInitialize() {
        this.$boxSum = document.querySelector(selectors.boxSum);
        this.$boxCount = document.querySelector(selectors.boxCount);
    },

    updateBoxBasketView(): boolean {
        if (doesBasketExist()) {
            if (this.$boxSum) {
                this.$boxSum.textContent = store.basketInfo!.basket.sum_no_shipping;
            }

            if (this.$boxCount) {
                this.$boxCount.textContent = String(store.basketInfo!.basket.count);
            }

            return true;
        }

        return false;
    }
};
