import { TAnalyticEventHandler, TAnalyticEventName } from '../event/types';

import { AnalyticEvent } from '../event';
import { IAnalyticService } from './types';
import { TServiceName } from '../../services/types';

export abstract class AnalyticService<T> implements IAnalyticService {
    private _name: TServiceName;
    protected _isSetuped = false;
    protected _events: Partial<Record<TAnalyticEventName, TAnalyticEventHandler<T>>> = {};

    public abstract setup(): Promise<void>;

    constructor(name: TServiceName) {
        this._name = name;
    }

    public getName(): TServiceName {
        return this._name;
    }

    public processEvent(event: AnalyticEvent): void {
        const eventHandler = this._events[event.name];
        const isEventHandlerCallable = typeof eventHandler === 'function';

        if (eventHandler && isEventHandlerCallable) {
            eventHandler(this as unknown as T, event.params);
        }
    }

    public isSetuped(): boolean {
        return this._isSetuped;
    }
}
