import { IFormValidator } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';
import {
    TPhoneCountries,
    TPhoneNumberPatternNames,
    TPhoneNumberPatternStrategy,
    TPhonePrefixStrategy
} from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import {
    formatToPhoneNumberWithoutMask,
    createInputMaskInstance,
    formatToPhoneNumberWithNineDigitsMask,
    formatToPhoneNumberWithoutPrefix,
    replacePlusWithDoubleZerosInPrefix
} from '@frontstoreRwd/modules/phone_country_select/phone_country_select_utils';
import {
    PHONE_COUNTRY_SELECT_CSS_CLASSES,
    PHONE_NUMBER_DETAILS,
    PHONE_NUMBER_PREFIX,
    PHONE_NUMBER_PATTERN_STRATEGIES,
    PHONE_NUMBER_COUNTRIES
} from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';

export const phonePatternStrategies: Record<TPhoneNumberPatternNames, TPhoneNumberPatternStrategy> = {
    [PHONE_NUMBER_PATTERN_STRATEGIES.nineDigits]: {
        setMaskData($input: HTMLInputElement): void {
            const { mask, validPattern, pattern } = PHONE_NUMBER_DETAILS.nineDigits;

            $input.dataset.mask = mask;
            $input.dataset.validPattern = validPattern;
            $input.dataset.pattern = pattern;

            $input.dataset.addMask = 'true';
        },
        updateMask($input: HTMLInputElement, selectedCountry: TPhoneCountries): void {
            const phoneNumberWithMask = $input.value;
            const phoneNumberWithoutMask = formatToPhoneNumberWithoutMask(phoneNumberWithMask);
            const phoneNumberLength = phoneNumberWithoutMask.length;

            if (phoneNumberLength) {
                const prefix = PHONE_NUMBER_PREFIX[selectedCountry];
                const formattedPrefix = replacePlusWithDoubleZerosInPrefix(prefix);
                const formattedPhoneNumber =
                    phoneNumberWithoutMask.startsWith(formattedPrefix) && prefix
                        ? formatToPhoneNumberWithNineDigitsMask(formatToPhoneNumberWithoutPrefix(phoneNumberWithoutMask, formattedPrefix))
                        : formatToPhoneNumberWithNineDigitsMask(phoneNumberWithoutMask);
                $input.value = formattedPhoneNumber;
            } else {
                $input.value = '';
            }

            const shouldCreateInputMaskInstance = $input.dataset.inputMaskInstance === 'false';

            if (shouldCreateInputMaskInstance) {
                createInputMaskInstance($input);
                $input.dataset.inputMaskInstance = 'true';
            }
        },
        setValidatorActivity(validator: IFormValidator, phoneName: string): void {
            validator.enableValidator(phoneName);
        }
    },
    [PHONE_NUMBER_PATTERN_STRATEGIES.none]: {
        setMaskData($input: HTMLInputElement): void {
            $input.dataset.addMask = 'false';
        },
        updateMask($input: HTMLInputElement): void {
            const phoneNumberWithMask = $input.value;
            const phoneNumberWithoutMask = formatToPhoneNumberWithoutMask(phoneNumberWithMask);

            $input.value = phoneNumberWithoutMask;
        },
        setValidatorActivity(validator: IFormValidator, phoneName: string): void {
            validator.disableValidator(phoneName);
        }
    }
};

export const phoneCountryStrategies: Record<TPhoneCountries, TPhonePrefixStrategy> = {
    [PHONE_NUMBER_COUNTRIES.Poland]: {
        setPrefixData($inputContainer: HTMLDivElement): void {
            $inputContainer.dataset.prefix = PHONE_NUMBER_PREFIX.Poland;

            $inputContainer.classList.add(PHONE_COUNTRY_SELECT_CSS_CLASSES.inputPrefix);
        }
    },
    [PHONE_NUMBER_COUNTRIES.Other]: {
        setPrefixData($inputContainer: HTMLDivElement): void {
            $inputContainer.classList.remove(PHONE_COUNTRY_SELECT_CSS_CLASSES.inputPrefix);
        }
    }
};
