import { $once } from '@core/tools/event_bus';
import { IHandle } from '@frontstoreRwd/core/events/i_handle';
import { ConsentsModal } from '..';
import { CONSENTS_SAVED } from '../../consents_api/event_names';

export class AfterConsentsSaved implements IHandle {
    private _consentsModal: ConsentsModal;

    constructor(consentsModal: ConsentsModal) {
        this._consentsModal = consentsModal;
        this.setupEvents();
    }

    setupEvents(): void {
        $once(CONSENTS_SAVED, this._onConsentsSavedEvent.bind(this));
    }

    private _onConsentsSavedEvent() {
        this._consentsModal.close();
        this._consentsModal.saveAsDisplayed();
    }
}
