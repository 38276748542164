import {
    isDifferentAddressChecked,
    isPhoneValidationEnabled,
    isPolandSelected,
    shouldPhoneCountrySelectBeVisible
} from '@frontstoreRwd/modules/basket_manage/basket_utils';
import { BASKET_SELECTORS } from '@frontstoreRwd/modules/basket_manage/basket_constants';
import { PHONE_COUNTRY_SELECT_CREATED_EVENT } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_event_names';
import { IPhoneCountrySelect } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import { VALIDATED_ELEMENTS_NAMES } from '@frontstoreRwd/modules/validation/form_validator/scheme/basket/form_validator_basket_constants';
import { IHandle } from '@frontstoreRwd/core/events/i_handle';
import { $on } from '@core/tools/event_bus';

export class AfterPhoneCountrySelectCreated implements IHandle {
    #phoneCountryInstances: Array<IPhoneCountrySelect> = [];

    #selectors = {
        addressDifferentId: BASKET_SELECTORS.addressDifferentId,
        primaryInputPhoneID: BASKET_SELECTORS.primaryInputPhoneID
    };

    constructor() {
        this.setupEvents();
    }

    public setupEvents(): void {
        $on(PHONE_COUNTRY_SELECT_CREATED_EVENT, this._setupPhoneCountrySelect);
    }

    private _setupPhoneCountrySelect = (phoneCountryInstance: unknown) => {
        this.#phoneCountryInstances.push(phoneCountryInstance as IPhoneCountrySelect);

        const currentPhoneCountryInstance = phoneCountryInstance as IPhoneCountrySelect;

        if (shouldPhoneCountrySelectBeVisible(currentPhoneCountryInstance)) {
            currentPhoneCountryInstance.activePhoneCountryFunctionality();
        } else {
            currentPhoneCountryInstance.resetPhoneCountryFunctionality();
        }

        this._setupPhoneCountryEventHandlers(currentPhoneCountryInstance);
    };

    private _setupPhoneCountryEventHandlers = (currentPhoneCountryInstance: IPhoneCountrySelect): void => {
        const phoneName = currentPhoneCountryInstance.$phoneInput.getAttribute('name') as string;
        const $countrySelect = document.querySelector<HTMLSelectElement>(`${BASKET_SELECTORS.countryForPhone}${phoneName}`);

        $countrySelect?.addEventListener('change', () => {
            this._updatePhoneCountryData(currentPhoneCountryInstance);
        });

        const $addressSelect = document.querySelector<HTMLSelectElement>(`${BASKET_SELECTORS.addressForPhone}${phoneName}`);

        $addressSelect?.addEventListener('change', () => {
            this._updatePhoneCountryData(currentPhoneCountryInstance);
        });

        const $differentAddressCheckbox = document.getElementById(this.#selectors.addressDifferentId);

        $differentAddressCheckbox?.addEventListener('change', this._updateSecondaryPhoneCountryData);
    };

    private _updatePhoneCountryData = (currentPhoneCountryInstance: IPhoneCountrySelect): void => {
        this._updateCurrentPhoneCountryData(currentPhoneCountryInstance);
        this._updateSecondaryPhoneCountryData();
    };

    private _updateCurrentPhoneCountryData = (currentPhoneCountryInstance: IPhoneCountrySelect): void => {
        if (!isPhoneValidationEnabled(currentPhoneCountryInstance)) return;

        const currentPhoneName = currentPhoneCountryInstance.$phoneInput.getAttribute('name') as string;

        currentPhoneCountryInstance.toggleDefaultPhoneNumberData(isPolandSelected(`${BASKET_SELECTORS.countryForPhone}${currentPhoneName}`));
    };

    private _updateSecondaryPhoneCountryData = (): void => {
        const secondaryPhoneCountryInstance = this._getSecondaryPhoCountryInstance();

        if (!isPhoneValidationEnabled(secondaryPhoneCountryInstance)) return;

        const secondaryPhoneName = secondaryPhoneCountryInstance?.$phoneInput.getAttribute('name') as string;

        if (isDifferentAddressChecked()) {
            if (!isPolandSelected(`${BASKET_SELECTORS.countryForPhone}${secondaryPhoneName}`)) return;

            secondaryPhoneCountryInstance?.activePhoneCountryFunctionality();

            return;
        }

        secondaryPhoneCountryInstance?.validator?.disableValidator(secondaryPhoneName);
    };

    private _getSecondaryPhoCountryInstance(): IPhoneCountrySelect | undefined {
        return this.#phoneCountryInstances.filter(({ $phoneInput }) => {
            return ($phoneInput.getAttribute('name') as string) === VALIDATED_ELEMENTS_NAMES.phone2;
        })[0];
    }
}
