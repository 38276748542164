export class ScriptInjector {
    public static async create(id: string, src: string): Promise<void> {
        if (document.getElementById(id)) {
            return;
        }

        await ScriptInjector._injectScript(id, src);
    }

    private static _injectScript(id: string, src: string): Promise<void> {
        return new Promise((resolve) => {
            const firstScriptOnPage = document.getElementsByTagName('script')[0];
            const script = document.createElement('script');

            script.id = id;
            script.src = src;
            script.async = true;
            script.onload = () => {
                resolve();
            };

            firstScriptOnPage.parentNode?.insertBefore(script, firstScriptOnPage);
        });
    }
}
