import { ACTIONS_TYPES } from '@frontstoreRwd/modules/validation/form_validator/form_validator_constants';
import { INPUT_MASK_CHANGE_EVENT } from '@frontstoreRwd/modules/input_mask';
import { EVENT_NAMES } from '@frontstoreRwd/modules/validation/form_validator/form_validator_event_names';
import { TFormValidatorOptions } from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';
import {
    isProperPhoneNumberLength,
    isPhoneNumberFirstDigitValid
} from '@frontstoreRwd/modules/validation/form_validator/validators/phone_validators';
import { VALIDATED_ELEMENTS_NAMES } from '@frontstoreRwd/modules/validation/form_validator/scheme/basket/form_validator_basket_constants';

export const basketAddressFormValidatorOptions: TFormValidatorOptions = {
    scheme: {
        [VALIDATED_ELEMENTS_NAMES.phone]: {
            validators: [new isProperPhoneNumberLength(), new isPhoneNumberFirstDigitValid()],
            isEnabled: false,
            actions: [{ actionName: EVENT_NAMES.blur }, { actionName: INPUT_MASK_CHANGE_EVENT, actionType: ACTIONS_TYPES.global }]
        },
        [VALIDATED_ELEMENTS_NAMES.phone2]: {
            validators: [new isProperPhoneNumberLength(), new isPhoneNumberFirstDigitValid()],
            isEnabled: false,
            actions: [{ actionName: EVENT_NAMES.blur }, { actionName: INPUT_MASK_CHANGE_EVENT, actionType: ACTIONS_TYPES.global }]
        }
    },
    settings: {
        validateOnSubmit: true
    }
};
