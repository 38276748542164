import { $off, $on } from '@core/tools/event_bus';
import { ACTIONS_TYPES } from '@frontstoreRwd/modules/validation/form_validator/form_validator_constants';
import {
    TActionName,
    TActionType,
    TFormValidatorEventStrategy,
    TInputMaskInstance
} from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';

export const formValidatorEventsStrategies: Record<TActionType, TFormValidatorEventStrategy> = {
    [ACTIONS_TYPES.dom]: {
        addListener: (action: TActionName, callback: (params: Event | TInputMaskInstance) => void, $element: HTMLElement): void => {
            $element.addEventListener(action, callback);
        },

        removeListener: (action: TActionName, callback: (params: Event | TInputMaskInstance) => void, $element: HTMLElement): void => {
            $element.removeEventListener(action, callback);
        }
    },
    [ACTIONS_TYPES.global]: {
        addListener: (action: TActionName, callback: (params: Event | TInputMaskInstance) => void): void => {
            $on(action, callback);
        },
        removeListener: (action: TActionName, callback: (params: Event | TInputMaskInstance) => void): void => {
            $off(action, callback);
        }
    }
};
