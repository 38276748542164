import { setDefaultInpostPhoneNumber } from '../inpost_phone_number_utils';
import { DIFFERENT_ADDRESS_REMOVED_EVENT } from '../../inpost_event_names';
import { IPhoneCountrySelect } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import { IHandle } from '@frontstoreRwd/core/events/i_handle';
import { $once } from '@core/tools/event_bus';

export class AfterDifferentAddressRemoved implements IHandle {
    #primaryPhoneCountryInstance: IPhoneCountrySelect;

    constructor(phoneCountryInstance: IPhoneCountrySelect) {
        this.#primaryPhoneCountryInstance = phoneCountryInstance;

        this.setupEvents();
    }

    public setupEvents(): void {
        $once(DIFFERENT_ADDRESS_REMOVED_EVENT, (): void => {
            setDefaultInpostPhoneNumber(this.#primaryPhoneCountryInstance);
            this.#primaryPhoneCountryInstance.toggleDefaultPhoneNumberData(true);
        });
    }
}
