;(function (undefined) {
    function FilterFakeSelect (options) {
        this.options = {
            $el: options.$el || null,
            labelSeparator: ', '
        };

        this.initialize();
    }

    FilterFakeSelect.prototype = {
        constructor: FilterFakeSelect,

        initialize: function () {
            var title;
            var $title;
            var $list;
            var $listElements;
            var lbl;
            var colors;

            $title = this.options.$el.find('> h5');
            title = $title.text().trim();
            this.options.$el.removeClass().addClass('multiselect');
            $title.replaceWith('<span>' + title + '</span>');
            $title = this.options.$el.find('> span');

            $list = this.options.$el.find('> ul');
            this.options.$el.on('mousedown', function (ev) {
                if (ev.currentTarget === ev.target) {
                    $('.multiselect:not(#' + $(ev.target).attr('id') + ') > ul').hide();
                    $list.toggle();
                } else if ($(ev.target).parent()[0] === ev.currentTarget) {
                    if (ev.target.tagName !== 'UL') {
                        $('.multiselect:not(#' + $(ev.target).parent().attr('id') + ') > ul').hide();
                        $list.toggle();
                    }
                }
            });

            lbl = [];
            colors = false;
            $listElements = this.options.$el.find('.selected span');
            $listElements.each(function () {
                if ($(this).data('color')) {
                    lbl.push($(this).data('color'));
                    colors = true;
                } else {
                    lbl.push($(this).text().trim());
                }
            });

            if (lbl.length === 0) {
                $title.html(title + ': (' + Shop.lang.common.product_stock_select + ')');
            } else {
                if (!colors) {
                    this.adjustLabels(this.options.$el, lbl, title, $title);
                } else {
                    $title.html(title + ': ');

                    lbl.forEach(function (color) {
                        $('<span />', {
                            'class': 'color-filter'
                        }).appendTo($title).css('background-color', color);
                    });
                }
            }
        },

        adjustLabels: function (select, lbl, headText, span) {
            var containerWidth;
            var $fakeContainer;
            var lblLength;
            var firstLbl;
            var dots;

            dots = '';
            firstLbl = lbl[0];
            containerWidth = select.width();
            $fakeContainer = $('<span />', {
                text: headText + ': ' + lbl.join(this.options.labelSeparator)
            }).appendTo($('body'));

            if (($fakeContainer.width() + 50) > containerWidth) {
                dots = ' [...]';
            }

            lblLength = lbl.length;
            while (($fakeContainer.width() + 50) > containerWidth) {
                lbl = lbl.slice(0, lblLength - 1);
                $fakeContainer.text(headText + ': ' + lbl.join(this.options.labelSeparator));
                lblLength = lbl.length;

                if (lbl.length <= 0) {
                    break;
                }
            }

            if (lbl.length <= 0) {
                $fakeContainer.text(headText + ': ' + firstLbl);
                while (($fakeContainer.width() + 50) > containerWidth) {
                    firstLbl = firstLbl.slice(0, firstLbl.length - 1);
                    $fakeContainer.text(headText + ': ' + firstLbl);

                    if (firstLbl.length <= 0) {
                        break;
                    }
                }

                span.html(headText + ': ' + Shop.fn.simpleSanitizeHTML(firstLbl) + dots);
            } else {
                span.html(headText + ': ' + lbl.join(this.options.labelSeparator) + dots);
            }

            $fakeContainer.remove();
        }
    };

    Shop.Filter.include({
        options: {
            limit: 5,
            fakeSelect: 0,

            selectors: {
                box: '#box_filter',
                groups: '#box_filter .group-filter',
                ulGroups: '> ul',
                item: 'li',
                itemSelected: 'selected',
                itemHide: 'none'
            },

            elements: {
                $box: null,
                $groups: null,
                $ulGroups: null
            }
        },

        initialize: function (options) {
            var self;

            this.constructor.setOptions(options);

            this.options.elements.$box = $(this.options.selectors.box);
            this.options.elements.$groups = $(this.options.selectors.groups);
            this.options.elements.$ulGroups = this.options.elements.$groups.find(this.options.selectors.ulGroups);
            this.options.fakeSelect = parseInt(this.options.elements.$box.data('selectDisplay'), 10);
            this.options.limit = parseInt(this.options.elements.$box.data('limit'), 10);

            if (this.options.fakeSelect === 1) {
                self = this;
                this.options.elements.$groups.each(function () {
                    if (!this.FilterFakeSelect) {
                        this.FilterFakeSelect = new FilterFakeSelect({
                            $el: $(this)
                        });
                    }
                });

                $(window).on('click', function (ev) {
                    if (!$(ev.target).hasClass('multiselect') && $(ev.target).parents('.multiselect').length <= 0) {
                        self.options.elements.$groups.find('> ul').hide();
                    }
                });
            }

            this.hide();
            this.options.elements.$box.removeClass('loading');
        },

        hide: function () {
            var self;
            var lists;

            self = this;
            lists = [];

            this.options.elements.$groups.each(function () {
                if ($(this).data('limit')) {
                    lists.push({
                        list: $(this).find(self.options.selectors.item),
                        limit: $(this).data('limit')
                    });
                } else if (self.options.limit > 0) {
                    lists.push({
                        list: $(this).find(self.options.selectors.item)
                    });
                }
            });

            this.parent.forEachIn(lists, function (obj) {
                self.hideList(obj.list, obj.limit);
            });
        },

        showList: function (list) {
            list.removeClass(this.options.selectors.itemHide);
            list.$button.text(Shop.lang.filter.show_less);
            list.collapsed = false;
        },

        hideList: function (list, limit) {
            var self;
            var listLength;
            var numberToHide;
            var lastItem;
            var $item;

            self = this;
            listLength = list.length;
            limit = limit || self.options.limit;

            if (listLength > limit) {
                numberToHide = listLength - limit;
                lastItem = listLength - 1;

                if (!!list.find('em').length) {
                    list.sort(self.sort.bind(this));
                }

                while (numberToHide > 0) {
                    $item = $(list[lastItem]);

                    if ($item.length === 0) {
                        break;
                    }

                    if (!$item.hasClass(self.options.selectors.itemSelected)) {
                        $item.addClass(self.options.selectors.itemHide);
                        numberToHide -= 1;
                    }

                    lastItem -= 1;
                }

                if (numberToHide !== listLength - limit) {
                    if (!list.$button) {
                        list.$button = $('<li />', {
                            'text': Shop.lang.filter.show_more,
                            'class': 'showmore'
                        }).appendTo(list.parent()).on('click', function (ev) {
                            ev.stopPropagation();

                            if (list.collapsed === true) {
                                self.showList(list);
                            } else {
                                self.hideList(list, limit);
                            }
                        });
                    } else {
                        list.$button.text(Shop.lang.filter.show_more);
                    }
                }

                list.collapsed = true;
            };
        },

        sort: function (x, y) {
            var xv;
            var yv;

            x = $(x);
            y = $(y);
            xv = parseInt(x.find('em').text().replace(/[^0-9]/g, ''), 10);
            yv = parseInt(y.find('em').text().replace(/[^0-9]/g, ''), 10);

            if (!xv) {
                xv = 0;
            }

            if (!yv) {
                yv = 0;
            }

            return yv - xv;

        }
    });
})();