import { BOX_RENDER_STRATEGIES } from './box_render_strategies';
import getRecentlyViewedProducts from './get_recently_viewed_products';

(function (undefined) {
    Shop.xhrBox.include({
        options: {
            xhrSelector: '.box-xhr'
        },

        initialize: function (options) {
            this.constructor.setOptions(options);

            getRecentlyViewedProducts();
            const self = this;

            $.each($(this.options.xhrSelector), function () {
                const recentViewProducts = JSON.parse(localStorage.getItem('recentViewProducts') ?? '[]');

                const $el = $(this);
                const url = $el.data('url');

                $.ajax({
                    url: url ?? '/box/render',
                    method: 'get',
                    async: true,
                    cache: false,
                    headers: {
                        'X-Cookie': 'lastProducts-' + Shop.lang.name + '=' + recentViewProducts.toString()
                    },
                    data: {
                        engine: $el.data('engine'),
                        id: $el.data('id')
                    }
                }).done(function (data) {
                    $el.html(data);
                    $el.removeClass('loading');

                    const renderStrategy = BOX_RENDER_STRATEGIES[$el.data('engine')];

                    if (typeof renderStrategy === 'function') {
                        BOX_RENDER_STRATEGIES[$el.data('engine')]({
                            moduleElement: $el,
                            shoperModule: self
                        });
                    }
                });
            });
        }
    });
})();
