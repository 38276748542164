/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
export function createElementFromHTMLString(html: string): ChildNode {
    const template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild as ChildNode;
}
