;(function (undefined) {
	Shop.ImageSlider.include({
        options: {
            container: null,
            $ul: null,
            $imgs: null,
            $nav: null,
            $navLeft: null,
            $navRight: null,

            containerWidth: null,
            elementWidth: null,
            elementsWidth: null,
            numberToHide: null,

            counter: 0
        },

        initialize: function (options) {
            var self;

            this.constructor.setOptions(options);
            this.options.$ul = this.options.container.find('ul');
            this.options.$imgs = this.options.container.find('li');
            this.options.$nav = this.options.container.find('nav');
            this.options.$navLeft = this.options.$nav.find('.smallgallery-left');
            this.options.$navRight = this.options.$nav.find('.smallgallery-right');
            this.hideElements();

            $(window).on('resize', this.hideElements.bind(this));

            this.options.$navLeft.on('mousedown', this.showPrev.bind(this));
            this.options.$navRight.on('mousedown', this.showNext.bind(this));
            this.options.container.on('swipeleft', this.showNext.bind(this));
            this.options.container.on('swiperight', this.showPrev.bind(this));
        },

        getNumberToHide: function () {
            var self;

            self = this;
            this.options.containerWidth = this.options.$ul.width();
            this.options.elementsWidth = 0;
            this.options.elementWidth = this.options.$imgs.outerWidth(true);

            this.options.$imgs.each(function () {
                self.options.elementsWidth += $(this).outerWidth(true);
            });

            return Math.max(Math.ceil((this.options.elementsWidth - this.options.containerWidth) / this.options.elementWidth), 0);
        },

        hideElements: function () {
            var number = this.getNumberToHide();
            this.options.$imgs.removeClass('none');

            if (number) {
                this.options.$imgs.filter(':nth-last-child(-n + ' + number + ')').addClass('none');
                this.options.$ul.addClass('with-nav');
                this.options.$nav.removeClass('none');
            } else {
                this.options.$ul.removeClass('with-nav');
                this.options.$nav.addClass('none');
            }
        },

        showNext: function () {
            var $toShow;
            var $toHide;

            this.options.$navLeft.removeClass('none');
            $toHide = this.options.$imgs.filter('li:not(.none):first');
            $toShow = this.options.$imgs.filter('li:not(.none) + .none:first');

            if (!!$toShow.length) {
                $toHide.addClass('none');
                $toShow.removeClass('none');
            }

            if ($toShow.is(':last-child') === true) {
                this.options.$navRight.addClass('none');
            }
        },

        showPrev: function () {
            var $toShow;
            var $toHide;

            this.options.$navRight.removeClass('none');
            $toHide = this.options.$imgs.filter('li:not(.none):last');
            $toShow = this.options.$imgs.filter('li:not(.none):first').prev();

            if (!!$toShow.length) {
                $toHide.addClass('none');
                $toShow.removeClass('none');
            }

            if ($toShow.is(':first-child') === true) {
                this.options.$navLeft.addClass('none');
            }
        }
    });
})();