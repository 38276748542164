import { PHONE_NUMBER_COUNTRIES } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';

export const BASKET_SELECTORS: Record<string, string> = {
    addressDifferentId: 'address_different',
    primaryInputPhoneID: 'input_phone',
    delivery: '.delivery',
    countryForPhone: '.js__country-for-',
    addressForPhone: '.js__address-for-'
};

export const COUNTRY_ID: Record<string, string> = {
    [PHONE_NUMBER_COUNTRIES.Poland]: '179'
};

export const BASKET_JS_CLASSES: Record<string, string> = {
    basketExtraStepShippings: 'js__basket-extra-step-shippings'
};

export const SESSION_EXPIRY_TIME_IN_MS = 43200000;
