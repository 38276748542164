;(function (undefined) {
	Shop.PageSlider.include({
        options:{ 
            containerSelector: '.pageslider',
            slideListSelector: '.slides',
            imgSelector: '.slider-img',
            txtSelector: '.slider-text',

            navType: null,

            animationType: null,
            autoRotate: false,
            circular: true,

            slideListWidth: 0,
            slideWidth: 0,
            imgRatio: 0,

            container: []
        },

        initialize: function (options) {
            this.constructor.setOptions(options);

            if (Shop.mRaty) {
                if (!Shop.mRaty.banner) {
                    this.setupSlider();
                }
            } else {
                this.setupSlider();
            }
        },

        setupSlider: function () {
            var container = $(this.options.containerSelector);
            container.each(function (index, el) {
                var slideList = $(el).find(this.options.slideListSelector);
                var slides = slideList.children('li');
                var ratioWidth;
                var slideListHeight;

                if (parseInt($(el).data('max-img-width'), 10) > $(el).parent('.box-slider').width()) {
                    ratioWidth = parseInt($(el).data('max-img-width'), 10);
                } else {
                    ratioWidth = $(el).parent('.box-slider').width();
                }

                if (slideList.height() > 0) {
                    slideListHeight = ratioWidth / slideList.height();
                } else {
                    slideListHeight = ratioWidth / slides.find('.slider-text').height();
                }

                this.options.container.push({
                    imgRatio: slideListHeight,
                    animationType: parseInt($(el).attr('data-slider-animation-type'), 10),
                    autoRotate: ($(el).attr('data-slider-auto') == 'true'),
                    navType: $(el).attr('data-slider-nav-type'),
                    autoDelay: parseInt($(el).attr('data-slider-delay'), 10),
                    speed: parseInt($(el).attr('data-slider-duration'), 10),
                    slidesOptions: [],
                    circular: true,
                    rotate: null,
                    objects: {
                        container: $(el),
                        slidesContainer: slideList,
                        slides: slides
                    }
                });
            }.bind(this));

            this.createNav();
            this.autoRotate();

            this.setupSlides();
            $(window).on('resize', this.recalculateDimensions.bind(this));
        },

        recalculateDimensions: function () {
            var that = this;

            $.each(this.options.container, function () {
                var self = this;

                this.slideWidth = this.objects.container.width();
                this.slideListWidth = this.objects.slides.length * this.slideWidth;

                this.objects.slidesContainer.width(this.slideListWidth);
                this.objects.slidesContainer.height(this.objects.container.width() / this.imgRatio);
                this.objects.slides.width(this.slideWidth);

                $.each(this.slidesOptions, function () {
                    this.img.container.find('> img').on('load', function () {
                        that.sliderObjReposition.call(self, this.img, 'img');
                    }.bind(this));
                    that.sliderObjReposition.call(self, this.img, 'img');
                    that.sliderObjReposition.call(self, this.txt);

                    if (!!this.container.find('[style*="font-size"]').length) {
                        that.recalculateFontSize(this.container.find('[style*="font-size"]'));
                    }
                });
            });
        },

        recalculateFontSize: function ($els) {
            var maxMidSize;
            var maxSmallSize;
            var shopClass;

            shopClass = this.parent;
            maxMidSize = 26;
            maxSmallSize = 16;

            $els.each(function () {
                var $el;
                var fontSize;

                $el = $(this);
                fontSize = parseInt($el.css('font-size'), 10);

                if (!this.initialFontSize) {
                    this.initialFontSize = fontSize;
                }

                if (shopClass.rwd.large) {
                    $el.css('font-size', this.initialFontSize + 'px');
                }

                if (shopClass.rwd.mid && fontSize > maxMidSize) {
                    $el.css('font-size', maxMidSize + 'px');
                }

                if (shopClass.rwd.small && fontSize > maxSmallSize) {
                    $el.css('font-size', maxSmallSize + 'px');
                }
                
            });
        },

        setupSlides: function () {
            var t = this,
                shopClass = this.parent,
                img,
                txt,
                gradients;

            gradients = ['-webkit-linear-gradient', '-o-linear-gradient', '-moz-linear-gradient', 'linear-gradient'];

            $.each(this.options.container, function () {
                var self = this;

                this.objects.slides.each(function (index) { 
                    img = $(this).find(t.options.imgSelector);
                    txt = $(this).find(t.options.txtSelector)

                    self.slidesOptions[index] = {
                        container: $(this),

                        bg: {
                            container: $(this),
                            image: $(this).attr('data-image'),
                            color1: $(this).attr('data-color-start'),
                            color2: $(this).attr('data-color-end'),
                            orientation: $(this).attr('data-orientation'),
                            size1: $(this).attr('data-bg-size-s'),
                            size2: $(this).attr('data-bg-size-e'),
                            href: $(this).attr('data-href')
                        },

                        img: {
                            container: img,
                            align: parseInt(img.find('img').attr('data-align'), 10),
                            animation: parseInt(img.find('img').attr('data-animation'), 10),
                            duration: parseInt(img.find('img').attr('data-duration'), 10) || 0
                        },

                        txt: {
                            container: txt,
                            align: parseInt(txt.attr('data-align'), 10),
                            animation: parseInt(txt.attr('data-animation'), 10),
                            duration: parseInt(txt.attr('data-duration'), 10) || 0
                        },

                        active: index ? false : true
                    }

                    if (index) {
                        t.hideSlide($(this), self.slidesOptions[index].img, self.slidesOptions[index].txt);
                    }

                    $.each(self.slidesOptions, function (index) {
                        var bgHref = shopClass.url('userdata/public/boxes/' + this.bg.image),
                            selfS = this;

                        if (this.bg.image) {
                        this.bg.container.css({
                            'background-image': (this.bg.image ? 'url(' + bgHref + ')' : ''),
                            'background-size': '100%',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center'
                            });
                        } else if ((this.bg.color1 !== 'transparent' && this.bg.color2 !== 'transparent') && (this.bg.color1 && this.bg.color2)) {
                            $.each(gradients, function () {
                                selfS.bg.container.css({
                                    'background': setGradient(this, selfS.bg.color1, selfS.bg.color2, selfS.bg.orientation, selfS.bg.size1, selfS.bg.size2)
                                });
                            });
                        } else if (this.bg.color1 !== 'transparent' && this.bg.color1) {
                            this.bg.container.css('background', (selfS.bg.image ? 'url(' + bgHref + ')' : '') + ' no-repeat center center, ' + this.bg.color1);
                        } else {
                            this.bg.container.css('background', (selfS.bg.image ? 'url(' + bgHref + ')' : '') + ' no-repeat center center, transparent');
                        }

                        if (selfS.bg.href !== '') {
                            selfS.bg.container.css('cursor', 'pointer');
                        }

                        this.bg.container.on('click', function (e) {
                            if (selfS.bg.href !== '') {
                                window.location.href = selfS.bg.href;
                            }
                        });
                    });
                });
            });

            function setGradient (cssProperty, color1, color2, orientation, bgSize, bgSize2) {
                return (cssProperty + '(' + (orientation === '1' ? 'to right, ' : '') +  color1 + ' ' + bgSize + '%,' + color2 + ' ' + bgSize2 + '%)');
            }
        },

        sliderObjReposition: function (obj, type) {
            var position = getPosition(obj.align);
            var container = obj.container;

            if (type === 'img' && obj.container.hasClass('slider-img')) {
                container = obj.container.find('> img');
            }

            if (!isNaN(obj.align)) {
                switch (position[0]) {
                    case 0:
                        container.css('left', '0');
                        break;
                    case 1:
                        container.css('left', (this.slideWidth - container.width()) / 2);
                        break;
                    case 2: 
                        container.css('right', '0');
                        break;
                }

                switch (position[1]) {
                    case 0:
                        container.css('top', '0');
                        break;
                    case 1:
                        container.css('top', (this.objects.container.height() / 2) - (container.height() / 2));
                        break;
                    case 2: 
                        container.css('bottom', '0');
                        break;
                }
            }

            function getPosition (align, index) {
                var x,
                    y;

                x = (align % 3);
                y = Math.floor(align / 3);

                return [x, y];
            }
        },

        createNav: function () {
            var t = this;

            $.each(this.options.container, function () {
                if (this.objects.slides.length > 1) {
                    var self = this;
                    var nav = $('<ul />', {
                        'class': 'slider-nav rwd-hide-small rwd-hide-tablet rwd-hide-medium ' + this.navType
                    }).appendTo(this.objects.container);

                    this.objects.nav = nav;

                    this.objects.slides.each(function (index) {
                        $('<li />', {
                            'data-slide-index': index,
                            'class': index ? '' : 'active',
                            'text': self.navType === 'numbers' ? (index + 1) : ''
                        }).appendTo(nav).on('click', function (e) {
                            t.goToSlide.call(self, index, $(this), t);
                        });
                    });

                    this.objects.container.on('swipeleft', t.slideRight.bind(t, this));
                    this.objects.container.on('swiperight', t.slideLeft.bind(t, this));

                    $('<span />', {
                        'class': 'ps-arrow-left'
                    }).appendTo(this.objects.container).on('click', t.slideRight.bind(t, this));

                    $('<span />', {
                        'class': 'ps-arrow-right'
                    }).appendTo(this.objects.container).on('click', t.slideLeft.bind(t, this));
                }
            });           
        },

        goToSlide: function (index, control, t) {  
            if (this.objects.nav) {
                var self = this;
                var previousIndex = parseInt(this.objects.nav.find('li.active').attr('data-slide-index'), 10);

                this.objects.nav.find('li.active').removeClass('active');
                control.addClass('active');

                if (!isNaN(previousIndex)) {
                    t.hideSlide(null, this.slidesOptions[previousIndex].img, this.slidesOptions[previousIndex].txt);

                    switch (this.animationType) {
                        case 0:
                            setTimeout(function () {
                                self.objects.slides.css('left', -index * self.slideWidth);
                                t.showSlide($(this), self.slidesOptions[index].img, self.slidesOptions[index].txt);
                            }.bind(this), Math.max(self.slidesOptions[previousIndex].img.duration, self.slidesOptions[previousIndex].txt.duration));
                            break;
                        case 1:
                            setTimeout(function () {
                                this.objects.slides.animate({
                                    left: -index * self.slideWidth
                                }, this.speed, function () {
                                    t.showSlide($(this), self.slidesOptions[index].img, self.slidesOptions[index].txt);
                                });
                            }.bind(this), Math.max(self.slidesOptions[previousIndex].img.duration, self.slidesOptions[previousIndex].txt.duration));
                            break;
                        case 2:
                            this.objects.slides.animate({
                                opacity: 0
                            }, (this.speed / 2), function () {
                                self.objects.slides.css('left', -index * self.slideWidth);
                                $(this).animate({
                                    opacity: 1
                                }, (self.speed / 2), function () {
                                    t.showSlide($(this), self.slidesOptions[index].img, self.slidesOptions[index].txt);
                                })
                            });
                    } 
                }
            }
        },

        slideRight: function (container) {
            var activeControl = container.objects.nav.find('li.active'),
                prev = activeControl.prev(),
                index = parseInt(prev.attr('data-slide-index'), 10);

            if(prev.length <= 0 && container.circular === true) {
                prev = container.objects.nav.find('li:last-child');
                index = container.objects.slides.length - 1;
            } else if (prev.length <= 0) {
                return false
            }

            if (container.autoRotate) {
                clearInterval(container.rotate);
                container.rotate = setInterval(function () {
                    this.slideLeft(container);
                }.bind(this), container.autoDelay);
            }

            this.goToSlide.call(container, index, prev, this);
        },

        slideLeft: function (container) {
            var activeControl = container.objects.nav.find('li.active'),
                next = activeControl.next(),
                index = parseInt(next.attr('data-slide-index'), 10);

            if(next.length <= 0 && container.circular === true) {
                next = container.objects.nav.find('li:first-child');
                index = 0;
            } else if (next.length <= 0) {
                return false
            }
            
            if (container.autoRotate) {
                clearInterval(container.rotate);
                container.rotate = setInterval(function () {
                    this.slideLeft(container);
                }.bind(this), container.autoDelay);
            }

            this.goToSlide.call(container, index, next, this);
        },

        hideSlide: function (slide, img, text) {
            var animSize = 5,
                opacity = 0;

            if (img.container.length > 0) {
                this.setSlidePosition(img.animation, img.container, animSize, opacity);
            }

            if (text.container.length > 0) {
                this.setSlidePosition(text.animation, text.container, animSize, opacity);
            }
        },

        showSlide: function (slide, img, text) {
            var animSize = 0,
                opacity = 1;

            if (img.container.length > 0) {
                this.setSlidePosition(img.animation, img.container, animSize, opacity);
            }

            if (text.container.length > 0) {
                this.setSlidePosition(text.animation, text.container, animSize, opacity);
            }
        },

        setSlidePosition: function (pos, container, animSize, opacity) {
            container.css('opacity', opacity);

            switch (pos) {
                case 0:
                    this.cssTranslate(container, -animSize, 0);
                    break;
                case 1:
                    this.cssTranslate(container, 0, -animSize);
                    break;
                case 2: 
                    this.cssTranslate(container, animSize, 0);
                    break;
                case 3: 
                    this.cssTranslate(container, 0, animSize);
                    break;
                default:
                    this.cssTranslate(container, 0, 0);
            }
        },

        cssTranslate: function (obj, x, y) {
            obj.css({
                '-ms-transform': 'translate(' + x + 'em, ' + y + 'em)',
                '-webkit-transform': 'translate(' + x + 'em, ' + y + 'em)',
                'transform': 'translate(' + x + 'em, ' + y + 'em)'
            })
        },

        autoRotate: function () {
            var t = this;

            $.each(this.options.container, function () {
                var self;

                if (this.objects.slides.length > 1) {
                    self = this;
                    if (this.autoRotate) {
                        this.rotate = setInterval(function () {
                            t.slideLeft(self);
                        }, this.autoDelay);
                    }
                }
            });
        }
    });
})();