;(function (undefined) {
	Shop.Address.include({
        options: {}, // no defaults!

        urls: {
            getRegions: '/console/countries/getregions/id/{id}',
            getSubregions: '/console/countries/getsubregions/id/{id}'
        },

        elements: {},

        initialize: function (options) {
            if (!!options) {
                this.options = options;
            }

            this.options.stateSelect.parents('tr, .state').addClass('hide-state');
            if (this.options.countrySelect.find('[data-region="1"]').length > 0) { // assuming there are some regions
                this.regionHandler();
            } else {
                this.options.stateSelect.val('');
            }

            this.elements = {};
        },

        regionHandler: function () {
            var self;

            self = this;
            this.options.countrySelect.on('change', function () {
                var request;
                var countryId;

                if (!parseInt($(this).find('option:selected').attr('data-region'), 10)) {
                    if (self.elements.$stateSelect && self.elements.$stateSelect.length > 0) {
                        self.elements.$stateSelect.addClass('none').attr('disabled', true);

                        if (self.elements.em) {
                            self.elements.em.hide();
                        }
                    }

                    if (self.elements.$citySelect && self.elements.$citySelect.length > 0) {
                        self.elements.$citySelect.addClass('none').attr('disabled', true);
                    }

                    self.options.citySelect.removeClass('none').attr('disabled', false);
                    self.options.stateSelect.removeClass('none').attr('disabled', false).val('');
                    self.options.stateSelect.parents('tr, .state').addClass('hide-state');
                    return false;
                }
                
                countryId = parseInt($(this).val(), 10);
                if (isNaN(countryId)) {
                    countryId = parseInt($(this).find('option:selected').attr('data-value'), 10)
                }

                request = $.ajax({
                    url: self.parent.substitute(self.urls.getRegions, {id: countryId}),
                    method: 'post',
                    async: true,
                    cache: false
                });

                request.success(function (regions) {
                    if (regions.regions.length > 0) {
                        self.options.stateSelect.parents('tr, .state').removeClass('hide-state');

                        if (!self.elements.$stateSelect) {
                            self.elements.$stateSelect = $('<select />', {
                                'name': self.options.stateSelect.attr('name'),
                                'id': self.options.stateSelect.attr('id')
                            }).insertBefore(self.options.stateSelect)
                        }

                        self.elements.$stateSelect.attr('data-selected', self.options.stateSelect.val());
                        self.fill(regions.regions, self.elements.$stateSelect);
                        self.elements.$stateSelect.removeClass('none').attr('disabled', false);
                        self.options.stateSelect.addClass('none').attr('disabled', true);

                        if (!self.elements.em) {
                            self.elements.em = $('<em />', {
                                'class': 'color',
                                'text': '*'  
                            }).prependTo(self.elements.$stateSelect.parents('tr').find('.label label'));
                        } else {
                            self.elements.em.show();
                        }

                        if (regions.subregions) {
                            self.subregionsHandler();
                            self.elements.$stateSelect.trigger('change');
                        } else {
                            if (self.elements.$citySelect) {
                                self.elements.$citySelect.addClass('none').attr('disabled', true);
                            }
                            self.options.citySelect.removeClass('none').attr('disabled', false);
                        }
                    } else {
                        self.elements.$stateSelect.addClass('none').attr('disabled', true);
                        self.options.stateSelect.removeClass('none').attr('disabled', false);

                        if (self.elements.em) {
                            self.elements.em.hide();
                        }
                    }
                });
            }).trigger('change');
        },

        subregionsHandler: function () {
            var self;

            self = this;
            this.elements.$stateSelect.off('change').on('change', function () {
                var regionId;
                var request;

                if (!$(this).find('option:selected').attr('data-region-id')) {
                    return false;
                }

                regionId = parseInt($(this).find('option:selected').attr('data-region-id'), 10);

                request = $.ajax({
                    url: self.parent.substitute(self.urls.getSubregions, {id: regionId}),
                    method: 'post',
                    async: true,
                    cache: false
                });

                request.success(function (subregions) {
                    if (subregions.subregions && subregions.subregions.length > 0) {
                        if (!self.elements.$citySelect) {
                            self.elements.$citySelect = $('<select />', {
                                'name': self.options.citySelect.attr('name'),
                                'id': self.options.citySelect.attr('id'),
                                'data-selected': self.options.citySelect.val()
                            }).insertBefore(self.options.citySelect)
                        }

                        self.fill(subregions.subregions, self.elements.$citySelect);
                        self.elements.$citySelect.removeClass('none').attr('disabled', false);
                        self.options.citySelect.addClass('none').attr('disabled', true);
                    } else {
                        if (self.elements.$citySelect) {
                            self.elements.$citySelect.addClass('none').attr('disabled', true);
                        }
                        self.options.citySelect.removeClass('none').attr('disabled', false);
                    }
                });
            })
        },

        fill: function (regions, $container) {
            $container.empty();

            regions.forEach(function (region) {
                $('<option />', {
                    'text': region.name,
                    'value': region.name,
                    'data-region-id': region.id,
                    'selected': ($container.attr('data-selected') === region.name ? true : false)
                }).appendTo($container);
            }.bind(this));
        }
    });
})();