(function(undefined) {
    Shop.PocztaPolskaBasketHandler.include({
        options: {
            paymentsMethods: null
        },

        classes: {
            disablePaymentMethod: 'pp-point-payment-disabled',
            deliveryDetailsPreview: 'pp-point-delivery-details',
            deliveryDetailsHeader: 'pp-point-delivery-details__header',
            deliveryDetailsContent: 'pp-point-delivery-details__content',
            paymentMethodOnlyPrepayment: 'pp-point-only-prepayment__label',
            paymentMethodOnlyPrepaymentDesc: 'pp-point-only-prepayment__label-desc',
            paymentMethodPointAlert: 'pp-point-payment-support-alert'
        },

        submitFromDeliver: false,
        submitFromPayment: true,

        selectedPointType: null,
        cashOnDeliveryIds: [],
        ppPointDeliverySnapshot: {},
        ppPointsDeliveriesId: [],

        initialize: function() {},

        initPickUpPoints: function(callback) {
            var self = this;

            if (!Shop.pocztapolska) {
                Shop.pocztapolska = new Shop.PocztaPolska({
                    googleMapsApikey: $('#pp-pick-up-points-apikey').val()
                });

                Shop.pocztapolska.callbacks.selected = callback;
            }

            this.ppPointsDeliveriesId = JSON.parse($('#pp-pick-up-points-delveries').val());
            this.ppPointsDeliveriesId.forEach(function(el) {
                self.ppPointDeliverySnapshot['shipping_' + el] = {
                    selectedPayment: null,
                    selectedPoint: null
                };
            });
        },

        getBasketInfo: function() {
            var self = this;
            var url = '/webapi/front/{lang}/basket/{currency}/';

            return $.ajax({
                method: 'GET',
                url: self.parent.substitute(url, {
                    lang: Shop.lang.name,
                    currency: Shop.values.currency
                }),

                error: function(err) {
                    console.error(err);
                }
            });
        },

        filterPrepaymentPayments: function(payments) {
            var filteredPayments = [];

            payments.forEach(function(payment) {
                if (payment.name === 'cod') {
                    filteredPayments.push(payment);
                }
            });

            return filteredPayments;
        },

        ppPointBlockPayment: function(evt) {
            evt.preventDefault();
            evt.stopPropagation();
        },

        ppTogglePayment: function($payment, disable) {
            if (disable) {
                $payment.addClass(this.classes.disablePaymentMethod);
                $payment.get(0).addEventListener('click', this.ppPointBlockPayment, true);
            } else {
                $payment.get(0).removeEventListener('click', this.ppPointBlockPayment, true);
                $payment.removeClass(this.classes.disablePaymentMethod);
            }
        },

        createPpBasicMarkup: function() {
            var self = this;
            var $ppPickupPoints = $('[data-pp-pick-up-points]');
            var $btnPickupPoint = $('<a />', {
                class: 'btn btn-red btn-right',
                id: 'btn-pp-pick-up-points',
                text: Shop.lang.pickupsPoints.select_pickup_point
            }).appendTo($ppPickupPoints);

            var $deliveryPreview = $('<div />', {
                class: this.classes.deliveryDetailsPreview
            }).insertBefore($ppPickupPoints);

            $('<h4 />', {
                class: this.classes.deliveryDetailsHeader,
                text: Shop.lang.pickupsPoints.selected_pickup_point_header
            }).appendTo($deliveryPreview);

            $('<div />', {
                class: this.classes.deliveryDetailsContent
            }).appendTo($deliveryPreview);

            $btnPickupPoint.on('click', function(evt) {
                var selectedDelivery;
                var selectedDeliveryDetails;
                var onlyPrepayment = false;
                var $pointListItem;

                if (!!self.cashOnDeliveryIds.length) {
                    self.cashOnDeliveryIds.forEach(function(cashOnDeliveryId) {
                        if ($('#payment_' + cashOnDeliveryId + '').is(':checked')) {
                            onlyPrepayment = true;
                        }
                    });
                }

                Shop.pocztapolska.show(onlyPrepayment);

                selectedDelivery = parseInt(
                    $('[name="shipping_id"]')
                        .filter(':checked')
                        .attr('value'),
                    10
                );

                selectedDeliveryDetails = self.ppPointDeliverySnapshot['shipping_' + selectedDelivery].selectedPoint;
                if (selectedDeliveryDetails) {
                    $pointListItem = $('[data-point-pni="' + selectedDeliveryDetails.id + '"]');
                    if (!!$pointListItem.length) {
                        $pointListItem.trigger('click');
                    } else {
                        self.parent.addEvent('PickUpPoints:view-fully-updated', function() {
                            $pointListItem = $('[data-point-pni="' + selectedDeliveryDetails.id + '"]');
                            if (!!$pointListItem.length) {
                                $pointListItem.trigger('click');
                            }

                            self.parent.removeEvent('PickUpPoints:view-fully-updated');
                        });
                    }
                } else {
                    Shop.pocztapolska.clearMapView();
                }
            });
        },

        updatePpPointObjectPoint: function(point, payment) {
            var $selectedDelivery = $('[name="shipping_id"]').filter(':checked');
            var selectedDeliveryValue = parseInt($selectedDelivery.attr('value'), 10);

            if (this.ppPointsDeliveriesId.indexOf(selectedDeliveryValue) !== -1) {
                if (point) {
                    this.ppPointDeliverySnapshot[$selectedDelivery.attr('id')].selectedPoint = point;
                }

                if (payment) {
                    this.ppPointDeliverySnapshot[$selectedDelivery.attr('id')].selectedPayment = payment;
                }

                localStorage.setItem('pickUpPointsSnapshot', this.parent.base64.encode(JSON.stringify(this.ppPointDeliverySnapshot)));
            }
        },

        createPpPointDeliveryMarkup: function(point, shortVersion) {
            var $markup = $(document.createDocumentFragment());

            if (point.name) {
                $('<p />', {
                    text: point.name
                }).appendTo($markup);
            }

            if (point.street) {
                $('<p />', {
                    text: point.street
                }).appendTo($markup);
            }

            if (point.zipCode || point.city) {
                $('<p />', {
                    text: (point.zipCode || '') + ' ' + (point.city || '')
                }).appendTo($markup);
            }

            if (point.description && !shortVersion) {
                point.description.split('#').forEach(function(item) {
                    $('<p />', {
                        text: item
                    }).appendTo($markup);
                });
            }

            return $markup;
        },

        updateSelectedPpPointMarkup: function(point) {
            var $pointDeliveryPreview;
            var $deliveryDetailsHeader;
            var $payment;
            var self = this;
            var $btnPickupPoint = $('#btn-pp-pick-up-points');

            $pointDeliveryPreview = $('.' + this.classes.deliveryDetailsContent);
            $deliveryDetailsHeader = $('.' + this.classes.deliveryDetailsHeader).addClass('none');
            $pointDeliveryPreview.empty();

            if (!!$('.' + this.classes.disablePaymentMethod).length) {
                this.cashOnDeliveryIds.forEach(function(cashOnDeliveryId) {
                    $payment = $('#payment_' + cashOnDeliveryId).parents('.payment');
                    self.ppTogglePayment($payment);
                });
            }

            if (point) {
                this.createPpPointDeliveryMarkup(point, true);
                $pointDeliveryPreview.append(this.createPpPointDeliveryMarkup(point, true));

                $deliveryDetailsHeader.removeClass('none');
                $btnPickupPoint.removeClass('btn btn-red btn-right').addClass('btn-change');
                $btnPickupPoint.text('(' + Shop.lang.pickupsPoints.change_pickup_point + ')');

                if (!!this.cashOnDeliveryIds.length && point.type !== 'POCZTA') {
                    this.cashOnDeliveryIds.forEach(function(cashOnDeliveryId) {
                        $payment = $('#payment_' + cashOnDeliveryId).parents('.payment');
                        self.ppTogglePayment($payment, true);
                    });
                }
            } else {
                $btnPickupPoint.addClass('btn btn-red btn-right').removeClass('btn-change');
                $btnPickupPoint.text(Shop.lang.pickupsPoints.select_pickup_point);
            }
        },

        updateSelectedPpPointDeliveryMethod: function(paymentId) {
            $('#payment_' + paymentId).prop('checked', true);
        },

        setPpHiddenPoint: function(point, $form) {
            var inputName = 'pp-point-id';
            var $oldInput = $('[name="' + inputName + '"]');

            if ($oldInput.length > 0) {
                $oldInput.remove();
            }

            $form.append(
                $('<input />', {
                    type: 'hidden',
                    name: inputName,
                    id: inputName,
                    value: this.parent.base64.encode(JSON.stringify(point))
                })
            );
        },

        pickUpPointsStepDelivery: function($form) {
            var self = this;
            var selectedDeliverySnapshot;
            var ppPointSessionValue;
            var $radioDelivery = $('[name="shipping_id"]');
            var $radioPayment = $('[name="payment_id"]');
            var $btnSubmit = $form.find('button[name="button2"]');
            var $btnPickupPoint;
            var $ppPointSession;

            this.initPickUpPoints(function(context, point) {
                self.updateSelectedPpPointMarkup(point);
                self.updatePpPointObjectPoint(
                    point,
                    parseInt(
                        $('[name="payment_id"]')
                            .filter(':checked')
                            .attr('value'),
                        10
                    )
                );
                self.setPpHiddenPoint(point, $form);

                localStorage.setItem('pickUpPointsSnapshot', self.parent.base64.encode(JSON.stringify(self.ppPointDeliverySnapshot)));

                if (self.submitFromDeliver && self.submitFromPayment) {
                    $btnSubmit.trigger('click');
                }
            });

            this.getBasketInfo()
                .then(function(response) {
                    var point;
                    var $prepayment;
                    var payments;

                    payments = self.filterPrepaymentPayments(response.payments);

                    if (!!payments.length) {
                        payments.forEach(function(payment) {
                            self.cashOnDeliveryIds.push(payment.payment_id);

                            $prepayment = $('#payment_' + payment.payment_id);
                            if (!!$prepayment.length) {
                                $prepayment.parents('.name').append(
                                    $('<span />', {
                                        text: Shop.lang.pickupsPoints.only_prepayment,
                                        class: self.classes.paymentMethodOnlyPrepayment
                                    })
                                );

                                $prepayment.parents('.name').append(
                                    $('<span />', {
                                        text: Shop.lang.pickupsPoints.only_prepayment_label,
                                        class: self.classes.paymentMethodOnlyPrepayment + ' ' + self.classes.paymentMethodOnlyPrepaymentDesc
                                    })
                                );
                            }
                        });
                    }

                    self.createPpBasicMarkup();

                    $ppPointSession = $('#pp-pick-up-point');

                    if (!!$ppPointSession.length) {
                        ppPointSessionValue = JSON.parse(self.parent.base64.decode($ppPointSession.val()));
                    }

                    if (!!ppPointSessionValue) {
                        self.updatePpPointObjectPoint(
                            ppPointSessionValue,
                            parseInt(
                                $('[name="payment_id"]')
                                    .filter(':checked')
                                    .attr('value'),
                                10
                            )
                        );
                        self.setPpHiddenPoint(ppPointSessionValue, $form);
                    } else if (localStorage.getItem('pickUpPointsSnapshot')) {
                        self.ppPointDeliverySnapshot = jQuery.extend(
                            self.ppPointDeliverySnapshot,
                            JSON.parse(self.parent.base64.decode(localStorage.getItem('pickUpPointsSnapshot')))
                        );
                    }

                    $btnSubmit.on('mousedown', function(evt) {
                        var onlyPrepayment = false;

                        if (
                            !JSON.parse(self.parent.base64.decode($('#pp-point-id').val())) &&
                            self.ppPointsDeliveriesId.indexOf(parseInt($radioDelivery.filter(':checked').val(), 10)) !== -1
                        ) {
                            evt.preventDefault();

                            self.submitFromDeliver = true;
                            Shop.pocztapolska.callbacks.close = function(context) {
                                self.submitFromDeliver = false;
                            };

                            if (!!self.cashOnDeliveryIds.length) {
                                self.cashOnDeliveryIds.forEach(function(cashOnDeliveryId) {
                                    if ($('#payment_' + cashOnDeliveryId + '').is(':checked')) {
                                        onlyPrepayment = true;
                                    }
                                });
                            }

                            Shop.pocztapolska.show(onlyPrepayment);
                        }
                    });

                    $btnPickupPoint = $('#btn-pp-pick-up-points');
                    $radioDelivery.on('change', function(evt) {
                        var $deliveryPreview;
                        var $disabledPaymentMethods;
                        var ppPointDeliverySnapshot;

                        $deliveryPreview = $('.' + self.classes.deliveryDetailsPreview);
                        if (self.ppPointsDeliveriesId.indexOf(parseInt($(this).val(), 10)) !== -1) {
                            $btnPickupPoint.removeClass('none');

                            if (!!$deliveryPreview.length) {
                                $deliveryPreview.removeClass('none');
                            }

                            ppPointDeliverySnapshot = self.ppPointDeliverySnapshot[$(this).attr('id')];

                            if (ppPointDeliverySnapshot) {
                                self.updateSelectedPpPointMarkup(ppPointDeliverySnapshot.selectedPoint);

                                if (ppPointDeliverySnapshot.selectedPoint) {
                                    self.setPpHiddenPoint(ppPointDeliverySnapshot.selectedPoint, $form);
                                } else {
                                    self.setPpHiddenPoint(null, $form);
                                }

                                if (ppPointDeliverySnapshot.selectedPayment) {
                                    self.updateSelectedPpPointDeliveryMethod(ppPointDeliverySnapshot.selectedPayment);
                                }
                            }
                        } else {
                            $btnPickupPoint.addClass('none');
                            self.setPpHiddenPoint(null, $form);

                            if (!!$deliveryPreview.length) {
                                $deliveryPreview.addClass('none');
                            }

                            $disabledPaymentMethods = $('.' + self.classes.disablePaymentMethod);
                            if (!!$disabledPaymentMethods.length) {
                                $disabledPaymentMethods.each(function(index, payment) {
                                    self.ppTogglePayment($(payment));
                                });
                            }
                        }

                        localStorage.setItem('pickUpPointsSnapshot', self.parent.base64.encode(JSON.stringify(self.ppPointDeliverySnapshot)));
                    });

                    $radioPayment.on('change', function() {
                        self.updatePpPointObjectPoint(undefined, parseInt($(this).attr('value'), 10));
                    });

                    $radioDelivery.filter(':checked').trigger('change');
                    self.updatePpPointObjectPoint(
                        undefined,
                        parseInt(
                            $('[name="payment_id"]')
                                .filter(':checked')
                                .attr('value'),
                            10
                        )
                    );
                })
                .fail(function(err) {
                    console.error(err);
                });
        },

        fillDeliveryFromPpPoint: function(point) {
            var inputName = 'pp-pick-up-point';
            var $deliveryDetails = $('.address.delivery .delivery-details');
            var $oldInput = $('[name="' + inputName + '"]');
            var $form = $('form[action*="/basket/step3"]');

            $deliveryDetails.empty();

            this.createPpPointDeliveryMarkup(point).appendTo($deliveryDetails);

            if ($oldInput.length > 0) {
                $oldInput.remove();
            }

            $form.append(
                $('<input />', {
                    type: 'hidden',
                    name: inputName,
                    id: inputName,
                    value: this.parent.base64.encode(JSON.stringify(point))
                })
            );

            this.setPpHiddenPoint(point, $form);
        },

        pickUpPointsStep3: function() {
            var self = this;
            var ppPickUpPoints = JSON.parse($('#pp-pick-up-points-delveries').val());

            localStorage.removeItem('pickUpPointsSnapshot');

            this.getBasketInfo()
                .then(function(response) {
                    var payments;

                    payments = self.filterPrepaymentPayments(response.payments);
                    response.shippings.forEach(function(shipping) {
                        var point;

                        if (shipping.selected && ppPickUpPoints.indexOf(shipping.shipping_id) !== -1) {
                            var $btnPp = $('<a />', {
                                id: 'btn-pp-pick-up-points',
                                class: 'btn-change',
                                text: '(' + Shop.lang.pickupsPoints.change_pickup_point + ')'
                            }).appendTo($('[data-pp-pick-up-points]'));

                            point = JSON.parse(self.parent.base64.decode($('#pp-pick-up-point').val()));
                            if (point) {
                                self.fillDeliveryFromPpPoint(JSON.parse(self.parent.base64.decode($('#pp-pick-up-point').val())));
                            }

                            self.initPickUpPoints(function(context, point) {
                                self.fillDeliveryFromPpPoint(point);
                            });

                            $btnPp.on('click', function(evt) {
                                var onlyPrepayment = false;

                                payments.forEach(function(payment) {
                                    if (payment && payment.selected) {
                                        onlyPrepayment = true;
                                    }
                                });

                                Shop.pocztapolska.show(onlyPrepayment);
                            });
                        }
                    });
                })
                .fail(function(err) {
                    console.error(err);
                });
        }
    });
})();
