;(function (undefined) {
    Shop.BundleStockHandler.include({
        options: {
            BundleStockContainer: null,
            $el: null,
            $view: null,
            id: null,
        },

        elements: {
            $stockContainers: null,
            $stockControls: null,
            $stockDropdowns: null
        },

        productId: null,
        stockId: null,
        product: null,
        isSelected: null,

        stockOptions: {},

        downloadedStocks: null,
        downloadedPath: null,

        events: {
            changeStock: null
        },

        initialize: function (options) {
            var self;
            var $el;
            var $stockChangeControl;
            var $stockClickControl;
            var stockObjData;

            if (!!options) {
                this.options = options;
            }

            this.virtOptions = {};
            $el = this.options.$el;

            this.productId = $el.data('stocks');
            this.elements = {};
            this.elements.$stockContainers = $el.find('.option-stock');
            this.elements.$stockControls = this.elements.$stockContainers.find('[data-variant-id]');
            this.elements.$stockDropdowns = $el.find('.stock-label');

            self = this;
            $stockChangeControl = this.elements.$stockControls.filter('input, select');
            $stockClickControl = this.elements.$stockControls.filter('[data-stock-option]');

            this.events.changeStock = (function () {
                var $self;
                var variantId;
                var variantValue;
                var $selectedValue;
                var stockInArray;
                var stockObj;
                var ind;

                $self = $(this);
                variantValue = $self.val() || $self.data('variantValue');
                variantId = parseInt($self.data('variantId'), 10);

                self.virtOptions[variantId] = variantValue;

                if ($self.is(':checkbox') && this.checked !== true) {
                    self.virtOptions[variantId] = null;
                }

                $selectedValue = $self.parents('.stock-control').find('.stock-label .selected-value');

                if ($self.is(':text')) {
                    if (variantValue) {
                        $selectedValue.text(variantValue);
                        $self.parents('.stock-control').addClass('stock-control-selected');
                    } else {
                        $selectedValue.text('');
                        $self.parents('.stock-control').removeClass('stock-control-selected');
                    }
                } else if ($self.is(':checkbox')) {
                    if (this.checked === true) {
                        $self.parents('.stock-control').addClass('stock-control-selected stock-control-selected-checkbox');
                    } else {
                        $self.parents('.stock-control').removeClass('stock-control-selected stock-control-selected-checkbox');
                    }
                } else if ($self.val() || $self.is('span')) {
                    $self.parents('.stock-control').addClass('stock-control-selected');
                    $self.parent().children().removeClass('product-option_color_selected').removeClass('color-selected');
                    $selectedValue.text($self.data('variantName'));
                    if ($self.data('color')) {
                        $selectedValue.css('border-color', $self.data('color')).addClass('color-selected');
                    }
                    $self.addClass('product-option_color_selected');
                }

                if (self.stockId) {
                    self.getStock(Object.assign({}, self.stockOptions, self.virtOptions), variantId).then(this.updateStock.bind(self), function () {
                        // err
                    });
                } else {
                    self.getStock(self.virtOptions, variantId).then(self.updateStock.bind(self), function () {
                        // err
                    });
                }

                self.isSelected = self.isValid();
                stockInArray = self.parent.findObjInArrayByParamValue(self.options.BundleStockContainer.bundle.selectedProducts, 'id', self.options.id);

                if (self.isSelected) {
                    stockObj = {
                        id: self.options.id,
                        stockId: self.stockId || self.options.stockId,
                        stock_id: self.stockId || self.options.stockId,
                        options: self.virtOptions,
                        quantity: parseFloat(self.options.$view.find('[data-quantity]').text())
                    };

                    if (stockInArray.id !== stockObj.id) {
                        self.inArrayPosition = self.options.BundleStockContainer.bundle.selectedProducts.length;
                        self.options.BundleStockContainer.bundle.selectedProducts.push(stockObj);
                    } else {
                        ind = self.options.BundleStockContainer.bundle.selectedProducts.indexOf(stockInArray);
                        self.options.BundleStockContainer.bundle.selectedProducts[ind] = stockObj;
                    }

                    $self.parents('.product').addClass('bundle-product-selected');
                } else {
                    if (stockInArray) {
                        self.options.BundleStockContainer.bundle.selectedProducts.splice(self.options.BundleStockContainer.bundle.selectedProducts.indexOf(stockInArray), 1);
                    }
                    $self.parents('.product').removeClass('bundle-product-selected');
                }

                self.options.BundleStockContainer.changeBuyAction();
            });

            this.elements.$stockDropdowns.on('click', function () {
                var me;
                var $parent;

                me = this;
                $parent = $(this).parent();

                if ($parent.hasClass('stock-control-open')) {
                    $parent.removeClass('stock-control-open');
                } else {
                    $parent.addClass('stock-control-open');
                }

                self.elements.$stockDropdowns.filter(function () {
                    if (this !== me) {
                        $(this).parent().removeClass('stock-control-open');
                    }
                });
            });

            $stockChangeControl.on('change', this.events.changeStock);
            $stockClickControl.on('click', this.events.changeStock);

            this.isSelected = this.isValid();

            if (this.isSelected) {
                stockObjData = {
                    id: this.options.id,
                    stockId: this.stockId || this.options.stockId,
                    stock_id: this.stockId || this.options.stockId,
                    quantity: parseFloat(this.options.$view.find('[data-quantity]').text())
                };

                if (!!Object.keys(this.virtOptions).length) {
                    stockObjData.options = this.virtOptions;
                }

                this.options.BundleStockContainer.bundle.selectedProducts.push(stockObjData);
            } else {
                $el.parents('.product').removeClass('bundle-product-selected');
            }
        },

        getStock: function (options, modVoption) {
            var url;
            var stringifiedOptions;

            url = 'webapi/front/{lang}/products/{currency}/{id}/stock/options/{stockOptions}/';
            stringifiedOptions = JSON.stringify(options);

            if (modVoption) {
                url += 'mod-voption/{modVoption}';
            }

            url = this.parent.url(url);
            url = this.parent.substitute(url, {
                lang: Shop.lang.name,
                currency: Shop.values.currency,
                id: this.productId,
                stockOptions: stringifiedOptions,
                modVoption: modVoption
            });

            return $.ajax({
                url: url,
                method: 'get'
            });
        },

        updateStock: function (stock, statusText) {
            if (statusText === 'success') {
                var $img;
                var src;
                var newSrc;
                this.elements.$stockDropdowns.parent().removeClass('stock-control-open');

                $img = this.options.$view.find('a.details img');
                src = $img.attr('src');
                newSrc = src.replace(/(productGfx_)(.+)(\.jpg)/g, '$1' + stock.image + '$3');

                $img.attr('src', newSrc);
            }
        },

        isValid: function () {
            var self;
            var selectedVariants;
            var requiredVariants;
            var $required;

            $required = this.elements.$stockContainers.filter('.option-required');
            if (!$required.length) {
                return true;
            }

            requiredVariants = [];
            $required.each(function () {
                $(this).find('input, select, [data-stock-option]').each(function () {
                    var variantId;

                    variantId = $(this).data('variantId') + '';
                    if (requiredVariants.indexOf(variantId) < 0) {
                        requiredVariants.push(variantId);
                    }
                });
            });

            self = this;
            selectedVariants = Object.keys(this.virtOptions);

            return requiredVariants.every(function (el) {
                return selectedVariants.indexOf(el) >= 0 && self.virtOptions[el];
            });
        }
    });
})();