;(function (undefined) {
	Shop.AjaxLayer.include({
        options : {
            selector : '.ajaxlayer',
            box_class : 'mask_box',
            close_selector : '.close_mask'
        },

        mask : null,
        div : null,
        modal: null,
        event : null,

        initialize: function(options) {
            this.constructor.setOptions(options);
            this.modal = new Shop.Modal();

            $(this.options.selector).each(function(that){
                that.assign(this);
            }, [this]);
        },

        assign: function(el) {
            el._class = this;

            $(el).off('mousedown').on('mousedown', function (e) {
                e.stopPropagation();
                e.preventDefault();

                this._class.load($(this), $(this).attr('data-href'));
            });
        },

        load: function(container, url) {
            var that = this;
            var shopClass = this.parent;

            var req = $.ajax({
                url: url,
                async: true,
                cache: false,
                method: 'get'
            }).error(function(xhr){
                if(0 === xhr.status) return;
                shopClass.alert(Shop.lang.ajaxlayer.error);
                that.close();
            }).success(function(responseHTML, response, responseTree){
                if(Shop.exist(responseTree) && Shop.exist(responseTree.responseText) && Shop.exist(responseHTML)) {
                    that.loaded(responseHTML);
                } else {
                    shopClass.alert(Shop.lang.ajaxlayer.error);
                    that.close();
                }
            });
        },

        loaded: function(html) {
            /** 
             * To add title to modal, returned makrup must contain data-header-title attribute
            */
            var $container = $('<div />').append($(html));
            var $title = $container.find('[data-header-title]');

            this.modal.options.content = $container;
            this.modal.options.footer = null;
            this.modal.options.header = $title.length > 0 ? $title.attr('data-header-title') : null;
            this.modal.createModal();
            this.afterload();
        },

        afterload: function() {
            var self;
            var urlF;
            var nameF;
            var mailF;
            var questionF;
            var that = this;
            var container = this.modal.object.body;
            var cb;
            var recaptchaToken;

            self = this;
            cb = (function () {
                $.ajax({
                    url: urlF,
                    method: 'post',
                    data: {
                        name: nameF,
                        mail: mailF,
                        question: questionF,
                        'g-recaptcha-response': recaptchaToken
                    }
                }).error(function () {
                    that.failure()
                }).success(function (html) {
                    that.modal.destroyModal();
                    that.loaded(html);
                });
            });
           
            container.find('form').each(function(){
                var recaptchaId;
                var recaptchaMode;

                if (!!$(this).find('#ask-recaptcha').length) {
                    if (!window.grecaptcha) {
                        var captchaTest;
                        Shop.Recaptcha = new Shop.Recaptcha();
                        
                        captchaTest = setInterval(function () {
                            if (window.grecaptcha && window.grecaptcha.render) {
                                captchaRender();
                                clearInterval(captchaTest);
                            }
                        }, 50);
                    }
                }

                if (window.grecaptcha && window.grecaptcha.render) {
                    captchaRender();
                }

                $(this).on('submit', function (ev) {
                    urlF = $(this).attr('action');
                    nameF = $(this).find('input[name="name"]').val();
                    mailF = $(this).find('input[name="mail"]').val();
                    questionF = $(this).find('textarea[name="question"]').val();

                    ev.preventDefault();
                    ev.stopPropagation();

                    if (window.grecaptcha && recaptchaId !== undefined) {
                        if (!grecaptcha.getResponse(recaptchaId) && recaptchaMode !== 'invisible') {
                            self.parent.alert(Shop.lang.common.recaptchaRequired);
                            return false;
                        }
                    }

                    if (recaptchaMode === 'invisible') {
                        grecaptcha.execute(recaptchaId);
                    } else {
                        cb();
                    }

                    return false;
                })

                function captchaRender () {
                    recaptchaMode = $('#ask-recaptcha').data('recaptchaMode') ? 'invisible' : 'normal';

                    recaptchaId = grecaptcha.render('ask-recaptcha', {
                        'sitekey' : $('#ask-recaptcha').data('recaptchaKey'),
                        'theme' : 'light',
                        'size': recaptchaMode,
                        'callback': function (token) {
                            recaptchaToken = token;

                            if (recaptchaMode === 'invisible') {
                                cb();
                            }
                        }
                    });
                }
            });
        },

        failure: function() {
            this.parent.alert(Shop.lang.ajaxlayer.error);
        }
    });
})();