;(function (undefined) {
	Shop.AddressContainer.include({
        initialize: function (options) {
            $('.address-handler').each(function () {
                var diff;

                diff = '';
                if ($(this).attr('data-diff-address')) {
                    diff = '2';
                }

                if (!this.Address) {
                    this.Address = new Shop.Address({
                        countrySelect: $(this).find('#input_country' + diff),
                        stateSelect: $(this).find('#input_state' + diff),
                        citySelect: $(this).find('#input_city' + diff)
                    })
                }
            });
        }
    });
})();