;
(function (undefined) {
    Shop.Modal.include({
        options: {
            autoShow: false, // TODO: not yet supported
            showMask: true,

            position: 'center',
            positionType: 'fixed',
            offset: 20,
            mobileBreakPoint: 600,

            header: null,
            content: null,
            footer: null,

            contentMaxHeight: null,
            contentMaxWidth: null,

            onPostShow: null
        },

        object: {
            container: null,
            header: null,
            body: null,
            footer: null,

            mask: null,

            position: {}
        },

        params: {
            width: 0,
            height: 0,
            leftPos: 0,
            topPos: 0,

            scroll: 0,
            windowWidth: 0,
            windowHeight: 0
        },

        callbacks: {
            close: null,
            onPostShow: null,
            onReposition: null
        },

        _isCreated: false,
        _isMaskCreated: false,
        _type: 'basic',

        initialize: function (options) {
            this.constructor.setOptions(options);
            var shopClass = this.parent;

            this.object = {
                    container: null,
                    header: null,
                    body: null,
                    footer: null,

                    mask: null,

                    position: {}
                },

                this.params = {
                    width: 0,
                    height: 0,
                    leftPos: 0,
                    topPos: 0,

                    scroll: 0,
                    windowWidth: 0,
                    windowHeight: 0
                },

                this._isCreated = false;
            this._isMaskCreated = false;
        },

        createModal: function () {
            if (this._isCreated) {
                this.showModal();

                if (this.options.showMask) {
                    this._showMask();
                }

                return false;
            }

            if (this.options.showMask && !this._isMaskCreated) {
                this._createMask();
            }

            this.object.container = $('<div class="modal modal-not-visible" />').appendTo(document.body);
            this.object.header = $('<div class="modal-header" />').appendTo(this.object.container);
            this.object.body = $('<div class="modal-body" />').appendTo(this.object.container);
            this.object.footer = $('<div class="modal-footer" />').appendTo(this.object.container);

            var h3 = $('<h3 />').appendTo(this.object.header);
            var closeButton = $('<div class="modal-close" />').appendTo(this.object.header);

            closeButton.html('<span class="modal-close-txt">' + Shop.lang.common.close + '</span>').on('click', this, function (e) {
                e.data.hideModal();
                e.data._hideMask();
            });

            this.loadContent();
            this._isCreated = true;

            var width = $(window).width();
            var height = $(window).height();
            var resize = this.parent.debounce(function () {
                if (width !== $(window).width() || (height !== $(window).height())) {
                    this._calculatePosition();
                    this.reposition();

                    width = $(window).width();
                    height = $(window).height();
                }
            }.bind(this), 200);

            $(window).on('resize', resize);
        },

        showModal: function () {
            if (this.params.scroll !== $(window).scrollTop()) {
                this._calculatePosition();
            }

            this.reposition();

            this.object.container.removeClass('modal-hidden');

            setTimeout(function () {
                this.object.container.removeClass('modal-not-visible').addClass('modal-visible');
            }.bind(this), 400);

            if (typeof (this.callbacks.onPostShow) === 'function') {
                this.callbacks.onPostShow.call(e.data, arguments);
            }
        },

        hideModal: function () {
            this.object.container.removeClass('modal-visible').addClass('modal-not-visible');
            setTimeout(function () {
                this.object.container.addClass('modal-hidden');
            }.bind(this), 400);

            if (typeof (this.callbacks.close) === 'function') {
                this.callbacks.close.call(this);
            }
        },

        destroyModal: function () {
            this.object.mask.remove();
            this.object.container.remove();
            this._isCreated = false;
        },

        loadContent: function () {
            if (this.options.header) {
                this.object.header.find('h3').html(this.options.header);
            }

            this.object.body.html(this.options.content);

            this.object.body.css({
                maxWidth: this.options.contentMaxWidth ? this.options.contentMaxWidth : 'auto',
                maxHeight: this.options.contentMaxHeight ? this.options.contentMaxHeight : 'auto'
            });

            if (this.options.footer) {
                this.object.footer.html(this.options.footer);
            } else {
                this.object.footer.remove();
            }

            this._calculatePosition();
            this.showModal();

            setTimeout(this.showModal.bind(this), (this._isMaskCreated ? 200 : 0));
        },

        _calculatePosition: function () {
            var mPos = {};
            var position = this.options.position.toLowerCase();
            var isMobile;
            var isFullView;
            var widthScale;
            var offsetTop;

            this.object.container.css('width', 'auto');
            this.params.width = this.object.container.outerWidth();
            this.params.height = this.object.container.outerHeight();
            this.params.leftPos = this.object.container.offset().left;
            this.params.topPos = this.object.container.offset().top;

            this.params.scroll = $(window).scrollTop();
            this.params.windowWidth = $(window).width();
            this.params.windowHeight = $(window).height();

            isMobile = this.params.windowWidth < this.options.mobileBreakPoint ? true : false;
            isFullView = isMobile && this._type !== 'alert';

            if (this.params.windowWidth < 768) {
                widthScale = isFullView ? 100 : 90;

                this.params.width = (this.params.windowWidth * widthScale) / 100;
                this.object.container.width(this.params.width);

                if (isFullView) {
                    this.params.height = this.params.height < this.params.windowHeight ? this.params.windowHeight : this.params.height;
                    this.object.container.height(this.params.height);
                }
            }

            if (this.options.positionType === 'fixed') {
                this.object.container.css('position', 'fixed');

                if (this.params.height > this.params.windowHeight) {
                    this.options.positionType = 'absolute';
                    this._calculatePosition();
                    return false;
                }

                if (position.indexOf('center') !== -1) {
                    mPos.top = (this.params.windowHeight / 2) - (this.params.height / 2);
                    mPos.left = (this.params.windowWidth / 2) - (this.params.width / 2);
                }
            } else {
                offsetTop = isFullView ? 0 : this.options.offset;

                this.object.container.css('position', 'absolute');

                if (this.params.height < this.params.windowHeight) {
                    this.options.positionType = 'fixed';
                    this._calculatePosition();
                    return false;
                }

                if (position.indexOf('center') !== -1) {
                    mPos.top = Math.max(this.params.scroll + offsetTop, 0);
                    mPos.left = (this.params.windowWidth / 2) - (this.params.width / 2);
                }

                if (position.indexOf('top') !== -1) {
                    mPos.top = this.params.scroll + offsetTop;
                }
            }

            this.object.position = mPos;
            return mPos;
        },

        reposition: function () {
            if (typeof this.callbacks.onReposition === 'function') {
                this.callbacks.onReposition(this.object.position);
            }

            this.object.container.css(this.object.position);
        },

        _createMask: function () {
            this.object.mask = $('<div class="mask" />').hide();
            this.object.mask.appendTo(document.body);
            this._showMask();
        },

        _showMask: function () {
            this.object.mask.show();
        },

        _hideMask: function () {
            this.object.mask.hide();
        },

        alert: function (alert, title) {
            this._type = 'alert';

            if (title) {
                this.options.header = title;
            } else {
                this.options.header = Shop.lang.common.alert;
            }

            this.options.content = alert;
            this.options.footer = $('<a />', {
                'class': 'btn btn-red',
                'text': 'OK'
            }).on('click', this, function (e) {
                e.data.hideModal();
                e.data._hideMask();
            });

            this.createModal();
            this.object.container.addClass('modal-alert');
            this.object.header.find('.modal-close').remove();
        }
    });
})();