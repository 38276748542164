import fastdom from '../../libraries/fastdom.min';

;
(function (undefined) {
    Shop.Tabs.include({
        options: {
            elements: {
                $container: null,
                $tabsContainer: null
            },

            selectors: {
                container: '.product-modules.active',
                boxHeader: '.boxhead',
                boxContainer: '.innerbox',
                tabContainer: 'tab-container',
                tabs: 'product-tabs',
                tabClass: 'product-tab',
                column: '.innermain .centercol',
                boxes: '.centercol > .box:not(#box_productfull)',
                boxesTop: '.centercol > [data-boxes-side="0"]',
                boxesBottom: '.centercol > [data-boxes-side="2"]',
                facebookComments: '#box_facebookchat'
            }
        },

        initialize: function (options) {
            var self;

            self = this;
            this.constructor.setOptions(options);

            fastdom.mutate(function () {
                self.createDOM();
            });
        },

        createDOM: function () {
            var self;
            var nav;
            var tabs;
            var $tabContainer;
            var $navContainer;
            var $navUl;
            var $facebookComments;

            this.options.elements.$container = $(this.options.selectors.container);

            this.options.elements.tabsContainer = $tabContainer = $('<div />', {
                'class': this.options.selectors.tabContainer
            });

            $navContainer = $('<nav />').appendTo($tabContainer);
            $navUl = $('<ul />').appendTo($navContainer);

            $('<div />', {
                'class': this.options.selectors.tabs
            }).appendTo($tabContainer);

            nav = [];
            tabs = [];
            self = this;

            this.options.elements.$container.children().each(function () {
                nav.push($(this).find(self.options.selectors.boxHeader));
                tabs.push($(this).find(self.options.selectors.boxContainer));
            });

            nav.forEach(function ($el, index) {
                $el.appendTo($navUl).wrap($('<li />', {
                    'class': $el.context.getAttribute('id')
                }));

                if (index === 0) {
                    $el.addClass('active');
                }
            });

            $navUl.find('> li').on('click', function () {
                var $el;

                $el = $(this);
                fastdom.mutate(function () {
                    $navUl.find('> li > div').removeClass('active');
                    $el.find('> div').addClass('active');
                    self.switchTab($el.attr('class'));
                });
            });

            tabs.forEach(function ($el, index) {
                $el.appendTo($tabContainer.find('.' + self.options.selectors.tabs)).wrap($('<div />', {
                    'class': self.options.selectors.tabClass + (index !== 0 ? ' none' : ''),
                    'data-tab': $el.context.getAttribute('id')
                }));
            });

            this.options.elements.$container.remove();
            $tabContainer.appendTo(this.options.selectors.column);
            $(this.options.selectors.boxesBottom).appendTo(this.options.selectors.column);
            $(this.options.selectors.boxesTop).prependTo(this.options.selectors.column);

            $facebookComments = $(this.options.selectors.facebookComments);

            if ($facebookComments.length > 0) {
                $facebookComments.appendTo(this.options.selectors.column);
            }

            return this;
        },

        switchTab: function (tabName) {
            this.options.elements.tabsContainer.find('[data-tab]').addClass('none');
            this.options.elements.tabsContainer.find('[data-tab="' + tabName + '"]').removeClass('none');
        }
    });
})();