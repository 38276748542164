export const TOOLTIP_JS_CLASSES: Record<string, string> = {
    shippingTimeTooltip: 'js__shipping_time_tooltip',
    tooltipInput: 'js__tooltip_input'
};

export const TOOLTIP_CSS_CLASSES: Record<string, string> = {
    tooltip: 'input-tooltip',
    tooltipVisible: 'input-tooltip_visible'
};

export const TOOLTIP_URLS: Record<string, string> = {
    shippingTime: '/product/get-warehouses-additional-shipping-times-for-stock/stock'
};

export const UNIT_CLASS = 'unit';
export const QUANTITY_WRAP_CLASS = 'quantity_wrap';
export const STOCK_ID_INPUT = 'input[name="stock_id"]';

export const DEFAULT_SHOP_VALUES = {
    lang: {
        tooltip: {
            current_shipping_time: 'Dla podanej ilości czas wysyłki wyniesie',
            up_to: 'Do',
            we_can_send_within: 'możemy wysłać w'
        }
    },
    values: {
        OptionCurrentStock: '',
        ProductStocksCache: ''
    }
};
