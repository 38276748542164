;(function (undefined) {
    Shop.CodeVerificationService = function () {
        var self = this;

        $('.js__code-verification-service').each(function (index, el) {
            var $el = $(el);
            var service = $el.attr('data-service');
            var url;
            var baseSettings;
            var specificSettings;

            if (!$el.CodeVerification) {
                baseSettings = _getBaseSettings(self);
                specificSettings = _getSpecificSettings(service);

                url = $el.find('[data-action]');
                $el.CodeVerification = new Shop.CodeVerification($el, $.extend(true, {}, baseSettings, specificSettings, {
                    url: url
                }));
            }
        });
    };

    Shop.CodeVerificationService.prototype = {
        constructor: Shop.CodeVerification,
        parent: Shop.prototype,
    };

    function _getBaseSettings (serviceInstance) {
        return {
            accessCode: {
                onSuccess: function (ev, email) {
                    var modal = _getModal({
                        header: Shop.lang.verificationService.verification_code_header,
                        message: serviceInstance.parent.substitute(Shop.lang.verificationService.verification_code_info, {
                            email: email
                        })
                    });
                    modal.createModal();
                },
                onError: function () {
                    var modal = _getModal({
                        header: Shop.lang.verificationService.verification_code_filed_header,
                        message: Shop.lang.verificationService.verification_code_filed_info
                    });
                    modal.createModal();
                }
            }
        };
    }

    function _getSpecificSettings (service) {
        var specificSettings = {
            rodo: {
                verificationAction: function () {
                    var modal = _getModal({
                        message: Shop.lang.rodo.archive_downloaded,
                        onSubmitBtnClick: function () {
                            modal.destroyModal();
                            location.href = location.origin;
                        },
                        onModalClose: function () {
                            location.href = location.origin;
                            modal.destroyModal();
                        },
                    });

                    setTimeout(function () {
                        modal.createModal();
                    }, 3000);
                }
            }
        };

        return specificSettings[service] || {};
    }

    function _getModal (settings) {
        var modal = new Shop.Modal();

        settings = $.extend(true, {}, {
            header: '',
            message: '',
            onSubmitBtnClick: function () {
                modal.destroyModal();
            },
            onModalClose: function () {
                modal.destroyModal();
            },
            submitBtnText: Shop.lang.common.ok
        }, settings);

        modal.options.header = $('<span />', {
            text: settings.header
        });
        modal.options.content = $('<p />', {
            text: settings.message
        });
        modal.options.footer = $('<div />', {
            'class': 'bottombuttons center'
        }).append($('<button />', {
            'text': settings.submitBtnText,
            'class': 'btn btn-red btn-center',
            click: settings.onSubmitBtnClick
        }));

        modal.callbacks.close = settings.onModalClose;

        return modal;
    }
})();