;(function (undefined) {
	Shop.AddToCart.include({
        options: {
            selectors: {
                form: '.basket form[action], form.basket[action]'
            },
            elements: {
                forms: null
            },

            preventSubmit: false
        },

        initialize: function (options) {
            var self;

            this.constructor.setOptions(options);
            this.options.elements.forms = $(this.options.selectors.form);

            self = this;
            this.options.elements.forms.each(function () {
                $(this).on('submit', function (ev) {
                    ev.preventDefault();
                    ev.stopPropagation();

                    self.parent.fireEvent('cart:add', ev, self.options, $(this));
                    if (!self.options.preventSubmit) {
                        this.submit();
                    }
                });
            });
        }
    });
})();