import { TPhoneCountries, TPhoneNumberPatternDetails } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';

export const PHONE_COUNTRY_SELECT_JS_CLASSES: Record<string, string> = {
    phoneCountrySelect: 'js__phone-country-select',
    phoneCountrySelectContainer: 'js__phone-country-select-container',
    phoneTableRow: 'js__phone-table-row'
};

export const PHONE_COUNTRY_SELECT_CSS_CLASSES: Record<string, string> = {
    inputPrefix: 'input_prefix',
    selectPhoneInactive: 'select__phone_inactive'
};

export const PHONE_COUNTRY_SELECT_SELECTORS: Record<string, string> = {
    inputPhone: '[id^="input_phone"]',
    primaryInputPhoneID: 'input_phone'
};

export const PHONE_NUMBER_PATTERN_STRATEGIES = {
    nineDigits: 'nineDigits',
    none: 'none'
} as const;

export const PHONE_NUMBER_COUNTRIES = {
    Poland: 'Poland',
    Other: 'Other'
} as const;

export const DEFAULT_PHONE_NUMBER_COUNTRY = PHONE_NUMBER_COUNTRIES.Poland;

export const PHONE_NUMBER_DETAILS: Record<string, TPhoneNumberPatternDetails> = {
    [PHONE_NUMBER_PATTERN_STRATEGIES.nineDigits]: {
        mask: '___ ___ ___',
        validPattern: '000 000 000',
        pattern: '^(\\d{3})(\\s)(\\d{3})(\\s)(\\d{3})$'
    }
};

export const PHONE_NUMBER_PREFIX: Record<TPhoneCountries, string> = {
    [PHONE_NUMBER_COUNTRIES.Poland]: '+48',
    [PHONE_NUMBER_COUNTRIES.Other]: ''
};

export const ENABLED_PHONE_VALIDATION_FLAG = '1';
