import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import jquery from 'jquery';

export default function getRecentlyViewedProducts() {
    customerPrivacy.onFunctionalConsentGranted(() => {
        const productSelector = '#box_productfull';

        if (jquery(productSelector).length > 0) {
            //@ts-ignore
            const currentProductId = parseInt(jquery('body').attr('id').replace('shop_product', ''), 10);
            const limit = 30;

            let recentViewProducts = JSON.parse(localStorage.getItem('recentViewProducts') ?? '[]');
            let recentProductIndex = recentViewProducts.indexOf(currentProductId);

            if (recentProductIndex !== -1) {
                recentViewProducts.splice(recentProductIndex, 1);
            }

            if (!isNaN(currentProductId)) {
                recentViewProducts.unshift(currentProductId);

                if (recentViewProducts.length > limit) {
                    recentViewProducts = recentViewProducts.slice(0, limit);
                }

                try {
                    //@ts-ignore
                    jquery.cookie('lastProducts-' + Shop.lang.name, recentViewProducts.toString());
                    localStorage.setItem('recentViewProducts', JSON.stringify(recentViewProducts));
                } catch (e) {
                    console.error(e);
                }
            }
        }
    });
}
