import { AnalyticManager } from '@frontstoreRwd/modules/analytics/classes/manager';
import { MICROSOFT_ADS } from '@frontstoreRwd/modules/analytics/services/services_names';
import { MicrosoftAdsService } from '@frontstoreRwd/modules/analytics/services/microsoft_ads/microsoft_ads';

export class MicrosoftAdsInitializer {
    private _analyticsManager: AnalyticManager;
    private _uetg: string;

    constructor(analyticManager: AnalyticManager, uetg: string) {
        this._analyticsManager = analyticManager;
        this._uetg = uetg;

        if (this._uetg) {
            const service = new MicrosoftAdsService(MICROSOFT_ADS, this._uetg);
            this._analyticsManager.register(service);
        }
    }
}
