import fastdom from '../../libraries/fastdom.min';

;
(function (undefined) {
    Shop.Gallery.include({
        options: {
            selector: '[data-gallery="true"]',
            activeIndex: null,
            fullScreen: false,
            minWidth: 100,

            showMiniGallery: true, // false
            showTitle: true, // false
            showMobile: false // true 
        },

        objects: {
            mask: null
        },

        elements: {
            container: null,
            inner: null,
            imgContainer: null,
            img: null,
            title: null,
            close: null,
            preview: null,
            galleryList: null,
            loader: null
        },

        keyEvents: null,
        imgPromises: [],
        callbacks: {
            onInit: null,
            preOpen: null,
            postOpen: null,
            resize: null,
            onNavPrev: null,
            onNavNext: null,
            onPreClose: null,
            onPostClose: null
        },

        initialize: function (options) {
            var self = this;
            var showMiniGallery = this.options.showMiniGallery;
            var showTitle = this.options.showTitle;
            var showMobile = this.options.showMobile;

            this.constructor.setOptions(options);

            if (typeof this.callbacks.onInit === 'function') {
                this.callbacks.onInit.call(this);
            }

            if (!this.options.showMobile) {
                this.objects.mask = new Shop.Mask({
                    showOnCreate: false
                });
            }

            $(this.options.selector).off('click').on('click', function (ev) {
                ev.preventDefault();
                ev.stopPropagation();

                if (self.parent.rwd.small === true) {
                    self.options.showMiniGallery = false;
                    self.options.showTitle = false;
                    self.options.showMobile = true;
                } else {
                    self.options.showMiniGallery = showMiniGallery;
                    self.options.showTitle = showTitle;
                    self.options.showMobile = showMobile;
                }

                if (typeof self.callbacks.preOpen === 'function') {
                    self.callbacks.preOpen.call(self);
                }
                self.open.call(self, ev, this)
                if (typeof self.callbacks.postOpen === 'function') {
                    self.callbacks.postOpen.call(self);
                }
            });
        },

        open: function (ev, el) {
            var self = this;
            var link = $(el);
            var key = this.hash(link.attr('href'));
            var dataName = link.data('galleryList');

            $(document.body).addClass('shop-gallery-open');

            if (dataName) {
                dataName = dataName.toString().replace(/\\/g, '\\\\');
                dataName = dataName.replace(/"/g, '\\"');
                dataName = dataName.replace(/'/g, "\\'");
                dataName = dataName.replace(/&/g, "\\&");

                this.elements.galleryList = $('[data-gallery-list="' + dataName + '"]');
            } else {
                this.elements.galleryList = link;
            }

            if (!this.options.showMobile) {
                this.objects.mask.show();
            }

            this.create(link);
            this.elements.galleryList.each(function () {
                var keyTemp = self.hash($(this).attr('href'));

                if (!self.imgPromises[keyTemp]) {
                    self.imgPromises[keyTemp] = $.get($(this).attr('href'), function () {
                        $('<img />', {
                            'src': this.url
                        });
                    });
                }
            });

            this.imgPromises[key].promise().done(function () {
                self.loadImg(this.url, link.attr('title'));
            });

            return this;
        },

        hash: function (string) {
            return string.split("").reduce(function (a, b) {
                a = ((a << 5) - a) + b.charCodeAt(0);
                return a & a
            }, 0);
        },

        create: function (link) {
            var self;
            var markup;
            var resize;

            markup = '' +
                '<div class="shop-gallery-skin">' +
                '<div class="shop-gallery-title">' +
                '</div>' +
                '<div class="shop-gallery-inner">' +
                '<div class="shop-gallery-img-container">' +
                '<img class="shop-gallery-img">' +
                '</div>' +

                '<span class="shop-gallery-nav shop-gallery-nav-prev"></span>' +
                '<span class="shop-gallery-nav shop-gallery-nav-next"></span>' +
                '</div>' +
                '<span class="shop-gallery-resize expand"></span>' +
                '<div class="shop-gallery-close">' + Shop.lang.common.close + '</div>' +
                '</div>';

            this.elements.container = $('<div />', {
                'class': 'shop-gallery inactive'
            }).html(markup).appendTo(document.body);

            this.elements.imgContainer = this.elements.container.find('.shop-gallery-img-container');
            this.elements.inner = this.elements.container.find('.shop-gallery-skin');
            this.elements.img = this.elements.imgContainer.find('> img');
            this.elements.title = this.elements.container.find('.shop-gallery-title');
            this.elements.resize = this.elements.container.find('.shop-gallery-resize');
            this.elements.close = this.elements.container.find('.shop-gallery-close');

            if (!this.options.showTitle) {
                this.elements.title.remove();
                this.elements.resize.remove();
            }

            this.elements.loader = $('<div />', {
                'class': 'shop-gallery-loader'
            }).appendTo(document.body);

            this.elements.nextButton = $('.shop-gallery-nav-next');
            this.elements.prevButton = $('.shop-gallery-nav-prev');

            if (this.elements.galleryList.length > 1) {
                this.options.activeIndex = this.elements.galleryList.index(link);

                this.elements.prevButton.off('click').on('click', this.navigatePrev.bind(this));
                this.elements.nextButton.off('click').on('click', this.navigateNext.bind(this));
                this.elements.inner.off('swipeleft').on('swipeleft', this.navigateNext.bind(this));
                this.elements.inner.off('swiperight').on('swiperight', this.navigatePrev.bind(this));

                this.createMiniGallery();
            } else {
                this.elements.prevButton.remove();
                this.elements.nextButton.remove();
            }

            resize = this.parent.debounce(this.reposition.bind(this, this.elements.container, this.elements.img[0]), 200);
            $(window).on('resize', resize);
            this.elements.close.on('mouseup', this.close.bind(this));
            this.elements.resize.on('mousedown', this.fullScreen.bind(this));
            self = this;

            this.keyEvents = (function (ev) {
                if (ev.keyCode === 27 || ev.keyCode === 37 || ev.keyCode === 39 || ev.keyCode === 122) {
                    ev.preventDefault();
                    ev.stopPropagation();
                }

                if (ev.keyCode === 27) {
                    this.close()
                }

                if (ev.keyCode === 37) {
                    this.elements.prevButton.trigger('click');
                }

                if (ev.keyCode === 39) {
                    this.elements.nextButton.trigger('click');
                }

                if (ev.keyCode === 122) {
                    if (self.elements.container[0].requestFullscreen) {
                        self.elements.container[0].requestFullscreen();
                    } else if (self.elements.container[0].msRequestFullscreen) {
                        self.elements.container[0].msRequestFullscreen();
                    } else if (self.elements.container[0].mozRequestFullScreen) {
                        self.elements.container[0].mozRequestFullScreen();
                    } else if (self.elements.container[0].webkitRequestFullscreen) {
                        self.elements.container[0].webkitRequestFullscreen();
                    }
                }
            }.bind(this));

            if (document.addEventListener) {
                document.addEventListener('webkitfullscreenchange', exitHandler, false);
                document.addEventListener('mozfullscreenchange', exitHandler, false);
                document.addEventListener('fullscreenchange', exitHandler, false);
                document.addEventListener('MSFullscreenChange', exitHandler, false);
            }

            $(window).on('keydown', this.keyEvents);

            function exitHandler() {
                var $body;

                $body = $('body');
                if (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null) {
                    $body.toggleClass('fullscreen');
                }
            }

            return this;
        },

        loadImg: function (url, title) {
            var self;

            this.elements.container.addClass('inactive');
            this.elements.img.addClass('inactive');

            self = this;
            setTimeout(function () {
                self.elements.img[0].onload = function () {
                    self.reposition(self.elements.container, self.elements.img[0]);
                }

                self.elements.img.attr('src', url);
                self.elements.loader.remove();
                self.elements.container.removeClass('inactive');
                self.elements.img.removeClass('inactive');
                self.elements.title.text(title);
            }, 200);

            return this;
        },

        reposition: function (object, img) {
            fastdom.measure(function () {
                var $body = $(document.body);
                var screenWidth = document.body.clientWidth;
                var screenHeight = window.innerHeight;
                var imgContainerWidth = img.naturalWidth;
                var imgContainerHeight = img.naturalHeight;
                var offset = 100;
                var x, y;
                var imgRatio = img.naturalWidth / img.naturalHeight;
                var padBottom = parseInt(this.elements.inner.css('paddingBottom'), 10) + 20;
                var $inner;
                var innerMargins;
                var innerPaddings;
                var imgContainerMaxWidth;
                var mobileHeight;
                var mobileWidth = 767;
                var containerBounding;

                $body.removeClass('shop-gallery-open__scrollX shop-gallery-open__scrollY');

                if (screenWidth > mobileWidth && this.options.showMobile === true && !this.elements.container.hasClass('inactive')) {
                    this.close();
                    this.options.showMobile = false;
                    return;

                } else if (screenWidth <= mobileWidth && this.options.showMobile === false && !this.elements.container.hasClass('inactive')) {
                    this.close();
                    this.options.showMobile = true;
                    return;
                }

                offset += (this.elements.title.outerHeight() + (this.elements.preview ? this.elements.preview.outerHeight(true) : 0))

                if ((img.naturalHeight + this.elements.title.outerHeight() + padBottom + (this.elements.preview ? this.elements.preview.outerHeight(true) : 0)) > screenHeight) {
                    imgContainerWidth = imgRatio * (screenHeight - offset);
                }

                $inner = this.elements.inner.find('.shop-gallery-inner');
                innerMargins = 2 * parseInt($inner.css('marginLeft'), 10);
                innerPaddings = 2 * parseInt(this.elements.imgContainer.css('paddingLeft'), 10);
                imgContainerMaxWidth = imgContainerWidth;

                if ((img.naturalWidth + innerMargins + innerPaddings) > screenWidth) {
                    imgContainerMaxWidth = screenWidth - offset;
                }

                if ((img.naturalHeight + this.elements.title.outerHeight() + (this.elements.preview ? this.elements.preview.outerHeight(true) : 0)) > screenHeight && img.naturalWidth > screenWidth) {
                    if (screenHeight < screenWidth) {
                        imgContainerWidth = imgRatio * (screenHeight - offset);
                    } else {
                        imgContainerWidth = screenWidth - offset;
                    }
                }

                if (img.naturalWidth > imgContainerWidth) {
                    this.elements.resize.removeClass('none');
                } else {
                    this.elements.resize.addClass('none');
                }

                imgContainerWidth = Math.min(imgContainerWidth, imgContainerMaxWidth);

                if (imgContainerWidth < this.options.minWidth) {
                    imgContainerWidth = this.options.minWidth;
                }

                if (this.options.fullScreen) {
                    this.elements.container.css('position', 'absolute');
                    imgContainerWidth = img.naturalWidth;
                } else {
                    this.elements.container.css('position', 'fixed');
                }

                if (!this.options.showMobile) {
                    this.elements.imgContainer.css({
                        width: imgContainerWidth
                    });
                    this.elements.title.css({
                        width: imgContainerWidth
                    });
                } else {
                    if (imgRatio < 1.1) {
                        mobileHeight = screenHeight;

                        this.elements.imgContainer.css({
                            width: 'auto',
                            height: 'auto'
                        });

                        if (img.clientHeight < screenHeight) {
                            mobileHeight = img.clientHeight;
                        }

                        this.elements.imgContainer.css({
                            width: 'auto',
                            height: mobileHeight
                        });
                    } else {
                        this.elements.imgContainer.css({
                            width: 'auto',
                            height: 'auto'
                        });
                    }
                }

                if (this.options.fullScreen) {
                    x = Math.max(30, ((screenWidth / 2) - (object.outerWidth() / 2)));
                    y = $(window).scrollTop() + 40;
                    imgContainerWidth = 'auto';
                } else {
                    x = ((screenWidth / 2) - (object.outerWidth() / 2));
                    y = ((screenHeight / 2) - (object.outerHeight() / 2));

                    if (imgContainerWidth === this.options.minWidth) {
                        y = 40;
                    }
                }

                if (!this.options.showMobile) {
                    object.css({
                        left: Math.floor(x),
                        top: Math.floor(y)
                    });
                }

                if (typeof this.callbacks.resize === 'function') {
                    this.callbacks.resize.call(this);
                }

                containerBounding = this.elements.container.get(0).getBoundingClientRect();

                if (screenHeight < this.elements.container.get(0).offsetHeight + containerBounding.top) {
                    $body.addClass('shop-gallery-open__scrollY');
                    if (screenWidth < this.elements.container.get(0).offsetWidth + containerBounding.left) {
                        $body.addClass('shop-gallery-open__scrollX');
                    }
                }

            }.bind(this));

            return this;
        },

        fullScreen: function () {
            this.options.fullScreen = !this.options.fullScreen;

            if (this.options.fullScreen) {
                this.elements.resize.addClass('collapse').removeClass('expand');
            } else {
                this.elements.resize.removeClass('collapse').addClass('expand');
            }

            this.reposition(this.elements.container, this.elements.img[0]);
        },

        navigatePrev: function () {
            var previews = $('.shop-gallery-item-preview');
            var prevIndex = this.options.activeIndex - 1;
            var prevImg;

            if (prevIndex < 0) {
                prevIndex = this.elements.galleryList.length - 1;
            }

            prevImg = this.elements.galleryList.eq(prevIndex);

            previews.removeClass('active');
            this.elements.preview.find('.shop-gallery-item-preview').eq(prevIndex).addClass('active');
            this.loadImg(prevImg.attr('href'), prevImg.attr('title'));
            this.options.activeIndex = prevIndex;
            this.moveMiniGallery(this.options.activeIndex);

            if (typeof this.callbacks.onNavPrev === 'function') {
                this.callbacks.onNavPrev.call(this);
            }
        },

        navigateNext: function () {
            var previews = $('.shop-gallery-item-preview');
            var nextIndex = this.options.activeIndex + 1;
            var nextImg;

            if (nextIndex >= this.elements.galleryList.length) {
                nextIndex = 0;
            }

            nextImg = this.elements.galleryList.eq(nextIndex);

            previews.removeClass('active');
            this.elements.preview.find('.shop-gallery-item-preview').eq(nextIndex).addClass('active');
            this.loadImg(nextImg.attr('href'), nextImg.attr('title'));
            this.options.activeIndex = nextIndex;
            this.moveMiniGallery(this.options.activeIndex);

            if (typeof this.callbacks.onNavNext === 'function') {
                this.callbacks.onNavNext.call(this);
            }
        },

        createMiniGallery: function () {
            var self;
            var $slider;
            var $container;
            var sliderWidth;

            self = this;
            this.elements.preview = $('<div />', {
                'class': 'shop-gallery-preview' + (this.options.showMiniGallery === false ? ' none' : '')
            }).appendTo(this.elements.container);

            $container = $('<div />', {
                'class': 'shop-gallery-container'
            }).appendTo(this.elements.preview);

            $slider = $('<aside />', {
                'class': 'shop-gallery-slider'
            }).appendTo($container);

            this.elements.galleryList.each(function () {
                var a;
                var div;

                div = $('<div />', {
                    'class': 'shop-gallery-item-preview',
                    'data-href': $(this).attr('href')
                }).appendTo($slider);

                a = $('<img />', {
                    'src': $(this).find('img').attr('src')
                }).appendTo(div);

                div.on('click', function () {
                    var previews = $('.shop-gallery-item-preview');

                    self.loadImg($(this).attr('href'), $(this).attr('title'));
                    previews.removeClass('active');
                    div.addClass('active');
                    self.options.activeIndex = self.elements.galleryList.index($(this));

                    self.moveMiniGallery(self.options.activeIndex);
                }.bind(this));
            });

            sliderWidth = this.elements.galleryList.length * ($('.shop-gallery-item-preview')[0].offsetWidth + 8);
            $slider.width(sliderWidth);
            this.elements.preview.find('.shop-gallery-item-preview').eq(this.options.activeIndex).addClass('active');

            if (sliderWidth > $container.width()) {
                this.createMiniGalleryNav();
            }
        },

        createMiniGalleryNav: function () {
            var self = this;
            var $prev;
            var $next;

            $prev = $('<span />', {
                'class': 'shop-gallery-mini-prev'
            }).appendTo(this.elements.preview).on('click', function () {
                var $slider = $('.shop-gallery-slider');
                var $container = $('.shop-gallery-container');

                var visibleContentWidth = $container.width();
                var sliderPosition = $slider.position().left;
                var sliderWidth = $slider.width();
                var slideWidth = $slider.find('.shop-gallery-item-preview:first-child').outerWidth(true);
                var slideCount = $slider.find('.shop-gallery-item-preview').length;
                var visibleElements = Math.floor(visibleContentWidth / slideWidth);
                var moveX = ((visibleElements - 1) * slideWidth) + sliderPosition;

                if (moveX > 0) {
                    moveX = 0;
                }

                $slider.css('left', moveX);
            }.bind(this));

            $next = $('<span />', {
                'class': 'shop-gallery-mini-next'
            }).appendTo(this.elements.preview).on('click', function () {
                var $slider = $('.shop-gallery-slider');
                var $container = $('.shop-gallery-container');

                var visibleContentWidth = $container.width();
                var sliderPosition = $slider.position().left;
                var sliderWidth = $slider.width();
                var slideWidth = $slider.find('.shop-gallery-item-preview:first-child').outerWidth(true);
                var slideCount = $slider.find('.shop-gallery-item-preview').length;
                var visibleElements = Math.floor(visibleContentWidth / slideWidth);
                var moveX = ((visibleElements - 1) * slideWidth) - sliderPosition;

                if ((moveX + visibleContentWidth) > sliderWidth) {
                    moveX = (slideCount - visibleElements) * slideWidth;
                }

                $slider.css('left', -moveX);
            }.bind(this));

            this.moveMiniGallery(this.options.activeIndex);
        },

        moveMiniGallery: function (activeIndex) {
            var $slider = $('.shop-gallery-slider');
            var $container = $('.shop-gallery-container');
            var visibleContentWidth = $container.width();
            var sliderPosition = $slider.position().left;
            var sliderWidth = $slider.width();
            var slideWidth = $slider.find('.shop-gallery-item-preview:first-child').outerWidth(true);
            var slideCount = $slider.find('.shop-gallery-item-preview').length;
            var visibleElements = Math.floor(visibleContentWidth / slideWidth);
            var moveX = 0;
            var activeElem = $slider.find('.shop-gallery-item-preview').eq(activeIndex);
            var activePosition = activeElem.position().left;

            if (visibleContentWidth >= sliderWidth) {
                return false;
            }

            if ((visibleContentWidth + sliderPosition) < (activeElem.outerWidth(true) + activePosition)) {
                moveX = activePosition - activeElem.outerWidth(true);
            }

            if ((moveX + visibleContentWidth) > sliderWidth) {
                moveX = (slideCount - visibleElements) * slideWidth;
            }

            $slider.css('left', -moveX);
        },

        close: function () {
            if (typeof this.callbacks.onPreClose === 'function') {
                this.callbacks.onPreClose.call(this);
            }

            this.elements.container.addClass('inactive');
            $(document.body).removeClass('shop-gallery-open__scrollX shop-gallery-open__scrollY shop-gallery-open');

            if (!this.options.showMobile) {
                this.objects.mask.destroy();
            }

            setTimeout(function () {
                this.elements.container.remove();
            }.bind(this), 100);

            $(window).off('keydown', this.keyEvents);

            if (typeof this.callbacks.onPostClose === 'function') {
                this.callbacks.onPostClose.call(this);
            }
        }
    });
})();