import { $on } from '@core/tools/event_bus';
import { ANALYTIC_EVENTS_LIST } from './events_list';
import { AnalyticEvent } from '../event';
import { IAnalyticService } from '../service/types';
import { TServiceName } from '../../services/types';

export class AnalyticManager {
    private _servicesContainer: IAnalyticService[] = [];
    private _events = ANALYTIC_EVENTS_LIST;
    private _eventsEmittedBeforeSetup: AnalyticEvent[] = [];

    constructor() {
        this._bindEventHandlers();
    }

    private _bindEventHandlers(): void {
        this._events.forEach((eventName) => {
            $on(eventName, (params) => {
                const event = new AnalyticEvent(eventName, params);

                if (this.isEveryServiceSetuped()) {
                    this._dispatch(event);
                } else {
                    this._eventsEmittedBeforeSetup.push(event);
                }
            });
        });
    }

    public isEveryServiceSetuped(): boolean {
        return this._servicesContainer.length > 0 && this._servicesContainer.every((service) => service.isSetuped());
    }

    private _dispatch(event: AnalyticEvent): void {
        this._servicesContainer.forEach((service) => this._processEventByService(service, event));
    }

    private _processEventByService(service: IAnalyticService, event: AnalyticEvent): void {
        try {
            service.processEvent(event);
        } catch (err) {
            console.error(err);
        }
    }

    public async register(service: IAnalyticService): Promise<void> {
        try {
            const isServiceRegistred = this._hasService(service.getName());

            if (!isServiceRegistred) {
                this._servicesContainer.push(service);

                await service.setup();
                this._dispatchEventsEmmitedBeforeSetup(service);
            } else {
                throw new Error('Analytic Service with this name allready registred');
            }
        } catch (err) {
            console.error(err);
        }
    }

    private _hasService(serviceName: TServiceName): boolean {
        const index = this._servicesContainer.findIndex((service) => service.getName() === serviceName);

        if (index >= 0) {
            return true;
        }

        return false;
    }

    private _dispatchEventsEmmitedBeforeSetup(service: IAnalyticService): void {
        if (this._eventsEmittedBeforeSetup.length > 0) {
            this._eventsEmittedBeforeSetup.forEach((event: AnalyticEvent) => this._processEventByService(service, event));
        }
    }

    public unregister(serviceName: TServiceName): void {
        const index = this._servicesContainer.findIndex((service) => service.getName() === serviceName);

        if (index >= 0) {
            this._servicesContainer.splice(index, 1);
        }
    }
}
