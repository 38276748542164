import fastdom from '../../libraries/fastdom.min';

Shop.Mask.include({
    options: {
        classes: {
            main: 'mask',
            inactive: 'mask-inactive',
            hidden: 'mask-hidden'
        },

        elements: {
            mask: null
        },

        showOnCreate: true,
        isCreated: false,
        isShow: false,
        delay: 200
    },

    initialize: function (options) {
        var self;
        this.constructor.setOptions(options);

        self = this;
        fastdom.mutate(function () {
            self.create(self.options.showOnCreate);
        });
    },

    create: function (show) {
        var options = this.options;

        if (options.isCreated === false) {
            options.elements.mask = $('<div />', {
                'class': options.classes.main + ' ' + options.classes.inactive + ' ' + options.classes.hidden
            }).appendTo(document.body);

            options.isCreated = true;
        }

        if (show !== false) {
            this.show();
        }
    },

    show: function () {
        var options = this.options;

        if (options.isCreated === false) {
            this.create(true);

            return this;
        }

        options.elements.mask.removeClass(options.classes.hidden);

        setTimeout(function () {
            options.elements.mask.removeClass(options.classes.inactive);
            options.isShow = true;
        }.bind(this), options.delay);

        return this;
    },

    hide: function (destroy) {
        var options = this.options;

        if (options.isCreated === false) {
            return false;
        }

        options.elements.mask.addClass(options.classes.inactive);
        options.isShow = false;

        setTimeout(function () {
            options.elements.mask.addClass(options.classes.hidden);
        }, options.delay)

        if (destroy) {
            setTimeout(function () {
                this.destroy();
            }.bind(this), options.delay)
        }

        return this;
    },

    destroy: function () {
        var options = this.options;

        if (options.isShow === true) {
            this.hide(true);

            return this;
        }

        options.isCreated = false;
        options.elements.mask.remove();
        options.elements.mask = null;

        return this;
    }
});