import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';

(function(undefined) {
    Shop.ProductVoter.include({
        options: {
            selectors: {
                class0: 'star0',
                class05: 'star0-5',
                class1: 'star1',
                starContainer: 'span.votestars',
                starsSelector: 'span.votestars img',
                voteMessageClass: 'vote-message'
            }
        },

        elements: {
            $starContainer: null,
            $stars: null
        },

        url: 'product/vote/prod/{prodid}/vote/{score}',

        initialize: function(options) {
            var self;
            var $stars;
            var $starContainer;

            this.constructor.setOptions(options);

            self = this;
            this.elements.$starContainer = $starContainer = $(this.options.selectors.starContainer);
            this.elements.$stars = $stars = $(this.options.selectors.starsSelector);

            customerPrivacy.onFunctionalConsentGranted(() => {
                $stars
                    .each(function(value) {
                        this.initialClass = $(this).attr('class');
                        this.value = value + 1;
                    })
                    .on('mouseenter', function() {
                        self.mouseenter(this.value);
                    })
                    .on('click', function() {
                        self.vote(this.value);
                    });

                $starContainer.on('mouseleave', function() {
                    self.mouseleave();
                });
            });
        },

        mouseenter: function(score) {
            var n;
            var self;
            var classToRemove;

            self = this;
            n = 1;
            classToRemove = this.options.selectors.class0 + ' ' + this.options.selectors.class05 + ' ' + this.options.selectors.class1;

            this.elements.$stars.each(function() {
                var half;
                var className;
                var _score;

                if (n > score) {
                    half = n - score;

                    if (half >= 0.25 && half <= 0.75) {
                        className = self.options.selectors.class05;
                    }
                }

                $(this).removeClass(classToRemove);
                _score = Math.round(score);

                if (n <= _score && !className) {
                    className = self.options.selectors.class1;
                } else if (!className) {
                    className = self.options.selectors.class0;
                }

                $(this).addClass(className);

                n += 1;
            });
        },

        mouseleave: function() {
            this.elements.$stars.each(function() {
                $(this).attr('class', this.initialClass);
            });
        },

        vote: function(score, self) {
            var shopClass;
            var id;
            var url;
            var self;
            var $starContainer;

            id = parseInt(this.elements.$starContainer.attr('id').replace(/[^0-9]/g, ''));
            if (id > 0 && score > 0) {
                shopClass = this.parent;
                self = this;

                url = shopClass.substitute(this.url, {
                    prodid: id,
                    score: score
                });

                $.ajax({
                    url: shopClass.url(url),
                    async: false,
                    cache: false,
                    method: 'get',

                    error: function() {
                        shopClass.alert(Shop.lang.common.product_vote_alert);
                    },

                    success: function(productRate) {
                        $starContainer = self.elements.$starContainer;

                        if (parseInt(productRate, 10) !== 0) {
                            $starContainer.off('mouseleave').removeAttr('id');
                            self.elements.$stars.off('mouseenter click');
                            $starContainer.prev('.' + self.options.selectors.voteMessageClass).text(Shop.lang.voting.vote_message + ':');
                        } else {
                            shopClass.alert(Shop.lang.common.product_vote_alert);
                        }
                    }
                });
            }
        }
    });
})();
