(function(undefined) {
    /**
     * Singleton class for managing search instances
     */
    Shop.SearchManager = function() {
        if (Shop.SearchManager.instance) {
            return Shop.SearchManager.instance;
        }

        Shop.SearchManager.instance = this;

        /**
         * contains current open search instance
         */
        this.target = null;
        this.previousVisible = null;

        this.callbacks = {
            handleHistoryChange: _handleHistoryChange.bind(this)
        };

        this.initialize();
    };

    Shop.SearchManager.prototype = {
        constructor: Shop.SearchManager,

        parent: Shop.prototype,

        initialize: function() {
            var self = this;
            var options;
            $('.js__search').each(function() {
                var $el = $(this);
                var $searchContainer;
                if (!this.Search) {
                    options = {
                        $el: $el,
                        baseDir: $el.data('baseDir') || ''
                    };
                    if ($el.parents('.logo-bar').length) {
                        options.onSearchOpen = function() {
                            if (self.parent.rwd.small) {
                                $el.removeClass('none');
                            }
                        };

                        options.onSearchClose = function() {
                            if (self.parent.rwd.small) {
                                $el.addClass('none');
                                $('.menu-mobile li a.fa-search').removeClass('active');
                            }
                        };

                        options.mobileBtn = true;
                    }

                    this.Search = new Shop.Search(options);
                    $searchContainer = $el.parents("[class*='search__container']");
                    //check if header search was hidden from administration panel
                    if ($searchContainer && $searchContainer.length && $searchContainer.hasClass('none')) {
                        this.Search.mainSearchHidden = true;
                    }

                    // if current state is #search-open, open first found search, if current target doesnt exist
                    if (history.state && history.state.searchOpen && !self.target) {
                        this.Search.open();
                    }
                }
            });

            _bindEvents(this);
        }
    };

    function _handleHistoryChange() {
        var searchToSet;

        if (!history.state && this.target && this.target.objects.view.isOpen()) {
            this.target.close();
        } else if (history.state && history.state.searchOpen) {
            if (this.previousVisible && !this.previousVisible.objects.view.isOpen()) {
                this.previousVisible.open();
            } else {
                /**
                 * Solution for case: user open search then close it, go back in browser navigation
                 * then go forvard and have url with #search-open an previous search is set to null
                 */
                searchToSet = $('.js__search')
                    .first()
                    .get(0).Search;

                if (searchToSet) {
                    searchToSet.open();
                }
            }
        }
    }

    function _updateSearchContainer($search, shouldBeVisible) {
        var $searchContainer = $search.parents('.search__container');

        if ($searchContainer && $searchContainer.length) {
            if (shouldBeVisible) {
                $searchContainer.removeClass('none');
            } else {
                $searchContainer.addClass('none');
            }
        }
    }

    function _bindEvents(managerInstance) {
        var $window = $(window);
        var $mainSearch = $('.js__search').first();
        var mainSearchInstance = $mainSearch.get(0) ? $mainSearch.get(0).Search : null;

        $window
            .on(
                'resize',
                managerInstance.parent.debounce(function() {
                    const isMainSearchBarHidden = mainSearchInstance && mainSearchInstance.mainSearchHidden;
                    const isMainSearchBarOpen = mainSearchInstance && mainSearchInstance.objects.view.isOpen();

                    if (managerInstance.parent.rwd.mid || managerInstance.parent.rwd.large) {
                        if (isMainSearchBarHidden) {
                            _updateSearchContainer($mainSearch, false);
                        }

                        $window.off('popstate', managerInstance.callbacks.handleHistoryChange);
                    } else {
                        if (isMainSearchBarOpen) {
                            _updateSearchContainer($mainSearch, true);
                        }

                        $window.on('popstate', managerInstance.callbacks.handleHistoryChange);
                    }
                }, 300)
            )
            .trigger('resize');

        managerInstance.parent.addEvent('search:open', function(evt, instance) {
            const isSmallScreen = !(managerInstance.parent.rwd.mid || managerInstance.parent.rwd.large);
            const isMainSearchBarVisible = mainSearchInstance && (!mainSearchInstance.mainSearchHidden || isSmallScreen);
            const isUrlUpdateNecessary = managerInstance.parent.rwd.small && (!history.state || (history.state && !history.state.searchOpen));

            if (managerInstance.target && managerInstance.target !== instance) {
                managerInstance.target.close();
            }

            if (isUrlUpdateNecessary) {
                history.pushState({ searchOpen: true }, 'search-open', '#search-open');
            }

            if (isMainSearchBarVisible) {
                _updateSearchContainer($mainSearch, true);
            }

            managerInstance.target = managerInstance.previousVisible = instance;
        });

        managerInstance.parent.addEvent('search:close', function(evt, instnace) {
            const isHistoryBackEnable = managerInstance.parent.rwd.small && history.state && history.state.searchOpen;
            const isMainSearchBarHidden = mainSearchInstance && mainSearchInstance.mainSearchHidden;

            if (isHistoryBackEnable) {
                history.go(-1);
            }

            if (isMainSearchBarHidden) {
                _updateSearchContainer($mainSearch, false);
            }

            managerInstance.target = null;
        });

        $(document.body).on('mouseup', function(evt) {
            var $el = $(evt.target);

            if (
                managerInstance.target &&
                !managerInstance.target.isInputValueSelected() &&
                !$el.hasClass('search') &&
                !$el.parents('.search').length
            ) {
                managerInstance.target.objects.view.close();
                managerInstance.target = null;
            }
        });
    }
})();
