export default function filterBoxRenderStrategy({ shoperModule }: any) {
    const shopInstance = shoperModule.parent;
    if (!shopInstance.filter) {
        shopInstance.subclass('Filter');
    }

    if (!shopInstance.filterprice) {
        shopInstance.subclass('FilterPrice');
    }
}
