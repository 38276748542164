import { TAnalyticEventName } from './types';

export class AnalyticEvent {
    name: TAnalyticEventName;
    params: unknown;

    constructor(name: TAnalyticEventName, params: unknown) {
        this.name = name;
        this.params = params;
    }
}
