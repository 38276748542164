import { TConsentName } from '@storefrontFeatures/customer_privacy/consents/consents_types';

export const CONSENTS_SERVICE_NAME = 'consentsService';
export const CONSENTS_STORE_NAME = 'consents';
export const USER_CONSENTS_KEY = 'user_consents';

export const ANALYTICS_CONSENT_NAME = 'analyticsConsent';
export const MARKETING_CONSENT_NAME = 'marketingConsent';
export const FUNCTIONAL_CONSENT_NAME = 'functionalConsent';
export const PLATFORM_CONSENT_NAME = 'platformAnalyticsConsent';

export const CONSENT_NAMES = {
    [ANALYTICS_CONSENT_NAME]: 'analyticsConsent',
    [MARKETING_CONSENT_NAME]: 'marketingConsent',
    [FUNCTIONAL_CONSENT_NAME]: 'functionalConsent',
    [PLATFORM_CONSENT_NAME]: 'platformAnalyticsConsent'
} as const;

export const GRANTED_EVENT_NAME = 'Granted';
export const WITHDRAWN_EVENT_NAME = 'Withdrawn';

export const LIST_OF_CONSENT_NAMES: TConsentName[] = Object.values(CONSENT_NAMES);
