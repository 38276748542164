export const FORM_ERRORS_CSS_CLASSES = {
    error: 'error',
    errorContainer: 'error_',
    inputError: 'input_error'
};

export const FORM_ERRORS_JS_CLASSES = {
    errorContainer: 'js__error-container_',
    inputContainer: 'js__input-container'
};
