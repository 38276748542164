import { $once } from '@core/tools/event_bus';
import { IHandle } from '@frontstoreRwd/core/events/i_handle';

export class AfterConsentGranted implements IHandle {
    private _consentName: string;
    private _callback: () => void;

    constructor(consentName: string, callback: () => void) {
        this._consentName = consentName;
        this._callback = callback;
        this.setupEvents();
    }

    setupEvents(): void {
        $once(`${this._consentName}Granted`, this._callback);
    }
}
