;(function (undefined) {
    Shop.SearchStartPhrases = function (options) {
        this.options = options || {
            onPhrasesClick: null,
            phrases: []
        };

        this.settings = {
            initalCount: 5,
            loadMoreCount: 5
        };

        this.elements = {
            $container: null
        };

        this.objects = {
            basicTemplate: new Shop.SearchTemplate()
        };

        this.initialize();
    };

    Shop.SearchStartPhrases.prototype = {
        constructor: Shop.SearchStartPhrases,

        parent: Shop.prototype,

        initialize: function () {},

        clean: function () {
            this.objects.basicTemplate.clean();
        },

        render: function () {
            if (this.elements.$container) {
                this.elements.$container.append(this.template());
            }
        },

        template: function () {
            var $markup = this.objects.basicTemplate.template({
                title: Shop.lang.search.start_phrases_header,
                showLimit: 5,
                loadMoreCount: 3,
                elementsCount: this.options.phrases.length,
                listItems: this.options.phrases,
                onListItemClick: this.options.onPhrasesClick
            });

            return $markup;
        }
    };
})();