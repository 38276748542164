;(function (undefined) {
    var CACHE = {};

    // 0 - suggestions, 1 - results, if request is empty still will be cached throught data type
    var CACHE_TIMES = [30000, 30000];
    var CACHE_TYPES_MAP = {
        'suggestions': 0,
        'results': 1
    };

    Shop.SearchCache = function () {
        this.initialize();
    };

    Shop.SearchCache.prototype = {
        constructor: Shop.SearchStartPhrases,

        parent: Shop.prototype,

        initialize: function () {
            var cache = sessionStorage.getItem('front-search-cache');

            if (cache) {
                CACHE = JSON.parse(this.parent.base64.decode(cache));
            }
        },

        set: function (phrase, type, response) {
            if (!CACHE[phrase]) {
                CACHE[phrase] = {
                    suggestions: {},
                    results: {}
                };
            }

            CACHE[phrase][type] = {
                response: response,
                time: Date.now()
            };

            try {
                sessionStorage.setItem('front-search-cache', this.parent.base64.encode(JSON.stringify(CACHE)));
            } catch (err) {
                console.error(err);
            }
        },

        get: function (phrase, type) {
            var dateDiff;
            var cachedValue;

            if (CACHE[phrase]) {
                cachedValue = CACHE[phrase][type];

                if (cachedValue) {
                    dateDiff = Date.now() - cachedValue.time;

                    if (dateDiff < CACHE_TIMES[CACHE_TYPES_MAP[type]] && cachedValue.response) {
                        return cachedValue;
                    } else {
                        CACHE[phrase][type] = null;
                    }
                }
            }

            return false;
        }
    };
})();