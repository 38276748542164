import { ITooltip, ITooltipProps } from './tooltip_types';

import { TOOLTIP_CSS_CLASSES } from './tooltip_constants';

export class Tooltip implements ITooltip {
    $tooltipWrapper: HTMLElement;
    classNamesProp: string[];
    tooltipValue?: string;
    $tooltip: HTMLDivElement;

    constructor({ $tooltipWrapper, tooltipValue, classNamesProp = [] }: ITooltipProps) {
        this.$tooltipWrapper = $tooltipWrapper;
        this.classNamesProp = classNamesProp;
        this.tooltipValue = tooltipValue;

        this.$tooltip = this.$tooltipWrapper.querySelector(`.${TOOLTIP_CSS_CLASSES.tooltip}`) as HTMLDivElement;
    }

    public hideTooltip(): void {
        this.$tooltip.classList.add('none');
        this.$tooltip.innerHTML = '';
    }

    public showTooltip(): void {
        this.$tooltip.classList.remove('none');
        this.$tooltip.classList.add(TOOLTIP_CSS_CLASSES.tooltipVisible);
    }

    public removeVisible(): void {
        this.$tooltip.classList.remove(TOOLTIP_CSS_CLASSES.tooltipVisible);
    }

    public updateTooltip(newContent: string): void {
        this.$tooltip.innerHTML = newContent;
    }

    private _onClickOutsideTooltip = (event: MouseEvent): void => {
        const { target } = event;

        const isTargetATooltip = this.$tooltipWrapper.contains(target as unknown as HTMLElement);

        if (!isTargetATooltip && this.$tooltip.classList.contains(TOOLTIP_CSS_CLASSES.tooltipVisible)) {
            this.removeVisible();
        } else if (!isTargetATooltip && !this.$tooltip.classList.contains('none')) {
            this.hideTooltip();
        }
    };

    public renderTooltip(tooltipValue = ''): void {
        const tooltip = document.createElement('div');
        tooltip.innerHTML = this.tooltipValue || tooltipValue;

        tooltip.className = `${TOOLTIP_CSS_CLASSES.tooltip} ${this.classNamesProp.join(' ')}`;

        this.$tooltipWrapper.append(tooltip);

        this.$tooltip = this.$tooltipWrapper.querySelector(`.${TOOLTIP_CSS_CLASSES.tooltip}`) as HTMLDivElement;

        document.addEventListener('click', this._onClickOutsideTooltip);
    }
}
