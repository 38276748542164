import jquery from 'jquery';

(function (undefined) {
    Shop.SkinPreviewBox.include({
        options: {
            prevClass: 'skinpreview',
            src: 'libraries/images/1px.gif'
        },

        initialize: function (options) {
            var $button;
            var shopClass;

            shopClass = this.parent;
            this.constructor.setOptions(options);

            $button = $('<span />', {
                    class: this.options.prevClass,
                    text: Shop.lang.skinpreview.close
                })
                .on('click', function (ev) {
                    jquery.removeCookie('skinpreview', {
                        path: '/' + shopClass.urls.base.replace(/(^\/|\/$)/g, '')
                    });
                    jquery.removeCookie('preview', {
                        path: '/' + shopClass.urls.base.replace(/(^\/|\/$)/g, '')
                    });
                    location.reload();
                })
                .appendTo(document.body);

            $('<img />', {
                src: shopClass.url(this.options.src)
            }).prependTo($button);
        }
    });
})();