import { $emit } from '@core/tools/event_bus';
import { FormErrorRenderer } from '@frontstoreRwd/modules/form_error_renderer/form_error_renderer';
import { IFormErrorRenderer } from '@frontstoreRwd/modules/form_error_renderer/form_error_renderer_types';
import {
    TScheme,
    IFormValidator,
    TValidationResult,
    TFormValidatorOptions,
    TValidatorSettings,
    TEventHandlerObject,
    TActionObject,
    TInputMaskInstance
} from '@frontstoreRwd/modules/validation/form_validator/form_validator_types';
import { VALIDATOR_CREATED_EVENT, EVENT_NAMES } from '@frontstoreRwd/modules/validation/form_validator/form_validator_event_names';
import { FORM_JS_SELECTORS, ACTIONS_TYPES, EMPTY_INPUT_VALUE } from '@frontstoreRwd/modules/validation/form_validator/form_validator_constants';
import { formValidatorEventsStrategies } from '@frontstoreRwd/modules/validation/form_validator/form_validator_events_strategies';
import { Validator } from '@frontstoreRwd/modules/validation/validator/validator';

export class FormValidator implements IFormValidator {
    #$form: HTMLFormElement;
    #validator = new Validator();
    #scheme: Record<string, TScheme>;
    #settings: TValidatorSettings | null = null;
    #shouldValidateForm = true;

    public formErrorRenderer: IFormErrorRenderer;

    constructor($form: HTMLFormElement, options: TFormValidatorOptions, formErrorRenderer = new FormErrorRenderer()) {
        this.#$form = $form;
        this.formErrorRenderer = formErrorRenderer;
        this.#scheme = options.scheme;

        this._setupValidators();

        if (options?.settings) {
            this.#settings = options.settings;

            this._setupEventHandlers();
        }

        $emit(VALIDATOR_CREATED_EVENT, this);
    }

    private _setupValidators = () => {
        Object.keys(this.#scheme).forEach((elementName: string): void => {
            const $elements = this.#$form.querySelectorAll(`[name="${elementName}"]`);

            if (!$elements.length) return;

            if (this.#scheme[elementName].isEnabled) {
                this.enableValidator(elementName);
            }
        });
    };

    public enableValidator(elementName: string): void {
        this.#scheme[elementName].isEnabled = true;

        const $elements = this.#$form.querySelectorAll<HTMLInputElement>(`[name="${elementName}"]`);

        $elements?.forEach(($element: HTMLInputElement): void => {
            this.#scheme[elementName].actions.forEach((action: TActionObject): void => {
                const { actionName, actionType = ACTIONS_TYPES.dom } = action;

                formValidatorEventsStrategies[actionType].addListener(actionName, this._validate, $element);
            });

            const isEmptyInputValue = this._getElementValue($element) === EMPTY_INPUT_VALUE;

            if (isEmptyInputValue) return;

            this._validateElement($element);
        });
    }

    private _validate = (value: Event | TInputMaskInstance): void => {
        const $element = value instanceof Event ? value.target : value.options.$el[0];

        this._validateElement($element as HTMLInputElement);
    };

    private _validateElement = ($element: HTMLInputElement): void => {
        const elementName = $element.getAttribute('name') as string;
        const shouldValidateElement = this.#scheme[elementName]?.isEnabled && !this._isElementDisabled($element);

        if (shouldValidateElement) {
            const validationResult: TValidationResult = this._getValidationResult($element);

            if (validationResult.isValid) {
                this.formErrorRenderer.removeErrors($element);
            } else {
                this.formErrorRenderer.render($element, validationResult.errorsName);
            }
        }
    };

    private _isElementDisabled = ($element: HTMLElement): boolean => {
        return $element.getAttribute('disabled') !== null;
    };

    private _getValidationResult($element: HTMLInputElement): TValidationResult {
        const value: string | boolean = this._getElementValue($element);
        const elementName = $element.getAttribute('name') as string;

        return this.#validator.validate(value, this.#scheme[elementName].validators);
    }

    private _getElementValue($element: HTMLInputElement): string | boolean {
        let value;
        const elementType = $element.getAttribute('type');

        if (elementType === 'text') {
            value = $element.value;
        } else {
            value = $element.checked;
        }

        return value;
    }

    private _setupEventHandlers(): void {
        if (!this.#settings) return;

        document.querySelector(FORM_JS_SELECTORS.noValidate)?.addEventListener('click', () => {
            this.#shouldValidateForm = false;
        });

        const { validateOnSubmit, eventHandlers } = this.#settings;

        if (validateOnSubmit) {
            this.#$form.addEventListener(EVENT_NAMES.submit, this._validateFormOnSubmit);
        }

        if (eventHandlers?.length) {
            eventHandlers.forEach((eventHandlerObject: TEventHandlerObject): void => {
                const $element = document.querySelector(eventHandlerObject.selector);

                $element?.addEventListener(eventHandlerObject.eventName, (ev: Event): void => eventHandlerObject.handler(this, ev));
            });
        }
    }

    private _validateFormOnSubmit = (ev: Event): void => {
        if (!this.#shouldValidateForm) return;

        Object.keys(this.#scheme).forEach((elementName: string): void => {
            const $elements = this.#$form.querySelectorAll<HTMLInputElement>(`[name="${elementName}"]`);

            if (!$elements.length) return;

            if (this.#scheme[elementName].isEnabled) {
                $elements?.forEach(($element: HTMLInputElement): void => {
                    if (this._isElementDisabled($element)) return;

                    const validationResult: TValidationResult = this._getValidationResult($element);

                    if (!validationResult.isValid) {
                        ev.preventDefault();

                        this.formErrorRenderer.render($element, validationResult.errorsName);
                    }
                });
            }
        });
    };

    public disableValidator(elementName: string): void {
        this.#scheme[elementName].isEnabled = false;

        const $elements = this.#$form.querySelectorAll<HTMLInputElement>(`[name="${elementName}"]`);

        $elements?.forEach(($element: HTMLInputElement): void => {
            this.#scheme[elementName].actions.forEach((action: TActionObject): void => {
                const { actionName, actionType = ACTIONS_TYPES.dom } = action;

                if (actionType === ACTIONS_TYPES.dom) {
                    formValidatorEventsStrategies[actionType].removeListener(actionName, this._validate, $element);
                }
            });

            this.formErrorRenderer.removeErrors($element);
        });
    }

    public isValidatorEnabled(elementName: string): boolean {
        return this.#scheme[elementName].isEnabled;
    }

    get $form(): HTMLFormElement {
        return this.#$form;
    }
}
