import { xhr } from '@core/tools/xhr';
import { basketUrl } from '../urls';
import { IApiBasketInformation } from './types';
import { IFrontApiOptions } from '../types';

export async function getBasketInformation({ lang, currency }: IFrontApiOptions): Promise<IApiBasketInformation | null> {
    try {
        const response = await xhr<IApiBasketInformation>({
            url: basketUrl(lang, currency),
            method: 'get'
        });

        if (response && response.status === 200) {
            return response.data;
        }
    } catch (err) {
        console.error(err);
    }

    return null;
}
