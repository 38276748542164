export class GtagSDK {
    public static async create(trackingId: string, layerName?: string): Promise<void> {
        const id = layerName ? `gtag-jssdk-${layerName}` : 'gtag-jssdk';
        if (document.getElementById(id)) {
            return;
        }

        await GtagSDK._injectScript(id, trackingId, layerName);
    }

    private static _injectScript(id: string, trackingId: string, layerName?: string): Promise<void> {
        return new Promise((resolve) => {
            const firstScriptOnPage = document.getElementsByTagName('script')[0];
            const sdkScript = document.createElement('script');
            const layerNameParam = layerName ? `&l=${layerName}` : '';

            sdkScript.id = id;
            sdkScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}${layerNameParam}`;
            sdkScript.async = true;
            sdkScript.onload = () => {
                resolve();
            };

            firstScriptOnPage.parentNode?.insertBefore(sdkScript, firstScriptOnPage);
        });
    }
}
