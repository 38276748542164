import { PHONE_NUMBER_DETAILS, PHONE_NUMBER_COUNTRIES } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';
import { BASKET_JS_CLASSES } from '@frontstoreRwd/modules/basket_manage/basket_constants';

export const formatToPhoneNumberWithoutMask = (value: string): string => {
    return value.replace(/\D+/g, '');
};

export const formatToPhoneNumberWithoutPrefix = (value: string, prefix: string): string => {
    return value.slice(prefix.length);
};

export const replacePlusWithDoubleZerosInPrefix = (prefix: string): string => {
    return `00${prefix.slice(1)}`;
};

export const createInputMaskInstance = ($input: HTMLInputElement): void => {
    // @ts-ignore
    new window.Shop.InputMask({
        $el: $input,
        mask: $input.dataset.mask,
        pattern: $input.dataset.pattern,
        validPattern: $input.dataset.validPattern,
        value: $input.value
    });
};

export const formatToPhoneNumberWithNineDigitsMask = (value: string): string => {
    const phoneNumber = formatToPhoneNumberWithoutMask(value);
    const phoneNumberLength = phoneNumber.length;
    let phoneNumberWithMask: string;

    if (phoneNumberLength <= 3) {
        phoneNumberWithMask = phoneNumber;
    } else if (phoneNumberLength <= 6) {
        phoneNumberWithMask = `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, phoneNumberLength)}`;
    } else if (phoneNumberLength <= 9) {
        phoneNumberWithMask = `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, phoneNumberLength)}`;
    } else {
        phoneNumberWithMask = `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 9)}`;
    }

    const newPhoneNumberLength = phoneNumberWithMask.length;

    return phoneNumberWithMask + PHONE_NUMBER_DETAILS.nineDigits.mask.slice(newPhoneNumberLength);
};

export const isPolishNumberSelected = (): boolean => {
    const $shippingsContainer = document.querySelector<HTMLElement>(`.${BASKET_JS_CLASSES.basketExtraStepShippings}`);

    return $shippingsContainer?.dataset?.phoneNumberShippingCountry === PHONE_NUMBER_COUNTRIES.Poland;
};
