import Cookie from 'js-cookie';
import basketHeaderPreview from '@frontstoreRwd/modules/basket_manage/basket_header_preview';
import basketBox from '@frontstoreRwd/modules/basket_manage/basket_box';
import freeShippingBox from '@frontstoreRwd/modules/basket_manage/free_shipping_box';
import { AfterPhoneCountrySelectCreated } from '@frontstoreRwd/modules/basket_manage/events/after_phone_country_select_created';
import { updateBasket } from '@frontstoreRwd/modules/basket_manage/basket_utils';

import { $on } from '@core/tools/event_bus';
import { store } from '@frontstoreRwd/store';
import { SESSION_EXPIRY_TIME_IN_MS } from './basket_constants';

export const basketPreview = {
    ...basketHeaderPreview,
    ...basketBox,
    ...freeShippingBox,

    async initialize(): Promise<void> {
        const basketIndex = Cookie.get('basket');
        const update = () => this.update();

        this.basketHeaderPreviewInitialize();
        this.basketBoxInitialize();
        this.freeShippingBoxInitialize();

        if (basketIndex && basketIndex !== '0') {
            const lastBasketIndex = localStorage.getItem('basketIndex');
            const lastBasket = localStorage.getItem('frontstoreBasket');
            const hasBasketChanges = Boolean(lastBasketIndex && !(basketIndex === lastBasketIndex));
            const basketUpdateTime = Number(localStorage.getItem('basketUpdateTime'));
            const isSessionExpired = Date.now() - basketUpdateTime >= SESSION_EXPIRY_TIME_IN_MS;
            const hasBeenOrderedInBackground = localStorage.getItem('hasBeenOrderedInBackground') !== null;

            const shouldUpdateBasket =
                ((hasBasketChanges || !lastBasket) && store.basketInfo === null) || isSessionExpired || hasBeenOrderedInBackground;

            if (shouldUpdateBasket) {
                await updateBasket();
                localStorage.removeItem('hasBeenOrderedInBackground');
            } else {
                store.basketInfo = JSON.parse(String(lastBasket));
            }

            update();
        } else {
            localStorage.removeItem('basketIndex');
            localStorage.removeItem('frontstoreBasket');
        }

        $on('basket:update', update);

        new AfterPhoneCountrySelectCreated();
    },

    update(): void {
        this.updateHeaderBasketView();
        this.updateBoxBasketView();
        this.updateFreeShippingBoxView();
        this.setLocalBasketData();
    },

    setLocalBasketData(): void {
        try {
            localStorage.setItem('basketIndex', String(Cookie.get('basket')));
            localStorage.setItem('frontstoreBasket', JSON.stringify(store.basketInfo));
        } catch {}
    }
};
