import { TConsentName } from './types';

export const CONSENT_MODAL_COUNTER = '1';

export const USER_CONSENTS_KEY = 'user_consents';
export const CONSENT_MODAL_KEY = 'show_consent_modal';

export const ANALYTICS_CONSENT_NAME = 'analyticsConsent';
export const MARKETING_CONSENT_NAME = 'marketingConsent';
export const FUNCTIONAL_CONSENT_NAME = 'functionalConsent';
export const PLAFTORM_CONSENT_NAME = 'platformAnalyticsConsent';

export const LIST_OF_CONSENT_NAMES: TConsentName[] = [
    ANALYTICS_CONSENT_NAME,
    MARKETING_CONSENT_NAME,
    FUNCTIONAL_CONSENT_NAME,
    PLAFTORM_CONSENT_NAME
];
