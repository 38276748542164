import Features from '@core/tools/features';
import { getTemplate } from '@core/dom_utilities/get';

export default {
    initialize: function () {
        if (Features.isEnabled('skakanka')) {
            document.querySelector('.js__skankaka-modal')?.addEventListener('click', function () {
                //@ts-ignore
                const modal = new Shop.Modal();

                modal.options.header = 'Mam wersję testową sklepu. Co teraz?';
                modal.options.content = getTemplate('skakanka-modal')[0];
                modal.options.footer = null;

                if (modal._isCreated) {
                    modal.loadContent();
                    modal.reposition();
                    modal._createMask();
                } else {
                    modal.createModal();
                    modal.reposition();
                }
            });
        }
    }
};
