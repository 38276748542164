import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import { CurrencyHelper } from '@frontstoreRwd/core/classes/CurrencyHelper';
import { TGoogleAnalyticsV4Order, TGoogleAnalyticsV4Product, TPurchaseParams } from './types';
import { TGoogleRemarketingOptions } from '@frontstoreRwd/modules/analytics/types';

export class GoogleAnalyticsV4Map {
    public static toV4Product(product: AnalyticProduct): TGoogleAnalyticsV4Product {
        const priceFloat = CurrencyHelper.convertStrToNum(product.price);
        const positionFloat = CurrencyHelper.convertStrToNum(product.position);
        const quantityInt = product.quantity ? CurrencyHelper.convertStrToNum(product.quantity) : 1;

        return {
            item_name: product.name, // Name or ID is required.
            item_id: product.id,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.variant,
            item_list_name: product.listName || '',
            index: positionFloat,
            price: priceFloat,
            currency: product.currency || '',
            quantity: quantityInt
        };
    }

    public static toV4Order(
        purchaseParams: TPurchaseParams,
        remarketingOptions?: TGoogleRemarketingOptions | undefined,
        trackingId?: string[]
    ): TGoogleAnalyticsV4Order {
        const revenueFloat = CurrencyHelper.convertStrToNum(purchaseParams.orderSummary.revenue);
        const shippingFloat = CurrencyHelper.convertStrToNum(purchaseParams.orderSummary.shipping);
        const taxFloat = CurrencyHelper.convertStrToNum(purchaseParams.orderSummary.tax);
        const analyticsV4Products = purchaseParams.products.map((product: AnalyticProduct) => this.toV4Product(product));

        const order: TGoogleAnalyticsV4Order = {
            transaction_id: purchaseParams.orderSummary.id,
            value: revenueFloat,
            shipping: shippingFloat,
            currency: purchaseParams.currency || '',
            tax: taxFloat,
            items: analyticsV4Products,
            coupon: purchaseParams.coupon
        };

        if (remarketingOptions?.isRemarketingActive && remarketingOptions?.isConversionEnabled) {
            order.send_to = [`AW-${remarketingOptions.conversionId}/${remarketingOptions.conversionLabel}`];
            if (trackingId) {
                order.send_to = order.send_to.concat(trackingId);
            }
        }

        if (remarketingOptions?.isRemarketingActive && remarketingOptions?.isShoppingAdsEnabled) {
            order.aw_merchant_id = remarketingOptions.awMerchantId;
            order.aw_feed_country = remarketingOptions.awFeedCountry;
            order.aw_feed_language = remarketingOptions.awFeedLanguage;
        }

        return order;
    }
}
