import { AfterDifferentAddressRemoved } from './after_different_address_removed';
import { setDefaultInpostPhoneNumber, isInpostShippingSelected, isPhoneCountrySelectNotNeeded } from '../inpost_phone_number_utils';
import {
    isPolandSelected,
    isDifferentAddressChecked,
    togglePrimaryPhoneCountrySelectActivity,
    isPhoneValidationEnabled
} from '@frontstoreRwd/modules/basket_manage/basket_utils';
import { BASKET_SELECTORS } from '@frontstoreRwd/modules/basket_manage/basket_constants';
import { IPhoneCountrySelect } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_types';
import { PHONE_COUNTRY_SELECT_CREATED_EVENT } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_event_names';
import { PHONE_COUNTRY_SELECT_JS_CLASSES } from '@frontstoreRwd/modules/phone_country_select/phone_country_select_constants';
import { IHandle } from '@frontstoreRwd/core/events/i_handle';
import { $on } from '@core/tools/event_bus';

export class AfterPhoneCountrySelectCreated implements IHandle {
    #phoneCountryInstances: Array<IPhoneCountrySelect> = [];

    #selectors = {
        phoneCountrySelectContainer: `.${PHONE_COUNTRY_SELECT_JS_CLASSES.phoneCountrySelectContainer}`,
        addressDifferentId: BASKET_SELECTORS.addressDifferentId
    };

    constructor() {
        this.setupEvents();
    }

    public setupEvents(): void {
        $on(PHONE_COUNTRY_SELECT_CREATED_EVENT, this._setInpostPhoneNumberData);
    }

    private _setInpostPhoneNumberData = (phoneCountryInstance: unknown): void => {
        const currentPhoneCountryInstance = phoneCountryInstance as IPhoneCountrySelect;

        if (!isInpostShippingSelected(currentPhoneCountryInstance) || !isPhoneValidationEnabled(currentPhoneCountryInstance)) {
            return;
        }

        this.#phoneCountryInstances.push(currentPhoneCountryInstance);

        const isPrimaryPhoneCountrySelect = this.#phoneCountryInstances.length === 1;
        const shouldSetDefaultPrimaryInpostPhoneNumber = isPrimaryPhoneCountrySelect && !isDifferentAddressChecked();

        if (shouldSetDefaultPrimaryInpostPhoneNumber || !isPrimaryPhoneCountrySelect) {
            setDefaultInpostPhoneNumber(currentPhoneCountryInstance);
            currentPhoneCountryInstance.toggleDefaultPhoneNumberData(true, false);
        }

        const phoneName = currentPhoneCountryInstance.$phoneInput.getAttribute('name') as string;
        const $countrySelect = document.querySelector<HTMLSelectElement>(`${BASKET_SELECTORS.countryForPhone}${phoneName}`);

        $countrySelect?.addEventListener('change', (): void => {
            this._updatePhoneCountrySelects(currentPhoneCountryInstance);
        });

        const $addressSelect = document.querySelector<HTMLSelectElement>(`${BASKET_SELECTORS.addressForPhone}${phoneName}`);

        $addressSelect?.addEventListener('change', () => {
            this._updatePhoneCountrySelects(currentPhoneCountryInstance);
        });

        if (isPrimaryPhoneCountrySelect) {
            this._setupEventHandlers(currentPhoneCountryInstance);
        }
    };

    private _updatePhoneCountrySelects = (currentPhoneCountryInstance: IPhoneCountrySelect): void => {
        const isPrimaryPhoneCountrySelect = this.#phoneCountryInstances[0] === currentPhoneCountryInstance;

        if (isPrimaryPhoneCountrySelect) {
            const phoneName = currentPhoneCountryInstance.$phoneInput.getAttribute('name') as string;

            if (isPhoneCountrySelectNotNeeded(phoneName)) {
                currentPhoneCountryInstance.toggleDefaultPhoneNumberData(false);
            } else {
                togglePrimaryPhoneCountrySelectActivity(currentPhoneCountryInstance);

                currentPhoneCountryInstance.toggleDefaultPhoneNumberData(true, isDifferentAddressChecked());
            }
        }

        if (isDifferentAddressChecked()) {
            const secondaryPhoneCountryInstance: IPhoneCountrySelect = this.#phoneCountryInstances[1];

            setDefaultInpostPhoneNumber(secondaryPhoneCountryInstance);
            secondaryPhoneCountryInstance.toggleDefaultPhoneNumberData(true, false);
        }
    };

    private _setupEventHandlers = (currentPhoneCountryInstance: IPhoneCountrySelect): void => {
        new AfterDifferentAddressRemoved(currentPhoneCountryInstance);

        const $differentAddressCheckbox = document.getElementById(this.#selectors.addressDifferentId);

        $differentAddressCheckbox?.addEventListener('change', this._updatePhoneCountryData);
    };

    private _updatePhoneCountryData = (): void => {
        const [primaryPhoneCountryInstance, secondaryPhoneCountryInstance]: Array<IPhoneCountrySelect> = this.#phoneCountryInstances;
        const primaryPhoneName = primaryPhoneCountryInstance.$phoneInput.getAttribute('name') as string;

        if (isDifferentAddressChecked()) {
            if (isPolandSelected(`${BASKET_SELECTORS.countryForPhone}${primaryPhoneName}`)) {
                togglePrimaryPhoneCountrySelectActivity(primaryPhoneCountryInstance);
            } else {
                primaryPhoneCountryInstance.toggleDefaultPhoneNumberData(false);
            }

            setDefaultInpostPhoneNumber(secondaryPhoneCountryInstance);
            secondaryPhoneCountryInstance.toggleDefaultPhoneNumberData(true, false);
        } else {
            setDefaultInpostPhoneNumber(primaryPhoneCountryInstance);
            primaryPhoneCountryInstance.toggleDefaultPhoneNumberData(true, isDifferentAddressChecked());
            togglePrimaryPhoneCountrySelectActivity(primaryPhoneCountryInstance);
        }
    };
}
