import { Consent } from '../consent';
import { ConsentsGroup } from '.';

export class ConsentsGroupMap {
    static grantedToJSON(consents: ConsentsGroup): string {
        const grantedConsentsNames = consents.getGranted().map((consent) => consent.getName());
        return JSON.stringify(grantedConsentsNames);
    }

    static grantedToString(consentsGroup: ConsentsGroup): string {
        const consents = consentsGroup.get();
        const grantedConsents = consents.filter((consent: Consent) => consent.isGranted());

        const isEveryConsentGranted = consents.length === grantedConsents.length;
        if (isEveryConsentGranted) {
            return 'all';
        }

        const isAnyConsentGranted = grantedConsents.length > 0;
        if (isAnyConsentGranted) {
            const grantedConsentsNames = grantedConsents.map((consent: Consent) => consent.getName()).join(',');
            return grantedConsentsNames;
        }

        return 'none';
    }
}
