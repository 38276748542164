import { $emit } from '@core/tools/event_bus';
import { TConsentName } from '../../types';

export class Consent {
    private _name: TConsentName;
    private _isGranted = false;

    constructor(name: TConsentName, isGranted: boolean) {
        this._name = name;
        this._isGranted = isGranted;
    }

    getName(): TConsentName {
        return this._name;
    }

    isGranted(): boolean {
        return this._isGranted;
    }

    grant(): Consent {
        this._isGranted = true;
        $emit(`${this.getName()}Granted`, this);

        return this;
    }

    withdrawn(): Consent {
        this._isGranted = false;
        $emit(`${this.getName()}Withdrawn`, this);

        return this;
    }
}
