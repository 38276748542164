import { $emit } from '@core/tools/event_bus';
import { AfterConsentGranted } from './events/after_consent_granted';
import { CONSENTS_SAVED } from './event_names';
import { Consent } from '../consent';
import { ConsentsGroup } from '../consents_group';
import { IConsentsStore } from '../consents_store/types';
import { TConsentName } from '../../types';

export class ConsentsApi {
    private _store: IConsentsStore;
    private _consentsGroup: ConsentsGroup;

    constructor(consentsGroup: ConsentsGroup, store: IConsentsStore) {
        this._consentsGroup = consentsGroup;
        this._store = store;

        this._updateConsentsWithStoredValues();
    }

    private async _updateConsentsWithStoredValues(): Promise<void> {
        try {
            const consentsFromStore = await this._store.load();

            if (consentsFromStore) {
                this.grantConsents(consentsFromStore);
                this.saveConsents();
            }
        } catch (err) {
            console.error(err);
        }
    }

    grantConsents(grantedConsents: TConsentName[]): ConsentsApi {
        grantedConsents.forEach((name) => {
            const consent = this._consentsGroup.find(name);
            consent?.grant();
        });

        return this;
    }

    withdrawConsents(withdrawnConsents: TConsentName[]): ConsentsApi {
        withdrawnConsents.forEach((name) => {
            const consent = this._consentsGroup.find(name);
            consent?.withdrawn();
        });

        return this;
    }

    async saveConsents(): Promise<ConsentsApi> {
        try {
            await this._store.save(this._consentsGroup);
            $emit(CONSENTS_SAVED, this._consentsGroup);
        } catch (err) {
            console.error(err);
        }

        return this;
    }

    onConsentGranted(name: TConsentName, callback: () => void): ConsentsApi {
        const consent = this._consentsGroup.find(name);

        if (consent) {
            if (consent.isGranted()) {
                callback();
            } else {
                new AfterConsentGranted(consent.getName(), callback);
            }
        }

        return this;
    }

    getGrantedConsents(): Consent[] {
        return this._consentsGroup.getGranted();
    }
}
