import { CurrencyUtils } from '@dreamcommerce/utilities';

export class CurrencyHelper {
    public static convertStrToNum(number: string): number {
        const separators = {
            //@ts-ignore
            decimalSeparator: Shop.values.decimalSep,
            //@ts-ignore
            literalSeparator: Shop.values.thousandSep
        };

        const convertedNumber = number ? CurrencyUtils.toNumber(number, separators) : NaN;
        return convertedNumber !== undefined ? convertedNumber : NaN;
    }
}
