import { AnalyticService } from '../../classes/service';
import { FacebookEvents } from '@frontstoreRwd/modules/facebook/classes/events/facebook_events';
import { TFacebookBusinessExtensionEvent } from './facebook_business_extension_types';
import { TFacebookBusinessExtensionOptions } from '../../types';
import { TServiceName } from '../types';
import eventsHandlersMapper from './facebook_business_extension_events';

export class FacebookBusinessExtensionService extends AnalyticService<FacebookBusinessExtensionService> {
    private _fbq: any;
    private _options: TFacebookBusinessExtensionOptions;

    protected _events: TFacebookBusinessExtensionEvent = eventsHandlersMapper;

    constructor(name: TServiceName, options: TFacebookBusinessExtensionOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        FacebookEvents.create();

        //@ts-ignore
        this._fbq = window.fbq;

        this._fbq('init', this._options.pixelId);
        this._fbq('trackSingle', this._options.pixelId, 'PageView');

        this._isSetuped = true;
    }

    public getPixelId(): string {
        return this._options.pixelId;
    }

    public fbq(
        eventName: string,
        analyticParamaeters: Record<string, any>,
        eventParameters: Record<string, any> | undefined = undefined,
        trackName = 'trackSingle'
    ): void {
        this._fbq(trackName, this._options.pixelId, eventName, analyticParamaeters, eventParameters);
    }
}
