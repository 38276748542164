import '@babel/polyfill';
import 'mdn-polyfills/Node.prototype.before';
import 'mdn-polyfills/Node.prototype.after';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/CustomEvent';

import { Gallery } from '@dreamcommerce/aurora';

export const productCardGallery = {
    initialize(): void {
        const $galleryImages: NodeListOf<HTMLImageElement> = document.querySelectorAll('.js__open-gallery');

        $galleryImages.forEach(($galleryImage: HTMLImageElement) => {
            $galleryImage.addEventListener('click', showGallery);
        });

        const $galleryTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-gallery-name-to-open]');

        $galleryTriggers.forEach(($galleryTrigger: HTMLElement) => {
            $galleryTrigger.addEventListener('click', showGallery);
        });

        handleOldGalleryModule($galleryImages);
        this.handleHashChange();

        window.addEventListener('hashchange', this.handleHashChange);
    },

    handleHashChange(): void {
        const hash: string = window.location.hash;
        const isGalleryNameInHash: boolean = hash.includes('galleryName');

        if (hash && isGalleryNameInHash) {
            const [galleryNameString, imageNumberString] = hash.split(',imageNumber=');
            const hashNameLength: number = '#galleryName='.length;
            const galleryNameIndex: number = galleryNameString.indexOf('#galleryName=');
            const galleryName: string = galleryNameString.slice(hashNameLength + galleryNameIndex);

            if (!doesGalleryExist(galleryName)) {
                const $images: NodeListOf<HTMLImageElement> = document.querySelectorAll(`[data-gallery-name=${galleryName}]`);

                if ($images.length) {
                    const isGalleryCopied: boolean = imageNumberString?.includes('-copied');
                    const imageNumber = isGalleryCopied ? +imageNumberString.replace('-copied', '') : +imageNumberString;
                    const isImageNumberValid: boolean = imageNumber > 0 && imageNumber <= $images.length;
                    const selectedImage = isImageNumberValid ? ($images[imageNumber - 1] as HTMLImageElement) : ($images[0] as HTMLImageElement);

                    createGallery(galleryName, selectedImage.src);
                }
            }
        }
    }
};

const createGallery = (galleryName: string, selectedImageSource: string) => {
    new Gallery({
        name: galleryName,
        initiallySelectedImageSrc: selectedImageSource,
        hasCopyLink: true,
        handleHistory: true,
        translations: {
            // @ts-ignore
            copyLink: window.Shop.lang.productGallery.copyLinkToGallery,
            // @ts-ignore
            picture: window.Shop.lang.productGallery.picture,
            // @ts-ignore
            copyLinkShortVersion: window.Shop.lang.productGallery.copyLink,
            // @ts-ignore
            copyLinkConfirmation: window.Shop.lang.productGallery.linkCopied
        }
    });
};

const showGallery = (ev: Event): void => {
    ev.preventDefault();

    const $galleryImage = ev.target as HTMLElement;
    const { selectedImageSource, galleryName } = returnInitialGalleryData($galleryImage);

    if (!doesGalleryExist(galleryName)) {
        createGallery(galleryName, selectedImageSource);
    }

    updateUpScrollButtonView();
};

const updateUpScrollButtonView = () => {
    const $upScrollButton: HTMLDivElement | null = document.querySelector('.up');

    $upScrollButton?.classList.add('rwd-hide-full');

    document.querySelector('.js__gallery_new')?.addEventListener('removeGallery', () => {
        $upScrollButton?.classList.remove('rwd-hide-full');
    });
};

const doesGalleryExist = (galleryName: string): boolean => (document.querySelector(`.js__gallery-${galleryName}-close`) ? true : false);

const returnInitialGalleryData = ($galleryTrigger: HTMLElement): { selectedImageSource: string; galleryName: string } => {
    let galleryName: string | undefined = $galleryTrigger.dataset.galleryNameToOpen;
    let $image: HTMLImageElement;

    if (galleryName) {
        const currentSelectedImage: HTMLImageElement | null = document.querySelector(`.current [data-gallery-name=${galleryName}]`);

        $image = currentSelectedImage
            ? (currentSelectedImage as HTMLImageElement)
            : (document.querySelector(`[data-gallery-name=${galleryName}]`) as HTMLImageElement);
    } else {
        $image = $galleryTrigger as HTMLImageElement;
        galleryName = $image.dataset.galleryName as string;
    }

    return {
        selectedImageSource: $image.src,
        galleryName
    };
};

// temporary part of code to handle old gallery module on product page
const handleOldGalleryModule = ($galleryImages: NodeListOf<HTMLImageElement>): void => {
    if ($galleryImages.length) {
        const thumbnailsList = document.querySelector('.innersmallgallery ul') as HTMLUListElement;

        if (thumbnailsList) {
            thumbnailsList.childNodes.forEach((thumbnailLi: ChildNode) => {
                thumbnailLi.addEventListener('click', handleThumbnailLiClick);
            });
        } else {
            const mainImageContainer = document.querySelector('#box_productfull .maininfo .mainimg') as HTMLDivElement;

            mainImageContainer.addEventListener('click', () => {
                const $galleryImage: HTMLImageElement = $galleryImages[0];
                const { selectedImageSource, galleryName } = returnInitialGalleryData($galleryImage);

                if (!doesGalleryExist(galleryName)) {
                    createGallery(galleryName, selectedImageSource);
                }
            });
        }
    }
};

// temporary part of code to handle old gallery module on product page
const handleThumbnailLiClick = (ev: Event) => {
    ev.preventDefault();

    const thumbnailLi = ev.target as ChildNode;

    thumbnailLi.childNodes.forEach((child: ChildNode) => {
        if (child.nodeName == 'A') {
            const thumbnailAnchor = child as HTMLAnchorElement;
            const thumbnail = thumbnailAnchor.children[1] as HTMLImageElement;

            thumbnail.click();
        }
    });
};
